import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import TermsConditions from './views/terms-conditions'
import WebsiteSecurity from './views/website-security'
import WhyWordPressWebsiteNeedRegularMaintenance from './views/why-word-press-website-need-regular-maintenance'
import Partners from './views/partners'
import Blog from './views/blog'
import Home from './views/home'
import SpeedUpWebsite from './views/speed-up-website'
import WebsiteMaintenance from './views/website-maintenance'
import MalwareCleanup from './views/malware-cleanup'
import NotFound from './views/not-found'
import PrivacyPolicy from './views/privacy-policy'
import TermsOfServices from './views/terms-of-services'
import WordPressWebsiteSecurityIsASecurityPluginEnough from './views/word-press-website-security-is-a-security-plugin-enough'
import HowToReduceWordPressWebsiteRunningCost from './views/how-to-reduce-word-press-website-running-cost'
import AboutUs from './views/about-us'
import OurEthics from './views/our-ethics'
import WorkWithUsCleanWPPro from './views/work-with-us-clean-wp-pro'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Terms-Conditions',
      path: '/terms-and-conditions',
      component: TermsConditions,
    },
    {
      name: 'Website-Security',
      path: '/website-security',
      component: WebsiteSecurity,
    },
    {
      name: 'Why-WordPress-website-need-regular-maintenance',
      path: '/why-wordpress-website-need-regular-maintenance',
      component: WhyWordPressWebsiteNeedRegularMaintenance,
    },
    {
      name: 'Partners',
      path: '/partners',
      component: Partners,
    },
    {
      name: 'Blog',
      path: '/blog',
      component: Blog,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'Speed-up-Website',
      path: '/speed-up-website',
      component: SpeedUpWebsite,
    },
    {
      name: 'Website-Maintenance',
      path: '/website-maintenance',
      component: WebsiteMaintenance,
    },
    {
      name: 'Malware-Cleanup',
      path: '/malware-cleanup',
      component: MalwareCleanup,
    },
    {
      name: 'Not-Found',
      path: '/404',
      component: NotFound,
    },
    {
      name: 'Privacy-Policy',
      path: '/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      name: 'Terms-of-Services',
      path: '/terms-of-services',
      component: TermsOfServices,
    },
    {
      name: 'WordPress-website-security-Is-a-security-plugin-enough',
      path: '/wordpress-website-security-is-a-security-plugin-enough',
      component: WordPressWebsiteSecurityIsASecurityPluginEnough,
    },
    {
      name: 'How-to-reduce-WordPress-website-running-cost',
      path: '/how-to-reduce-wordpress-website-running-cost',
      component: HowToReduceWordPressWebsiteRunningCost,
    },
    {
      name: 'About-Us',
      path: '/about-us',
      component: AboutUs,
    },
    {
      name: 'Our-Ethics',
      path: '/our-ethics',
      component: OurEthics,
    },
    {
      name: 'Work-with-us-CleanWP-Pro',
      path: '/career',
      component: WorkWithUsCleanWPPro,
    },
  ],
})
