<template>
  <div class="blog-post-card2-blog-post-card" v-bind:class="rootClassName">
    <a :href="blog_url_link" class="blog-post-card2-link">
      <img
        :alt="image_alt"
        :src="image_src"
        image_src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&amp;ixlib=rb-1.2.1&amp;h=1000"
        class="blog-post-card2-image"
      />
    </a>
    <div class="blog-post-card2-container">
      <div class="blog-post-card2-container1">
        <span class="blog-post-card2-text">{{ label }}</span>
        <span class="blog-post-card2-text1">{{ when }}</span>
      </div>
      <a :href="blog_url_link" class="blog-post-card2-link1">
        <h1 class="blog-post-card2-text2">{{ title }}</h1>
      </a>
      <span class="blog-post-card2-text3">{{ description }}</span>
      <div class="blog-post-card2-container2">
        <div class="blog-post-card2-profile">
          <img
            :alt="profile_alt"
            :src="profile_src"
            class="blog-post-card2-image1"
          />
          <span class="blog-post-card2-text4">{{ author }}</span>
        </div>
        <div class="blog-post-card2-container3">
          <a :href="blog_url_link" class="blog-post-card2-link2">{{ text }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogPostCard2',
  props: {
    rootClassName: String,
    title: {
      type: String,
      default: 'Lorem ipsum dolor sit amet',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    label: {
      type: String,
      default: 'ENTERPRISE',
    },
    profile_src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&h=1200',
    },
    when: {
      type: String,
      default: '3 days ago',
    },
    description: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non volutpat turpis. Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor. Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    },
    blog_url_link: {
      type: String,
      default: 'https://cleanwp.pro/blog/',
    },
    profile_alt: {
      type: String,
      default: 'profile',
    },
    image_src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&w=1500',
    },
    author: {
      type: String,
      default: 'Jane Doe',
    },
    text: {
      type: String,
      default: 'Read More ->',
    },
  },
}
</script>

<style scoped>
.blog-post-card2-blog-post-card {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card2-blog-post-card:hover {
  transform: scale(1.02);
}
.blog-post-card2-link {
  display: contents;
}
.blog-post-card2-image {
  width: 450px;
  height: 350px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.blog-post-card2-container {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blog-post-card2-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card2-text {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  text-transform: uppercase;
}
.blog-post-card2-text1 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.blog-post-card2-link1 {
  display: contents;
}
.blog-post-card2-text2 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-post-card2-text2:hover {
  color: #107bd2;
}
.blog-post-card2-text3 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-post-card2-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card2-profile {
  display: flex;
  align-items: center;
}
.blog-post-card2-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.blog-post-card2-text4 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.blog-post-card2-container3 {
  display: flex;
  position: relative;
}
.blog-post-card2-link2 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  text-decoration: none;
}
.blog-post-card2-link2:hover {
  color: #107bd2;
}






@media(max-width: 1600px) {
  .blog-post-card2-image {
    width: 450px;
  }
}
@media(max-width: 991px) {
  .blog-post-card2-blog-post-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {
  .blog-post-card2-blog-post-card {
    flex-direction: column;
  }
  .blog-post-card2-image {
    width: 100%;
    height: auto;
  }
}
@media(max-width: 479px) {
  .blog-post-card2-container2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-post-card2-link2 {
    width: auto;
  }
}
</style>
