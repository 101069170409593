<template>
  <div class="you-tube-you-tube" v-bind:class="rootClassName">
    <img
      :alt="pastedImage_alt"
      :src="pastedImage_src"
      class="you-tube-pasted-image"
    />
  </div>
</template>

<script>
export default {
  name: 'YouTube',
  props: {
    pastedImage_src: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/39622a11-b838-43d2-9bdc-3ba79420cf0d/128f45a2-cd39-4279-86c4-c1790c4a9f30?org_if_sml=15208',
    },
    pastedImage_alt: {
      type: String,
      default: 'pastedImage',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.you-tube-you-tube {
  width: 240px;
  height: 75px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.you-tube-pasted-image {
  width: 200px;
}
.you-tube-root-class-name {
  height: 75px;
}
.you-tube-root-class-name1 {
  height: 75px;
}
.you-tube-root-class-name2 {
  height: 75px;
}
.you-tube-root-class-name3 {
  height: 75px;
}
.you-tube-root-class-name4 {
  height: 75px;
}
.you-tube-root-class-name5 {
  height: 75px;
}
.you-tube-root-class-name6 {
  height: 75px;
}
.you-tube-root-class-name7 {
  height: 75px;
}
.you-tube-root-class-name8 {
  height: 75px;
}
.you-tube-root-class-name9 {
  height: 75px;
}
.you-tube-root-class-name10 {
  height: 75px;
}
.you-tube-root-class-name11 {
  height: 75px;
}
.you-tube-root-class-name12 {
  height: 75px;
}
.you-tube-root-class-name13 {
  height: 75px;
}
.you-tube-root-class-name14 {
  height: 75px;
}
.you-tube-root-class-name15 {
  height: 75px;
}
.you-tube-root-class-name16 {
  height: 75px;
}
.you-tube-root-class-name17 {
  height: 75px;
}
.you-tube-root-class-name18 {
  height: 75px;
}
.you-tube-root-class-name19 {
  height: 75px;
}
.you-tube-root-class-name20 {
  height: 75px;
}
.you-tube-root-class-name21 {
  height: 75px;
}
.you-tube-root-class-name22 {
  height: 75px;
}
.you-tube-root-class-name23 {
  height: 75px;
}
.you-tube-root-class-name24 {
  height: 75px;
}
.you-tube-root-class-name25 {
  height: 75px;
}
.you-tube-root-class-name26 {
  height: 75px;
}
.you-tube-root-class-name27 {
  height: 75px;
}
.you-tube-root-class-name28 {
  height: 75px;
}
.you-tube-root-class-name29 {
  height: 75px;
}
.you-tube-root-class-name30 {
  height: 75px;
}
.you-tube-root-class-name31 {
  height: 75px;
}
.you-tube-root-class-name32 {
  height: 75px;
}
.you-tube-root-class-name33 {
  height: 75px;
}
.you-tube-root-class-name34 {
  height: 75px;
}
.you-tube-root-class-name35 {
  height: 75px;
}
.you-tube-root-class-name36 {
  height: 75px;
}
.you-tube-root-class-name37 {
  height: 75px;
}
.you-tube-root-class-name38 {
  height: 75px;
}
.you-tube-root-class-name39 {
  height: 75px;
}
.you-tube-root-class-name40 {
  height: 75px;
}
.you-tube-root-class-name41 {
  height: 75px;
}
.you-tube-root-class-name42 {
  height: 75px;
}
.you-tube-root-class-name43 {
  height: 75px;
}
@media(max-width: 1200px) {
  .you-tube-root-class-name {
    width: 200px;
  }
  .you-tube-root-class-name1 {
    width: 200px;
  }
  .you-tube-root-class-name2 {
    width: 200px;
  }
  .you-tube-root-class-name3 {
    width: 200px;
  }
  .you-tube-root-class-name8 {
    width: 200px;
  }
  .you-tube-root-class-name9 {
    width: 200px;
  }
  .you-tube-root-class-name10 {
    width: 200px;
  }
  .you-tube-root-class-name11 {
    width: 200px;
  }
  .you-tube-root-class-name12 {
    width: 200px;
  }
  .you-tube-root-class-name13 {
    width: 200px;
  }
  .you-tube-root-class-name14 {
    width: 200px;
  }
  .you-tube-root-class-name15 {
    width: 200px;
  }
  .you-tube-root-class-name16 {
    width: 200px;
  }
  .you-tube-root-class-name17 {
    width: 200px;
  }
  .you-tube-root-class-name18 {
    width: 200px;
  }
  .you-tube-root-class-name19 {
    width: 200px;
  }
  .you-tube-root-class-name20 {
    width: 200px;
  }
  .you-tube-root-class-name21 {
    width: 200px;
  }
  .you-tube-root-class-name22 {
    width: 200px;
  }
  .you-tube-root-class-name23 {
    width: 200px;
  }
  .you-tube-root-class-name24 {
    width: 200px;
  }
  .you-tube-root-class-name25 {
    width: 200px;
  }
  .you-tube-root-class-name26 {
    width: 200px;
  }
  .you-tube-root-class-name27 {
    width: 200px;
  }
  .you-tube-root-class-name28 {
    width: 200px;
  }
  .you-tube-root-class-name29 {
    width: 200px;
  }
  .you-tube-root-class-name30 {
    width: 200px;
  }
  .you-tube-root-class-name31 {
    width: 200px;
  }
  .you-tube-root-class-name32 {
    width: 200px;
  }
  .you-tube-root-class-name33 {
    width: 200px;
  }
  .you-tube-root-class-name34 {
    width: 200px;
  }
  .you-tube-root-class-name35 {
    width: 200px;
  }
  .you-tube-root-class-name36 {
    width: 200px;
  }
  .you-tube-root-class-name37 {
    width: 200px;
  }
  .you-tube-root-class-name38 {
    width: 200px;
  }
  .you-tube-root-class-name39 {
    width: 200px;
  }
  .you-tube-root-class-name40 {
    width: 200px;
  }
  .you-tube-root-class-name41 {
    width: 200px;
  }
  .you-tube-root-class-name42 {
    width: 200px;
  }
  .you-tube-root-class-name43 {
    width: 200px;
  }
}
@media(max-width: 991px) {
  .you-tube-root-class-name {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name1 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name2 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name3 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name8 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name9 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name10 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name11 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name12 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name13 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name14 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name15 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name16 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name17 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name18 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name19 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name20 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name21 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name22 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name23 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name24 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name25 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name26 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name27 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name28 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name29 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name30 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name31 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name32 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name33 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name34 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name35 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name36 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name37 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name38 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name39 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name40 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name41 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name42 {
    width: 160px;
    height: 65px;
  }
  .you-tube-root-class-name43 {
    width: 160px;
    height: 65px;
  }
}
@media(max-width: 767px) {
  .you-tube-root-class-name {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name1 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name2 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name3 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name8 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name9 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name10 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name11 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name12 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name13 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name14 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name15 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name16 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name17 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name18 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name19 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name20 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name21 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name22 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name23 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name24 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name25 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name26 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name27 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name28 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name29 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name30 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name31 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name32 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name33 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name34 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name35 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name36 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name37 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name38 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name39 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name40 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name41 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name42 {
    width: 150px;
    height: 50px;
  }
  .you-tube-root-class-name43 {
    width: 150px;
    height: 50px;
  }
}
@media(max-width: 479px) {
  .you-tube-you-tube {
    width: 120px;
    height: 60px;
    align-items: center;
  }
  .you-tube-pasted-image {
    width: 80px;
  }
}
</style>
