<template>
  <footer class="footer-footer" v-bind:class="rootClassName">
    <div class="footer-top">
      <div class="footer-left">
        <span class="footer-text">{{ text1 }}</span>
        <div class="footer-subscription">
          <input
            type="email"
            required
            :placeholder="textinput_placeholder"
            autocomplete="email"
            class="footer-textinput input"
          />
          <div class="footer-subscribe">
            <span class="footer-text01">{{ text7 }}</span>
          </div>
        </div>
        <span class="footer-text02">{{ text2 }}</span>
      </div>
      <div class="footer-right">
        <div class="footer-category">
          <span class="footer-text03">{{ text3 }}</span>
          <div class="footer-links">
            <router-link to="/malware-cleanup" class="footer-navlink">
              {{ text8 }}
            </router-link>
            <router-link to="/website-security" class="footer-navlink01">
              {{ text9 }}
            </router-link>
            <router-link to="/website-maintenance" class="footer-navlink02">
              {{ text10 }}
            </router-link>
            <router-link to="/speed-up-website" class="footer-navlink03">
              {{ text11 }}
            </router-link>
          </div>
        </div>
        <div class="footer-category1">
          <span class="footer-text04">{{ text4 }}</span>
          <div class="footer-links1">
            <router-link to="/about-us" class="footer-navlink04">
              {{ text12 }}
            </router-link>
            <router-link to="/blog" class="footer-navlink05">
              {{ text13 }}
            </router-link>
            <router-link to="/partners" class="footer-navlink06">
              {{ text14 }}
            </router-link>
            <router-link to="/career" class="footer-navlink07">
              {{ text15 }}
            </router-link>
            <a
              href="mailto:hello@cleanwp.pro?subject=CleanWP Pro"
              class="footer-link"
            >
              {{ text16 }}
            </a>
          </div>
        </div>
        <div class="footer-category2">
          <span class="footer-text05">{{ text5 }}</span>
          <div class="footer-links2">
            <router-link to="/privacy-policy" class="footer-navlink08">
              {{ text17 }}
            </router-link>
            <router-link to="/terms-and-conditions" class="footer-navlink09">
              {{ text18 }}
            </router-link>
            <router-link to="/terms-of-services" class="footer-navlink10">
              Terms of Services
            </router-link>
            <router-link to="/terms-of-services" class="footer-navlink11">
              Cookies
            </router-link>
            <router-link to="/our-ethics" class="footer-navlink12">
              {{ text21 }}
            </router-link>
          </div>
        </div>
        <div class="footer-category3">
          <span class="footer-text06">{{ text6 }}</span>
          <div class="footer-links3">
            <a
              :href="link_text13"
              target="_blank"
              rel="noreferrer noopener"
              class="footer-link1"
            >
              {{ text22 }}
            </a>
            <span class="footer-text07">{{ text23 }}</span>
            <span class="footer-text08">{{ text24 }}</span>
            <span class="footer-text09">{{ text25 }}</span>
            <a
              href="https://www.facebook.com/cleanwppro"
              target="_blank"
              rel="noreferrer noopener"
              class="footer-link2"
            >
              {{ text26 }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <img :alt="Branding_alt" :src="Branding_src" class="footer-branding" />
      <span class="footer-text10">{{ text }}</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    link_text9: {
      type: String,
      default: 'https://cleanwp.pro/terms/',
    },
    textinput_placeholder: {
      type: String,
      default: 'Enter your email',
    },
    text: {
      type: String,
      default: 'Copyright © CleanWP PRO - 2023',
    },
    text10: {
      type: String,
      default: 'Website Maintenance',
    },
    link_text5: {
      type: String,
      default: 'https://cleanwp.pro/partners/',
    },
    link_text: {
      type: String,
      default: 'https://cleanwp.pro/website-security/',
    },
    text7: {
      type: String,
      default: 'Subscribe',
    },
    link_text2: {
      type: String,
      default: 'https://cleanwp.pro/speed-up-website/',
    },
    rootClassName: String,
    text9: {
      type: String,
      default: 'Website Security',
    },
    link_text8: {
      type: String,
      default: 'https://cleanwp.pro/privacy-policy/',
    },
    link_text3: {
      type: String,
      default: 'https://cleanwp.pro/about-us/',
    },
    text22: {
      type: String,
      default: 'Twitter',
    },
    link_text12: {
      type: String,
      default: 'https://cleanwp.pro/ethics/',
    },
    link_text14: {
      type: String,
      default: 'https://www.facebook.com/cleanwppro/',
    },
    link_text11: {
      type: String,
      default: 'https://cleanwp.pro/cookies/',
    },
    text20: {
      type: String,
      default: 'Cookies',
    },
    link_text1: {
      type: String,
      default: 'https://cleanwp.pro/professional-cleanup/',
    },
    text2: {
      type: String,
      default:
        'By subscribing to our newsletter you agree with our Terms and Conditions.',
    },
    link_text6: {
      type: String,
      default: 'https://cleanwp.pro/careers/',
    },
    text26: {
      type: String,
      default: 'Facebook',
    },
    text4: {
      type: String,
      default: 'Company',
    },
    text1: {
      type: String,
      default: 'Subscribe to our newsletter',
    },
    text14: {
      type: String,
      default: 'Partners',
    },
    text17: {
      type: String,
      default: 'Privacy Policy',
    },
    text24: {
      type: String,
      default: 'Crunchbase',
    },
    link_text7: {
      type: String,
      default: 'https://cleanwp.pro/contact/',
    },
    Branding_alt: {
      type: String,
      default: 'CleanWP Pro - The ultimate WordPress cleaner plugin',
    },
    text8: {
      type: String,
      default: 'Malware Clean up',
    },
    text5: {
      type: String,
      default: 'Useful links',
    },
    link_text13: {
      type: String,
      default: 'https://twitter.com/cleanwppro',
    },
    text13: {
      type: String,
      default: 'Blog',
    },
    text21: {
      type: String,
      default: 'Our Ethics',
    },
    text11: {
      type: String,
      default: 'Speed up Website',
    },
    text6: {
      type: String,
      default: 'Connect with us',
    },
    link_text10: {
      type: String,
      default: 'https://cleanwp.pro/gdpr/',
    },
    text3: {
      type: String,
      default: 'Services',
    },
    link_text4: {
      type: String,
      default: 'https://cleanwp.pro/blog/',
    },
    text15: {
      type: String,
      default: 'Careers',
    },
    text12: {
      type: String,
      default: 'About',
    },
    text19: {
      type: String,
      default: 'GDPR',
    },
    text16: {
      type: String,
      default: 'Contact Us',
    },
    text18: {
      type: String,
      default: 'Terms & Conditions',
    },
    Branding_src: {
      type: String,
      default: '/cleanwppro-200h.png',
    },
    text23: {
      type: String,
      default: 'Linkedin',
    },
    text25: {
      type: String,
      default: 'Producthunt',
    },
  },
}
</script>

<style scoped>
.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
  background-color: #000000;
}
.footer-top {
  width: 100%;
  display: flex;
  max-width: 1200px;
  border-color: #3B3B3B;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-bottom-width: 1px;
}
.footer-left {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.footer-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-subscription {
  width: 700px;
  height: 66px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: #ffffff;
}
.footer-textinput {
  width: 60%;
  outline: none;
  font-size: 14px;
  border-width: 0px;
}
.footer-subscribe {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 16px;
  padding-left: 32px;
  border-radius: 56px;
  padding-right: 32px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-start;
  background-color: #0064b7;
}
.footer-subscribe:hover {
  background-color: #000;
}
.footer-text01 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.footer-text02 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
}
.footer-right {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.footer-category {
  width: 240px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.footer-text03 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.footer-links {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-navlink {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink01 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink02 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink03 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-category1 {
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text04 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.footer-links1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-navlink04 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink05 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink06 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink07 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-link {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-category2 {
  width: 220px;
  display: flex;
  flex-direction: column;
}
.footer-text05 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.footer-links2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-navlink08 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink09 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink10 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink11 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-navlink12 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-category3 {
  width: 220px;
  display: flex;
  flex-direction: column;
}
.footer-text06 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.footer-links3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-link1 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-text07 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.footer-text08 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.footer-text09 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.footer-link2 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.footer-bottom {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  user-select: none;
  flex-direction: column;
}
.footer-branding {
  width: 250px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);;
  height: auto;
  align-self: center;
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text10 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
  align-self: center;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

















@media(max-width: 1600px) {
  .footer-navlink10 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
}
@media(max-width: 1200px) {
  .footer-left {
    width: 726px;
    align-self: center;
    margin-right: 0px;
  }
  .footer-text {
    align-self: center;
  }
  .footer-subscription {
    width: 100%;
  }
  .footer-text02 {
    align-self: center;
  }
  .footer-right {
    align-self: center;
  }
  .footer-category {
    width: 180px;
  }
  .footer-category1 {
    width: 130px;
  }
  .footer-category2 {
    width: 170px;
  }
  .footer-category3 {
    width: 180px;
  }
  .footer-bottom {
    margin-top: var(--dl-space-space-oneandhalfunits);
  }
  .footer-branding {
    width: 250px;
    height: auto;
    align-self: center;
  }
  .footer-text10 {
    color: var(--dl-color-gray-900);
    align-self: center;
    font-style: normal;
    font-weight: 600;
  }
}
@media(max-width: 991px) {
  .footer-footer {
    padding: var(--dl-space-space-sixunits);
  }
  .footer-top {
    flex-direction: column;
  }
  .footer-left {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .footer-subscription {
    width: 100%;
  }
  .footer-text02 {
    color: rgb(104, 104, 104);
    font-size: 14px;
  }
  .footer-right {
    gap: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .footer-links {
    gap: var(--dl-space-space-unit);
  }
  .footer-links1 {
    gap: var(--dl-space-space-unit);
  }
  .footer-links2 {
    gap: var(--dl-space-space-unit);
  }
  .footer-links3 {
    gap: var(--dl-space-space-unit);
  }
  .footer-branding {
    width: var(--dl-size-size-medium);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-text10 {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .footer-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .footer-top {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .footer-text {
    align-self: center;
  }
  .footer-subscription {
    width: 100%;
  }
  .footer-text02 {
    align-self: center;
  }
  .footer-category {
    width: 170px;
  }
  .footer-category1 {
    width: 110px;
  }
  .footer-category2 {
    width: 140px;
  }
  .footer-category3 {
    width: 170px;
  }
  .footer-bottom {
    margin-top: var(--dl-space-space-unit);
  }
  .footer-branding {
    width: 200px;
    height: auto;
    align-self: center;
  }
  .footer-text10 {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .footer-subscription {
    width: auto;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .footer-category2 {
    width: 150px;
  }
}
</style>
