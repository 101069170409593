<template>
  <div class="website-security-container">
    <section class="website-security-hero">
      <header-menu rootClassName="header-menu-root-class-name1"></header-menu>
      <div class="website-security-header">
        <h1 class="website-security-heading">
          WordPress Security Service: Keep Your Site Safe and Secure
        </h1>
        <span class="website-security-text">
          <span>
            WordPress is a powerful content management system (CMS) that is used
            by millions of people around the world. However, WordPress websites
            are also a common target for hackers. There were 4.3% of WordPress
            sites that were hacked.
            <span v-html="rawryem"></span>
          </span>
          <span>Source</span>
          <span>:</span>
          <span><span v-html="raw7u6p"></span></span>
          <a
            href="https://sucuri.net/reports/2021-hacked-website-report/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Sucuri
          </a>
        </span>
      </div>
      <dashboard-preview
        Image_src="/wordpress%20website%20security-1400w.jpeg"
        rootClassName="dashboard-preview-root-class-name4"
      ></dashboard-preview>
    </section>
    <section class="website-security-features">
      <div class="website-security-title">
        <span id="features" class="website-security-text05">
          Almost 1 in every 25 WordPress sites has been hacked.
        </span>
        <a href="#pricing" class="website-security-link1 button">
          <span class="website-security-text06">
            <span>Let us Protect your Website</span>
            <br />
          </span>
        </a>
        <span class="website-security-text09">
          Our WordPress Security Service can help you keep your website safe and
          secure. We offer a comprehensive suite of security features, including
        </span>
      </div>
      <div class="website-security-cards">
        <div class="website-security-container1">
          <div class="website-security-schedule card">
            <img
              alt="pastedImage"
              src="/firewall-200h.png"
              class="website-security-icon"
            />
            <span class="website-security-text10">Firewall Protection</span>
            <span class="website-security-text11">
              Our team will install and configure a robust firewall on your
              website to prevent unauthorised access, block malicious traffic, and
              enhance overall security.
              <span v-html="rawr7qz"></span>
            </span>
          </div>
          <div class="website-security-schedule1 card">
            <img
              alt="pastedImage"
              src="/malware%20scan-200h.png"
              class="website-security-icon01"
            />
            <span class="website-security-text12">Daily Malware Scanning</span>
            <span class="website-security-text13">
              <span>
                Let our experts set up daily malware scanning for your website,
                providing you with timely alerts and protection against potential
                security threats and vulnerabilities.
              </span>
              <br />
            </span>
          </div>
        </div>
        <div class="website-security-container2">
          <div class="website-security-analyze card">
            <img
              alt="Security Alert"
              src="/security%20alert-200h.png"
              class="website-security-icon02"
            />
            <span class="website-security-text16">Intrusion Detection</span>
            <span class="website-security-text17">
              <span>
                We will set up Intrusion Detection on your website, so in case
                someone attempts to bruteforce login or hack, you&apos;ll receive
                notifications and have their IP blocked for enhanced security.
              </span>
              <br />
            </span>
          </div>
          <div class="website-security-get-leads card">
            <img
              alt="Automated Backups"
              src="/backup-200h.png"
              class="website-security-icon03"
            />
            <span class="website-security-text20">Automated Backups</span>
            <span class="website-security-text21">
              Secure your website&apos;s data with our daily Automated Backup
              setup service. In case of any mishap, you can always restore your
              website to a previous version with ease.
            </span>
          </div>
        </div>
        <div class="website-security-container3">
          <div class="website-security-analyze1 card">
            <img
              alt="Spam protection"
              src="/spam%20protection-200h.png"
              class="website-security-icon04"
            />
            <span class="website-security-text22">
              <span>Spam Protection</span>
              <br />
            </span>
            <span class="website-security-text25">
              Shield your website from unwanted spam with our Spam Protection
              setup service. Our experts will configure your site to prevent
              automated spam comments and contact form submissions.
            </span>
          </div>
          <div class="website-security-get-leads1 card">
            <img
              alt="pastedImage"
              src="/autoclean-200h.png"
              class="website-security-icon05"
            />
            <span class="website-security-text26">Update and Maintenance</span>
            <span class="website-security-text27">
              We will perform routine updates on your website&apos;s plugins and
              themes as well as conduct security maintenance to ensure your
              website remains safe and secure.
              <span v-html="rawnobc"></span>
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="website-security-statistics">
      <div class="website-security-container4">
        <active-users
          Caption="— Website Secured"
          Statistic="1000+"
          rootClassName="active-users-root-class-name25"
        ></active-users>
        <active-users
          Caption="— Backup"
          Statistic="Daily"
          rootClassName="active-users-root-class-name26"
        ></active-users>
        <active-users
          Caption="— Hacked Proof"
          Statistic="100%"
          rootClassName="active-users-root-class-name27"
        ></active-users>
        <active-users
          Caption="— Malware Scan"
          Statistic="Daily"
          rootClassName="active-users-root-class-name28"
        ></active-users>
      </div>
    </section>
    <section class="website-security-banners">
      <div class="website-security-banner">
        <div class="website-security-container5">
          <div class="website-security-left">
            <span class="website-security-text28">
              Why Your Website Needs Protection
            </span>
            <h2 class="website-security-text29 title">
              The Importance of WordPress Security
            </h2>
            <span class="website-security-text30">
              WordPress is a popular website-building platform that&apos;s often
              targeted by hackers. If your website isn&apos;t properly secured, it
              may be vulnerable to threats that compromise important data, harm
              its reputation, or even bring it down entirely. For this reason,
              WordPress security is extremely important. You can safeguard your
              website against attacks and ensure that it remains trustworthy and
              secure for both you and your visitors.
            </span>
            <a href="#pricing" class="website-security-link2">
              <div class="website-security-get-started template-button">
                <span class="website-security-text31">Learn more</span>
              </div>
            </a>
          </div>
          <div class="website-security-image-container">
            <img
              alt="Full WordPress Website Backup"
              src="/website%20security-1200w.png"
              class="website-security-cards-image"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" class="website-security-pricing">
      <div class="website-security-centered-container">
        <div class="website-security-heading1">
          <span class="website-security-text32 mobileHeading title">
            Pricing for all kind of Security 
          </span>
          <span class="website-security-text33">
            Security is not a one-time thing. However, if it&apos;s a personal or
            small website, you can opt for the Security plan only. Otherwise,
            choose the Security and Maintenance plan.
          </span>
        </div>
        <div class="website-security-cards1">
          <div class="website-security-card">
            <span class="website-security-text34">
              Only Security
              <span v-html="rawtd6w"></span>
            </span>
            <span class="website-security-text35">
              This is for the small website owners who don&apos;t need regular
              maintenance.
            </span>
            <span class="website-security-text36">$99.00</span>
            <span class="website-security-text37">What&apos;s included</span>
            <div class="website-security-bullet-points">
              <div class="website-security-point">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon06"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text38 Content">
                  One time security setup
                </span>
              </div>
              <div class="website-security-point1">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon08"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text39">
                  Security plugin install
                </span>
              </div>
              <div class="website-security-point2">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon10"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text40 Content">
                  Backup plugin install
                </span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/29/checkout"
              class="website-security-link3"
            >
              <div class="website-security-get-started1 template-button">
                <span class="website-security-text41">Secure now</span>
              </div>
            </a>
          </div>
          <div class="website-security-card1">
            <span class="website-security-text42">Security and Maintenance</span>
            <span class="website-security-text43">
              If you also looking for regular maintenance.
            </span>
            <span class="website-security-text44">$149.00/year</span>
            <span class="website-security-text45">What&apos;s included</span>
            <div class="website-security-bullet-points1">
              <div class="website-security-point3">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon12"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text46">
                  Security and Backup setup
                </span>
              </div>
              <div class="website-security-point4">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon14"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text47 Content">
                  Unlimited malware cleanup
                </span>
              </div>
              <div class="website-security-point5">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon16"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text48 Content">
                  Monthly Security maintenance
                </span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/31/checkout"
              class="website-security-link4"
            >
              <div class="website-security-get-started2 template-button">
                <span class="website-security-text49">
                  <span>Secure now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
          <div class="website-security-card2">
            <span class="website-security-text52">
              Security, Maintenance and Optimisation 
            </span>
            <span class="website-security-text53">
              <span>
                This package will make sure your website always secure and load
                fast.
              </span>
              <br />
            </span>
            <span class="website-security-text56">$199.00/year</span>
            <span class="website-security-text57">What&apos;s included</span>
            <div class="website-security-bullet-points2">
              <div class="website-security-point6">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon18"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text58">
                  Security, Backup and caching
                </span>
              </div>
              <div class="website-security-point7">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon20"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text59">
                  Unlimited malware cleanup
                </span>
              </div>
              <div class="website-security-point8">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-security-icon22"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-security-text60">
                  Monthly Security and Optimisation
                </span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/32/checkout"
              class="website-security-link5"
            >
              <div class="website-security-get-started3 template-button">
                <span class="website-security-text61">
                  <span>Secure now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import DashboardPreview from '../components/dashboard-preview'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'WebsiteSecurity',
  components: {
    HeaderMenu,
    DashboardPreview,
    ActiveUsers,
    AppFooter,
  },
  data() {
    return {
      rawryem: ' ',
      raw7u6p: ' ',
      rawr7qz: ' ',
      rawnobc: ' ',
      rawtd6w: ' ',
    }
  },
  metaInfo: {
    title: 'WordPress Website Security Services - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'Secure your WordPress website with our expert security services. Our team of professionals offers top-notch protection against cyber threats and attacks.',
      },
      {
        property: 'og:title',
        content: 'WordPress Website Security Services - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/6c9b6377-3f92-4a7b-9df1-d583b6ab0c4f?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.website-security-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.website-security-hero {
  width: 100%;
  height: 869px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.website-security-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-end;
}
.website-security-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.website-security-text {
  color: #ffffff;
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.website-security-features {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #efefef;
}
.website-security-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.website-security-text05 {
  font-size: 50px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 65px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.website-security-link1 {
  cursor: pointer;
  font-size: 45px;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
}
.website-security-text06 {
  font-style: normal;
  font-weight: 600;
}
.website-security-text09 {
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.website-security-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.website-security-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.website-security-schedule {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-security-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text10 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.website-security-text11 {
  line-height: 24px;
}
.website-security-schedule1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-security-icon01 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text12 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.website-security-text13 {
  line-height: 24px;
}
.website-security-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.website-security-analyze {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-security-icon02 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text16 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.website-security-text17 {
  line-height: 24px;
}
.website-security-get-leads {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #ffffff;
}
.website-security-icon03 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text20 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.website-security-text21 {
  line-height: 24px;
}
.website-security-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.website-security-analyze1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-security-icon04 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text22 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.website-security-text25 {
  line-height: 24px;
}
.website-security-get-leads1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-security-icon05 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text26 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.website-security-text27 {
  line-height: 24px;
}
.website-security-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.website-security-container4 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.website-security-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.website-security-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.website-security-container5 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.website-security-left {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.website-security-text28 {
  color: rgb(0, 100, 183);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.website-security-text29 {
  width: 596px;
  text-align: left;
}
.website-security-text30 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.website-security-link2 {
  display: contents;
}
.website-security-get-started {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.website-security-get-started:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.website-security-text31 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.website-security-image-container {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.website-security-cards-image {
  width: 582px;
  height: 425px;
  object-fit: contain;
}
.website-security-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #0064b7;
}
.website-security-centered-container {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.website-security-heading1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.website-security-text32 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.website-security-text33 {
  color: rgb(238, 233, 254);
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.website-security-cards1 {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.website-security-card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.website-security-text34 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text35 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.website-security-text36 {
  font-size: 40px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text37 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-bullet-points {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-security-point {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon06 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text38 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-point1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon08 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text39 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-point2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon10 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text40 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-link3 {
  display: contents;
}
.website-security-get-started1 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.website-security-get-started1:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.website-security-text41 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.website-security-card1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.website-security-text42 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text43 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.website-security-text44 {
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text45 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-bullet-points1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-security-point3 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon12 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text46 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-point4 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon14 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text47 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-point5 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon16 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text48 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-link4 {
  display: contents;
}
.website-security-get-started2 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.website-security-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.website-security-text49 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.website-security-card2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #000000;
}
.website-security-text52 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text53 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.website-security-text56 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-text57 {
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-security-bullet-points2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-security-point6 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon18 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text58 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-point7 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon20 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text59 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-point8 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-security-icon22 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-security-text60 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-security-link5 {
  display: contents;
}
.website-security-get-started3 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.website-security-get-started3:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.website-security-text61 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}
.website-security-text61:hover {
  color: var(--dl-color-gray-900);
}
@media(max-width: 1200px) {
  .website-security-hero {
    width: 100%;
    height: 985px;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .website-security-heading {
    color: rgb(255, 255, 255);
    width: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .website-security-text {
    font-size: 20px;
    line-height: 30px;
  }
  .website-security-features {
    padding-top: 380px;
  }
  .website-security-text09 {
    font-size: 20px;
    line-height: 30px;
  }
  .website-security-text22 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .website-security-text25 {
    line-height: 24px;
  }
  .website-security-text26 {
    line-height: 33px;
  }
  .website-security-text27 {
    line-height: 24px;
  }
  .website-security-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .website-security-container4 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
  .website-security-banner {
    width: 100%;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .website-security-left {
    width: 50%;
  }
  .website-security-cards-image {
    width: 100%;
  }
  .website-security-pricing {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .website-security-centered-container {
    flex-direction: column;
  }
}
@media(max-width: 991px) {
  .website-security-hero {
    height: 913px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .website-security-header {
    width: 100%;
    max-width: 1200px;
    margin-top: var(--dl-space-space-fourunits);
  }
  .website-security-heading {
    font-size: 50px;
    line-height: 60px;
    padding-top: var(--dl-space-space-twounits);
  }
  .website-security-text {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .website-security-features {
    height: auto;
    padding-top: 330px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .website-security-title {
    width: 100%;
    max-width: auto;
  }
  .website-security-text09 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .website-security-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .website-security-text11 {
    line-height: 21px;
  }
  .website-security-text12 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .website-security-text13 {
    line-height: 21px;
  }
  .website-security-text17 {
    line-height: 21px;
  }
  .website-security-text20 {
    font-size: 24px;
  }
  .website-security-text21 {
    line-height: 21px;
  }
  .website-security-text25 {
    line-height: 21px;
  }
  .website-security-text26 {
    font-size: 24px;
  }
  .website-security-text27 {
    line-height: 21px;
  }
  .website-security-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .website-security-container4 {
    height: 100%;
    flex-direction: row;
  }
  .website-security-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .website-security-text29 {
    width: 584px;
    font-size: 40px;
    line-height: 45px;
  }
  .website-security-cards-image {
    width: 343px;
    height: auto;
  }
  .website-security-pricing {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .website-security-centered-container {
    width: 100%;
  }
  .website-security-text32 {
    font-size: 45px;
    line-height: 50px;
  }
  .website-security-text33 {
    width: 100%;
    max-width: 600px;
  }
  .website-security-cards1 {
    display: flex;
    flex-direction: row;
  }
  .website-security-card {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .website-security-text35 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .website-security-text37 {
    font-size: 16px;
  }
  .website-security-card1 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .website-security-text43 {
    color: rgb(0, 0, 0);
    line-height: 24px;
  }
  .website-security-text45 {
    font-size: 16px;
  }
  .website-security-card2 {
    width: 100%;
    align-items: flex-start;
    padding-right: var(--dl-space-space-twounits);
  }
  .website-security-text53 {
    color: rgb(255, 255, 255);
    line-height: 24px;
  }
  .website-security-text56 {
    color: var(--dl-color-gray-white);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
  }
}
@media(max-width: 767px) {
  .website-security-hero {
    height: 702px;
    position: relative;
    padding-bottom: 180px;
    background-color: #0064b7;
  }
  .website-security-header {
    height: 372px;
    margin-top: 0px;
    justify-content: center;
  }
  .website-security-heading {
    height: auto;
    font-size: 40px;
    line-height: 50px;
    padding-top: var(--dl-space-space-fiveunits);
  }
  .website-security-text {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .website-security-features {
    height: auto;
    padding-top: 225px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .website-security-text05 {
    font-size: 40px;
  }
  .website-security-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .website-security-text10 {
    font-size: 24px;
  }
  .website-security-text11 {
    font-size: 14px;
  }
  .website-security-text12 {
    font-size: 24px;
  }
  .website-security-text13 {
    font-size: 14px;
  }
  .website-security-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .website-security-text16 {
    font-size: 24px;
  }
  .website-security-text17 {
    font-size: 14px;
  }
  .website-security-text21 {
    font-size: 14px;
  }
  .website-security-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .website-security-text22 {
    font-size: 24px;
  }
  .website-security-text25 {
    font-size: 14px;
  }
  .website-security-text27 {
    font-size: 14px;
  }
  .website-security-statistics {
    width: auto;
    height: auto;
  }
  .website-security-container4 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
  .website-security-banner {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .website-security-container5 {
    flex-direction: column;
  }
  .website-security-left {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .website-security-text29 {
    width: auto;
  }
  .website-security-cards-image {
    width: auto;
  }
  .website-security-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .website-security-centered-container {
    width: auto;
    flex-direction: column;
  }
  .website-security-cards1 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .website-security-card {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .website-security-text34 {
    font-size: 28px;
  }
  .website-security-text35 {
    width: auto;
  }
  .website-security-text36 {
    font-size: 28px;
  }
  .website-security-bullet-points {
    width: 100%;
  }
  .website-security-card1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .website-security-text42 {
    font-size: 28px;
  }
  .website-security-text43 {
    width: auto;
  }
  .website-security-text44 {
    font-size: 28px;
  }
  .website-security-card2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .website-security-text52 {
    font-size: 28px;
  }
  .website-security-text53 {
    width: auto;
  }
  .website-security-text56 {
    font-size: 28px;
  }
}
@media(max-width: 479px) {
  .website-security-hero {
    height: 799px;
    padding-bottom: 51px;
  }
  .website-security-header {
    height: auto;
    padding-top: var(--dl-space-space-threeunits);
  }
  .website-security-heading {
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .website-security-text {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .website-security-features {
    height: auto;
    padding-top: 145px;
  }
  .website-security-text05 {
    font-size: 32px;
    line-height: 45px;
  }
  .website-security-link1 {
    height: auto;
    font-size: 25px;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .website-security-text06 {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
  }
  .website-security-text09 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .website-security-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .website-security-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .website-security-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .website-security-get-leads {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .website-security-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .website-security-get-leads1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .website-security-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .website-security-container4 {
    width: auto;
    flex-direction: column;
  }
  .website-security-left {
    width: 100%;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .website-security-text29 {
    width: auto;
    font-size: 32px;
    line-height: 38px;
  }
  .website-security-cards-image {
    width: 100%;
    height: auto;
  }
  .website-security-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .website-security-centered-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .website-security-text32 {
    font-size: 32px;
    line-height: 38px;
  }
  .website-security-cards1 {
    flex-direction: column;
  }
}
</style>
