<template>
  <div class="active-users-active-users metrics" v-bind:class="rootClassName">
    <span class="active-users-text">{{ Statistic }}</span>
    <span class="active-users-text1">{{ Caption }}</span>
  </div>
</template>

<script>
export default {
  name: 'ActiveUsers',
  props: {
    rootClassName: String,
    Caption: {
      type: String,
      default: 'Caption',
    },
    Statistic: {
      type: String,
      default: 'Description',
    },
  },
}
</script>

<style scoped>
.active-users-active-users {
  position: relative;
}
.active-users-text {
  color: rgb(255, 255, 255);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
}
.active-users-text1 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-top: 13px;
}





























































































@media(max-width: 1200px) {
  .active-users-active-users {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .active-users-root-class-name3 {
    width: auto;
  }
  .active-users-root-class-name7 {
    width: auto;
  }
  .active-users-root-class-name11 {
    width: auto;
  }
  .active-users-root-class-name15 {
    width: auto;
  }
  .active-users-root-class-name19 {
    width: auto;
  }
  .active-users-root-class-name23 {
    width: auto;
  }
  .active-users-root-class-name27 {
    width: auto;
  }
  .active-users-root-class-name31 {
    width: auto;
  }
  .active-users-root-class-name35 {
    width: auto;
  }
  .active-users-root-class-name39 {
    width: auto;
  }
  .active-users-root-class-name43 {
    width: auto;
  }
  .active-users-root-class-name47 {
    width: auto;
  }
  .active-users-root-class-name51 {
    width: auto;
  }
  .active-users-root-class-name55 {
    width: auto;
  }
  .active-users-root-class-name59 {
    width: auto;
  }
  .active-users-root-class-name63 {
    width: auto;
  }
  .active-users-root-class-name67 {
    width: auto;
  }
  .active-users-root-class-name71 {
    width: auto;
  }
  .active-users-root-class-name75 {
    width: auto;
  }
  .active-users-root-class-name79 {
    width: auto;
  }
  .active-users-root-class-name83 {
    width: auto;
  }
  .active-users-root-class-name87 {
    width: auto;
  }
  .active-users-root-class-name91 {
    width: auto;
  }
}
@media(max-width: 991px) {
  .active-users-active-users {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .active-users-text {
    font-size: 32px;
  }
  .active-users-text1 {
    text-align: center;
  }
  .active-users-root-class-name4 {
    width: auto;
  }
  .active-users-root-class-name6 {
    width: auto;
  }
  .active-users-root-class-name10 {
    width: auto;
  }
  .active-users-root-class-name14 {
    width: auto;
  }
  .active-users-root-class-name18 {
    width: auto;
  }
  .active-users-root-class-name22 {
    width: auto;
  }
  .active-users-root-class-name26 {
    width: auto;
  }
  .active-users-root-class-name30 {
    width: auto;
  }
  .active-users-root-class-name34 {
    width: auto;
  }
  .active-users-root-class-name38 {
    width: auto;
  }
  .active-users-root-class-name42 {
    width: auto;
  }
  .active-users-root-class-name46 {
    width: auto;
  }
  .active-users-root-class-name50 {
    width: auto;
  }
  .active-users-root-class-name54 {
    width: auto;
  }
  .active-users-root-class-name58 {
    width: auto;
  }
  .active-users-root-class-name62 {
    width: auto;
  }
  .active-users-root-class-name66 {
    width: auto;
  }
  .active-users-root-class-name70 {
    width: auto;
  }
  .active-users-root-class-name74 {
    width: auto;
  }
  .active-users-root-class-name78 {
    width: auto;
  }
  .active-users-root-class-name82 {
    width: auto;
  }
  .active-users-root-class-name86 {
    width: auto;
  }
  .active-users-root-class-name90 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .active-users-active-users {
    width: auto;
    align-items: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    justify-content: center;
  }
  .active-users-text {
    font-size: 28px;
  }
  .active-users-text1 {
    font-size: 14px;
  }
  .active-users-root-class-name {
    flex: 1;
    width: auto;
  }
  .active-users-root-class-name1 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name2 {
    width: auto;
  }
  .active-users-root-class-name5 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name8 {
    width: auto;
  }
  .active-users-root-class-name9 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name12 {
    width: auto;
  }
  .active-users-root-class-name13 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name16 {
    width: auto;
  }
  .active-users-root-class-name17 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name20 {
    width: auto;
  }
  .active-users-root-class-name21 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name24 {
    width: auto;
  }
  .active-users-root-class-name25 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name28 {
    width: auto;
  }
  .active-users-root-class-name29 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name32 {
    width: auto;
  }
  .active-users-root-class-name33 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name36 {
    width: auto;
  }
  .active-users-root-class-name37 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name40 {
    width: auto;
  }
  .active-users-root-class-name41 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name44 {
    width: auto;
  }
  .active-users-root-class-name45 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name48 {
    width: auto;
  }
  .active-users-root-class-name49 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name52 {
    width: auto;
  }
  .active-users-root-class-name53 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name56 {
    width: auto;
  }
  .active-users-root-class-name57 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name60 {
    width: auto;
  }
  .active-users-root-class-name61 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name64 {
    width: auto;
  }
  .active-users-root-class-name65 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name68 {
    width: auto;
  }
  .active-users-root-class-name69 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name72 {
    width: auto;
  }
  .active-users-root-class-name73 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name76 {
    width: auto;
  }
  .active-users-root-class-name77 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name80 {
    width: auto;
  }
  .active-users-root-class-name81 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name84 {
    width: auto;
  }
  .active-users-root-class-name85 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name88 {
    width: auto;
  }
  .active-users-root-class-name89 {
    flex: 1;
    width: auto;
    position: static;
  }
  .active-users-root-class-name92 {
    width: auto;
  }
}
@media(max-width: 479px) {
  .active-users-active-users {
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .active-users-text {
    font-size: 28px;
  }
  .active-users-text1 {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
  .active-users-root-class-name1 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name2 {
    width: 100%;
  }
  .active-users-root-class-name3 {
    width: 100%;
  }
  .active-users-root-class-name4 {
    width: 100%;
  }
  .active-users-root-class-name5 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name6 {
    width: 100%;
  }
  .active-users-root-class-name7 {
    width: 100%;
  }
  .active-users-root-class-name8 {
    width: 100%;
  }
  .active-users-root-class-name9 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name10 {
    width: 100%;
  }
  .active-users-root-class-name11 {
    width: 100%;
  }
  .active-users-root-class-name12 {
    width: 100%;
  }
  .active-users-root-class-name13 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name14 {
    width: 100%;
  }
  .active-users-root-class-name15 {
    width: 100%;
  }
  .active-users-root-class-name16 {
    width: 100%;
  }
  .active-users-root-class-name17 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name18 {
    width: 100%;
  }
  .active-users-root-class-name19 {
    width: 100%;
  }
  .active-users-root-class-name20 {
    width: 100%;
  }
  .active-users-root-class-name21 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name22 {
    width: 100%;
  }
  .active-users-root-class-name23 {
    width: 100%;
  }
  .active-users-root-class-name24 {
    width: 100%;
  }
  .active-users-root-class-name25 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name26 {
    width: 100%;
  }
  .active-users-root-class-name27 {
    width: 100%;
  }
  .active-users-root-class-name28 {
    width: 100%;
  }
  .active-users-root-class-name29 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name30 {
    width: 100%;
  }
  .active-users-root-class-name31 {
    width: 100%;
  }
  .active-users-root-class-name32 {
    width: 100%;
  }
  .active-users-root-class-name33 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name34 {
    width: 100%;
  }
  .active-users-root-class-name35 {
    width: 100%;
  }
  .active-users-root-class-name36 {
    width: 100%;
  }
  .active-users-root-class-name37 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name38 {
    width: 100%;
  }
  .active-users-root-class-name39 {
    width: 100%;
  }
  .active-users-root-class-name40 {
    width: 100%;
  }
  .active-users-root-class-name41 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name42 {
    width: 100%;
  }
  .active-users-root-class-name43 {
    width: 100%;
  }
  .active-users-root-class-name44 {
    width: 100%;
  }
  .active-users-root-class-name45 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name46 {
    width: 100%;
  }
  .active-users-root-class-name47 {
    width: 100%;
  }
  .active-users-root-class-name48 {
    width: 100%;
  }
  .active-users-root-class-name49 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name50 {
    width: 100%;
  }
  .active-users-root-class-name51 {
    width: 100%;
  }
  .active-users-root-class-name52 {
    width: 100%;
  }
  .active-users-root-class-name53 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name54 {
    width: 100%;
  }
  .active-users-root-class-name55 {
    width: 100%;
  }
  .active-users-root-class-name56 {
    width: 100%;
  }
  .active-users-root-class-name57 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name58 {
    width: 100%;
  }
  .active-users-root-class-name59 {
    width: 100%;
  }
  .active-users-root-class-name60 {
    width: 100%;
  }
  .active-users-root-class-name61 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name62 {
    width: 100%;
  }
  .active-users-root-class-name63 {
    width: 100%;
  }
  .active-users-root-class-name64 {
    width: 100%;
  }
  .active-users-root-class-name65 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name66 {
    width: 100%;
  }
  .active-users-root-class-name67 {
    width: 100%;
  }
  .active-users-root-class-name68 {
    width: 100%;
  }
  .active-users-root-class-name69 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name70 {
    width: 100%;
  }
  .active-users-root-class-name71 {
    width: 100%;
  }
  .active-users-root-class-name72 {
    width: 100%;
  }
  .active-users-root-class-name73 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name74 {
    width: 100%;
  }
  .active-users-root-class-name75 {
    width: 100%;
  }
  .active-users-root-class-name76 {
    width: 100%;
  }
  .active-users-root-class-name77 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name78 {
    width: 100%;
  }
  .active-users-root-class-name79 {
    width: 100%;
  }
  .active-users-root-class-name80 {
    width: 100%;
  }
  .active-users-root-class-name81 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name82 {
    width: 100%;
  }
  .active-users-root-class-name83 {
    width: 100%;
  }
  .active-users-root-class-name84 {
    width: 100%;
  }
  .active-users-root-class-name85 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name86 {
    width: 100%;
  }
  .active-users-root-class-name87 {
    width: 100%;
  }
  .active-users-root-class-name88 {
    width: 100%;
  }
  .active-users-root-class-name89 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .active-users-root-class-name90 {
    width: 100%;
  }
  .active-users-root-class-name91 {
    width: 100%;
  }
  .active-users-root-class-name92 {
    width: 100%;
  }
}
</style>
