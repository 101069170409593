import { render, staticRenderFns } from "./how-to-reduce-word-press-website-running-cost.vue?vue&type=template&id=6ec8287e&scoped=true&"
import script from "./how-to-reduce-word-press-website-running-cost.vue?vue&type=script&lang=js&"
export * from "./how-to-reduce-word-press-website-running-cost.vue?vue&type=script&lang=js&"
import style0 from "./how-to-reduce-word-press-website-running-cost.vue?vue&type=style&index=0&id=6ec8287e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec8287e",
  null
  
)

export default component.exports