<template>
  <div class="dashboard-preview-dashboard-preview" v-bind:class="rootClassName">
    <div class="dashboard-preview-outline">
      <img
        :alt="Image_alt"
        :src="Image_src"
        loading="eager"
        class="dashboard-preview-image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardPreview',
  props: {
    Image_alt: {
      type: String,
      default: 'CleanWP Pro - The ultimate WordPress cleaner plugin',
    },
    Image_src: {
      type: String,
      default: '/malware%20cleanup%20services-1400w.jpeg',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.dashboard-preview-dashboard-preview {
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #107bd2;
  border-width: 20px;
  border-radius: 80px;
  flex-direction: column;
  background-color: #107bd2;
}
.dashboard-preview-outline {
  display: flex;
  align-items: center;
  border-color: #505ef7;
  border-width: 10px;
  border-radius: 70px;
  flex-direction: column;
  background-color: #505ef7;
}
.dashboard-preview-image {
  width: 1069px;
  height: 712px;
  z-index: 10;
  user-select: none;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 70px;
}
.dashboard-preview-root-class-name {
  top: var(--dl-space-space-halfunit);
  position: static;
}
.dashboard-preview-root-class-name1 {
  top: var(--dl-space-space-halfunit);
  position: static;
}
.dashboard-preview-root-class-name2 {
  top: var(--dl-space-space-halfunit);
  position: static;
}
.dashboard-preview-root-class-name3 {
  top: var(--dl-space-space-halfunit);
  position: static;
}
.dashboard-preview-root-class-name4 {
  top: var(--dl-space-space-halfunit);
  position: static;
}
.dashboard-preview-root-class-name5 {
  top: var(--dl-space-space-halfunit);
  position: static;
}
.dashboard-preview-root-class-name6 {
  top: var(--dl-space-space-halfunit);
  position: static;
}
.dashboard-preview-root-class-name7 {
  top: var(--dl-space-space-halfunit);
  position: static;
}
@media(max-width: 1200px) {
  .dashboard-preview-dashboard-preview {
    top: 760px;
  }
  .dashboard-preview-image {
    animation-duration: 70px;
  }
  .dashboard-preview-root-class-name {
    width: auto;
    position: static;
    margin-top: var(--dl-space-space-halfunit);
  }
  .dashboard-preview-root-class-name1 {
    width: auto;
    position: static;
    margin-top: var(--dl-space-space-halfunit);
  }
  .dashboard-preview-root-class-name2 {
    width: auto;
    position: static;
    margin-top: var(--dl-space-space-halfunit);
  }
  .dashboard-preview-root-class-name3 {
    width: auto;
    position: static;
    margin-top: var(--dl-space-space-halfunit);
  }
  .dashboard-preview-root-class-name4 {
    width: auto;
    position: static;
    margin-top: var(--dl-space-space-halfunit);
  }
  .dashboard-preview-root-class-name5 {
    width: auto;
    position: static;
    margin-top: var(--dl-space-space-halfunit);
  }
  .dashboard-preview-root-class-name6 {
    width: auto;
    position: static;
    margin-top: var(--dl-space-space-halfunit);
  }
  .dashboard-preview-root-class-name7 {
    width: auto;
    position: static;
    margin-top: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 991px) {
  .dashboard-preview-dashboard-preview {
    top: 840px;
  }
  .dashboard-preview-image {
    width: 100%;
    height: auto;
    border-radius: 70px;
  }
}
@media(max-width: 767px) {
  .dashboard-preview-dashboard-preview {
    top: 570px;
    width: 90%;
    border-width: 10px;
    border-radius: 45px;
  }
  .dashboard-preview-outline {
    border-width: 10px;
    border-radius: 35px;
  }
  .dashboard-preview-image {
    width: 100%;
    border-radius: 35px;
  }
}
@media(max-width: 479px) {
  .dashboard-preview-dashboard-preview {
    top: 660px;
    height: auto;
  }
  .dashboard-preview-outline {
    border-width: 1px;
  }
  .dashboard-preview-image {
    width: 100%;
    height: auto;
    border-radius: 35px;
    margin-bottom: 3px;
  }
  .dashboard-preview-root-class-name {
    width: 100%;
  }
  .dashboard-preview-root-class-name1 {
    width: 100%;
  }
  .dashboard-preview-root-class-name2 {
    width: 100%;
  }
  .dashboard-preview-root-class-name3 {
    width: 100%;
  }
  .dashboard-preview-root-class-name4 {
    width: 100%;
  }
  .dashboard-preview-root-class-name5 {
    width: 100%;
  }
  .dashboard-preview-root-class-name6 {
    width: 100%;
  }
  .dashboard-preview-root-class-name7 {
    width: 100%;
  }
}
</style>
