<template>
  <div class="terms-of-services-container">
    <section class="terms-of-services-hero">
      <header-menu></header-menu>
      <div class="terms-of-services-header">
        <h1 class="terms-of-services-heading">Terms of Services</h1>
      </div>
    </section>
    <div class="terms-of-services-banner">
      <span>
        <span class="terms-of-services-text01">
          Last updated on June 10, 2023.
        </span>
        <br class="terms-of-services-text02" />
        <br class="terms-of-services-text03" />
        <span class="terms-of-services-text04">1. Terms</span>
        <br />
        <span>
          By accessing this Website, accessible from https://cleanwp.pro, you are
          agreeing to be bound by these Website Terms and Conditions and  of Use
          and agree that you are responsible for the agreement with any applicable
          local laws. If you disagree with any of these terms, you are prohibited
          from accessing this site. The materials contained in this Website are
          protected by copyright and trade mark law.
        </span>
        <br />
        <br />
        <span class="terms-of-services-text09">2. Use of License</span>
        <br />
        <span>
          Permission is granted to download and use CleanWP Pro&apos;s Plugin
          based on installing limit mentioned on the licence when you purchased.
          This is the grant of a license, not a transfer of title, and under this
          license you may not:
        </span>
        <br />
        <br />
        <span>a. modify or copy the materials;</span>
        <br />
        <span>
          b. use the Plugin or it&apos;s content for any commercial purpose or for
          any public display;
        </span>
        <br />
        <span>
          c. attempt to reverse engineer any software contained on CleanWP
          Pro&apos;s Website;
        </span>
        <br />
        <span>
          d. remove any copyright or other proprietary notations from the
          materials; or
        </span>
        <br />
        <span>
          e. transferring the materials to another person or &quot;mirror&quot;
          the materials on any other server.
        </span>
        <br />
        <span>
          f. This will let CleanWP Pro to terminate upon violations of any of
          these restrictions. Upon termination, your viewing right will also be
          terminated and you should destroy any downloaded materials in your
          possession whether it is printed or electronic format. These Terms of
          Service has been created with the help of the Terms Of Service
          Generator.
        </span>
        <br />
        <br />
        <span class="terms-of-services-text27">3. Use of services</span>
        <br />
        <span>
          a. The services offer on CleanWP Pro, based on the terms and service
          mentioned on there respective sales page.
        </span>
        <br />
        <span>
          b. We request that you take a full backup of your website before
          allowing us to handle it. This precautionary measure ensures that your
          data remains safe and secure in case of any unforeseen circumstances.
          While we do our best to recover any hacked or damaged websites, we
          cannot guarantee a successful recovery. However, we will exhaust all
          possibilities to try and restore your website with the utmost diligence
          and care.
        </span>
        <br />
        <span>
          c. Please note that any loss or damage incurred while attempting to
          recover your website cannot be attributed to us. We are not liable for
          any damages caused during the recovery process. It is vital to
          understand that restoring a hacked or damaged website can sometimes be a
          challenging task, and we encourage our clients always to maintain
          regular backups of their site to reduce any potential risks.
        </span>
        <br />
        <span>
          d. The working hours and task limits are reset every 30 days. However,
          it is important to understand that any unused credits from the previous
          month will not be carried forward to the next month. Therefore, it is
          recommended that you make the most of your allocated credits within the
          given time frame to avoid any loss of resources.
          <span v-html="rawe4b3"></span>
        </span>
        <br />
        <br />
        <span class="terms-of-services-text38">4. Disclaimer</span>
        <br />
        <span>
          All the materials on CleanWP Pro&apos;s Website are provided &quot;as
          is&quot;. CleanWP Pro makes no warranties, may it be expressed or
          implied, therefore negates all other warranties. Furthermore, CleanWP
          Pro does not make any representations concerning the accuracy or
          reliability of the use of the materials on its Website or otherwise
          relating to such materials or any sites linked to this Website.
        </span>
        <br />
        <br />
        <span class="terms-of-services-text43">5. Limitations</span>
        <br />
        <span>
          CleanWP Pro or its suppliers will not be hold accountable for any
          damages that will arise with the use or inability to use the materials
          on CleanWP Pro&apos;s Website, even if CleanWP Pro or an authorize
          representative of this Website has been notified, orally or written, of
          the possibility of such damage. Some jurisdiction does not allow
          limitations on implied warranties or limitations of liability for
          incidental damages, these limitations may not apply to you.
        </span>
        <br />
        <br />
        <span class="terms-of-services-text48">6. Revisions and Errata</span>
        <br />
        <span>
          The materials appearing on CleanWP Pro&apos;s Website may include
          technical, typographical, or photographic errors. CleanWP Pro will not
          promise that any of the materials in this Website are accurate,
          complete, or current. CleanWP Pro may change the materials contained on
          its Website at any time without notice. CleanWP Pro does not make any
          commitment to update the materials.
        </span>
        <br />
        <br />
        <span class="terms-of-services-text53">
          7. Site Terms of Use Modifications
        </span>
        <br />
        <span>
          CleanWP Pro may revise these Terms of Use for its Website at any time
          without prior notice. By using this Website, you are agreeing to be
          bound by the current version of these Terms and Conditions of Use.
        </span>
        <br />
        <br />
        <span class="terms-of-services-text58">8. Your Privacy</span>
        <br />
        <span>Please read our Privacy Policy page.</span>
        <br />
        <br />
        <span>9. Governing Law</span>
        <br />
        <span>
          Any claim related to CleanWP Pro&apos;s Website shall be governed by the
          laws of in without regards to its conflict of law provisions.
        </span>
        <br />
        <br />
      </span>
    </div>
    <section class="terms-of-services-statistics">
      <div class="terms-of-services-container1">
        <active-users
          Caption="— Products &amp; Services"
          Statistic="5"
          rootClassName="active-users-root-class-name89"
        ></active-users>
        <active-users
          Caption="— Years of experience"
          Statistic="10+"
          rootClassName="active-users-root-class-name90"
        ></active-users>
        <active-users
          Caption="— Customers"
          Statistic="1000+"
          rootClassName="active-users-root-class-name91"
        ></active-users>
        <active-users
          Caption="— In house Team"
          Statistic="10+"
          rootClassName="active-users-root-class-name92"
        ></active-users>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name16"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'TermsOfServices',
  components: {
    HeaderMenu,
    ActiveUsers,
    AppFooter,
  },
  data() {
    return {
      rawe4b3: ' ',
    }
  },
  metaInfo: {
    title: 'Terms of Services - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'CleanWP Pro puts customers first, committed to delivering on our promises and providing high-quality services to all, regardless of discounts or deals.',
      },
      {
        property: 'og:title',
        content: 'Terms of Services - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/a9853e78-7538-4371-8114-3881ac3d15b2?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.terms-of-services-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.terms-of-services-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.terms-of-services-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.terms-of-services-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.terms-of-services-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.terms-of-services-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.terms-of-services-container1 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 1600px) {
  .terms-of-services-hero {
    padding-bottom: var(--dl-space-space-unit);
  }
  .terms-of-services-heading {
    color: rgb(255, 255, 255);
  }
  .terms-of-services-text01 {
    font-style: normal;
    font-weight: 600;
    text-decoration: underline;
  }
  .terms-of-services-text02 {
    font-weight: 700;
  }
  .terms-of-services-text03 {
    font-weight: 700;
  }
  .terms-of-services-text04 {
    font-weight: 700;
  }
  .terms-of-services-text09 {
    font-weight: 700;
  }
  .terms-of-services-text27 {
    font-weight: 700;
  }
  .terms-of-services-text38 {
    font-weight: 700;
  }
  .terms-of-services-text43 {
    font-weight: 700;
  }
  .terms-of-services-text48 {
    font-weight: 700;
  }
  .terms-of-services-text53 {
    font-weight: 700;
  }
  .terms-of-services-text58 {
    font-weight: 700;
  }
}
@media(max-width: 1200px) {
  .terms-of-services-hero {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .terms-of-services-header {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .terms-of-services-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .terms-of-services-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .terms-of-services-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .terms-of-services-container1 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .terms-of-services-hero {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .terms-of-services-header {
    width: 100%;
    max-width: 1200px;
  }
  .terms-of-services-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .terms-of-services-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .terms-of-services-container1 {
    height: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .terms-of-services-hero {
    height: auto;
    position: relative;
    padding-bottom: 0px;
    background-color: #0064b7;
  }
  .terms-of-services-header {
    height: auto;
    justify-content: center;
  }
  .terms-of-services-heading {
    font-size: 40px;
    padding-top: var(--dl-space-space-unit);
  }
  .terms-of-services-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .terms-of-services-statistics {
    width: auto;
    height: auto;
  }
  .terms-of-services-container1 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .terms-of-services-hero {
    height: auto;
    padding-top: 4px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .terms-of-services-header {
    height: auto;
    padding-top: var(--dl-space-space-threeunits);
  }
  .terms-of-services-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .terms-of-services-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .terms-of-services-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .terms-of-services-container1 {
    width: auto;
    flex-direction: column;
  }
}
</style>
