<template>
  <div class="privacy-policy-container">
    <section class="privacy-policy-hero">
      <header-menu></header-menu>
      <div class="privacy-policy-header">
        <h1 class="privacy-policy-heading">
          <span>Privacy Policy</span>
          <br />
        </h1>
      </div>
    </section>
    <div class="privacy-policy-banner">
      <span>
        <span class="privacy-policy-text003">Last updated on June 10, 2023.</span>
        <br class="privacy-policy-text004" />
        <br />
        <span>
          At CleanWP.Pro, accessible from https://cleanwp.pro, one of our main
          priorities is the privacy of our visitors. This Privacy Policy document
          contains types of information that is collected and recorded by
          CleanWP.Pro and how we use it.
        </span>
        <br />
        <br />
        <span>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </span>
        <br />
        <br />
        <span>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in CleanWP.Pro. This policy is not applicable to
          any information collected offline or via channels other than this
          website.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text015">Consent</span>
        <br />
        <span>
          By using our website, you hereby consent to our Privacy Policy and agree
          to its terms.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text020">Information we collect</span>
        <br class="privacy-policy-text021" />
        <span>
          The personal information that you are asked to provide, and the reasons
          why you are asked to provide it, will be made clear to you at the point
          we ask you to provide your personal information.
        </span>
        <br />
        <br />
        <span>
          If you contact us directly, we may receive additional information about
          you such as your name, email address, phone number, the contents of the
          message and/or attachments you may send us, and any other information
          you may choose to provide.
        </span>
        <br />
        <br />
        <span>
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address, email
          address, and telephone number.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text031">How we use your information</span>
        <br class="privacy-policy-text032" />
        <span>
          We use the information we collect in various ways, including to:
        </span>
        <br />
        <br />
        <span class="privacy-policy-text036">
          1. Provide, operate, and maintain our website
        </span>
        <br class="privacy-policy-text037" />
        <span class="privacy-policy-text038">
          2. Improve, personalize, and expand our website
        </span>
        <br class="privacy-policy-text039" />
        <span class="privacy-policy-text040">
          3. Understand and analyze how you use our website
        </span>
        <br class="privacy-policy-text041" />
        <span class="privacy-policy-text042">
          4. Develop new products, services, features, and functionality
        </span>
        <br class="privacy-policy-text043" />
        <span class="privacy-policy-text044">
          5. Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </span>
        <br class="privacy-policy-text045" />
        <span class="privacy-policy-text046">6. Send you emails</span>
        <br class="privacy-policy-text047" />
        <span class="privacy-policy-text048">7. Find and prevent fraud</span>
        <br class="privacy-policy-text049" />
        <span class="privacy-policy-text050">8. Log Files</span>
        <br />
        <br />
        <span>
          CleanWP.Pro follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this and
          a part of hosting services&apos; analytics. The information collected by
          log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users&apos; movement on the website, and gathering demographic
          information.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text056">Cookies and Web Beacons</span>
        <br />
        <span>
          Like any other website, CleanWP.Pro uses &quot;cookies&quot;. These
          cookies are used to store information including visitors&apos;
          preferences, and the pages on the website that the visitor accessed or
          visited. The information is used to optimize the users&apos; experience
          by customizing our web page content based on visitors&apos; browser type
          and/or other information.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text061">
          Advertising Partners Privacy Policies
        </span>
        <br />
        <span>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of CleanWP.Pro.
        </span>
        <br />
        <br />
        <span>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on CleanWP.Pro, which are sent
          directly to users&apos; browser. They automatically receive your IP
          address when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </span>
        <br />
        <br />
        <span>
          Note that CleanWP.Pro has no access to or control over these cookies
          that are used by third-party advertisers.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text072">Third Party Privacy Policies</span>
        <br />
        <span>
          CleanWP.Pro&apos;s Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed information.
          It may include their practices and instructions about how to opt-out of
          certain options.
        </span>
        <br />
        <br />
        <span>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management with
          specific web browsers, it can be found at the browsers&apos; respective
          websites.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text080">
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </span>
        <br />
        <span>
          Under the CCPA, among other rights, California consumers have the right
          to:
        </span>
        <br />
        <br />
        <span>
          Request that a business that collects a consumer&apos;s personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </span>
        <br />
        <br />
        <span>
          Request that a business delete any personal data about the consumer that
          a business has collected.
        </span>
        <br />
        <br />
        <span>
          Request that a business that sells a consumer&apos;s personal data, not
          sell the consumer&apos;s personal data.
        </span>
        <br />
        <br />
        <span>
          If you make a request, we have one month to respond to you. If you would
          like to exercise any of these rights, please contact us.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text097">GDPR Data Protection Rights</span>
        <br />
        <span>
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </span>
        <br />
        <br />
        <span>
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </span>
        <br />
        <br />
        <span>
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </span>
        <br />
        <br />
        <span>
          The right to erasure – You have the right to request that we erase your
          personal data, under certain conditions.
        </span>
        <br />
        <br />
        <span>
          The right to restrict processing – You have the right to request that we
          restrict the processing of your personal data, under certain conditions.
        </span>
        <br />
        <br />
        <span>
          The right to object to processing – You have the right to object to our
          processing of your personal data, under certain conditions.
        </span>
        <br />
        <br />
        <span>
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </span>
        <br />
        <br />
        <span>
          If you make a request, we have one month to respond to you. If you would
          like to exercise any of these rights, please contact us.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text123">Children&apos;s Information</span>
        <br />
        <span>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </span>
        <br />
        <br />
        <span>
          CleanWP.Pro does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best efforts
          to promptly remove such information from our records.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text131">Changes to This Privacy Policy</span>
        <br />
        <span>
          We may update our Privacy Policy from time to time. Thus, we advise you
          to review this page periodically for any changes. We will notify you of
          any changes by posting the new Privacy Policy on this page. These
          changes are effective immediately, after they are posted on this page.
        </span>
        <br />
        <br />
        <span class="privacy-policy-text136">Contact Us</span>
        <br />
        <span>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us.
        </span>
      </span>
    </div>
    <section class="privacy-policy-statistics">
      <div class="privacy-policy-container1">
        <active-users
          Caption="— Products &amp; Services"
          Statistic="5"
          rootClassName="active-users-root-class-name73"
        ></active-users>
        <active-users
          Caption="— Years of experience"
          Statistic="10+"
          rootClassName="active-users-root-class-name74"
        ></active-users>
        <active-users
          Caption="— Customers"
          Statistic="1000+"
          rootClassName="active-users-root-class-name75"
        ></active-users>
        <active-users
          Caption="— In house Team"
          Statistic="10+"
          rootClassName="active-users-root-class-name76"
        ></active-users>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name12"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'PrivacyPolicy',
  components: {
    HeaderMenu,
    ActiveUsers,
    AppFooter,
  },
  metaInfo: {
    title: 'Privacy Policy - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'CleanWP Pro is the ultimate WordPress optimization plugin. Speed up your website, improve SEO rankings, and enhance user experience with ease. Try it today!',
      },
      {
        property: 'og:title',
        content: 'Privacy Policy - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/abd66c23-d74b-428e-83e3-466ed776f0ff?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.privacy-policy-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.privacy-policy-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.privacy-policy-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.privacy-policy-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.privacy-policy-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.privacy-policy-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.privacy-policy-container1 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 1600px) {
  .privacy-policy-hero {
    padding-bottom: var(--dl-space-space-unit);
  }
  .privacy-policy-heading {
    color: rgb(255, 255, 255);
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    text-transform: none;
    text-decoration: none;
  }
  .privacy-policy-text003 {
    font-style: normal;
    font-weight: 600;
    text-decoration: underline;
  }
  .privacy-policy-text004 {
    font-style: normal;
    font-weight: 600;
    text-decoration: underline;
  }
  .privacy-policy-text015 {
    font-weight: 700;
  }
  .privacy-policy-text020 {
    font-weight: 700;
  }
  .privacy-policy-text021 {
    font-weight: 700;
  }
  .privacy-policy-text031 {
    font-weight: 700;
  }
  .privacy-policy-text032 {
    font-weight: 700;
  }
  .privacy-policy-text036 {
    font-weight: 500;
  }
  .privacy-policy-text037 {
    font-weight: 500;
  }
  .privacy-policy-text038 {
    font-weight: 500;
  }
  .privacy-policy-text039 {
    font-weight: 500;
  }
  .privacy-policy-text040 {
    font-weight: 500;
  }
  .privacy-policy-text041 {
    font-weight: 500;
  }
  .privacy-policy-text042 {
    font-weight: 500;
  }
  .privacy-policy-text043 {
    font-weight: 500;
  }
  .privacy-policy-text044 {
    font-weight: 500;
  }
  .privacy-policy-text045 {
    font-weight: 500;
  }
  .privacy-policy-text046 {
    font-weight: 500;
  }
  .privacy-policy-text047 {
    font-weight: 500;
  }
  .privacy-policy-text048 {
    font-weight: 500;
  }
  .privacy-policy-text049 {
    font-weight: 500;
  }
  .privacy-policy-text050 {
    font-weight: 500;
  }
  .privacy-policy-text056 {
    font-weight: 700;
  }
  .privacy-policy-text061 {
    font-weight: 700;
  }
  .privacy-policy-text072 {
    font-weight: 700;
  }
  .privacy-policy-text080 {
    font-weight: 700;
  }
  .privacy-policy-text097 {
    font-weight: 700;
  }
  .privacy-policy-text123 {
    font-weight: 700;
  }
  .privacy-policy-text131 {
    font-weight: 700;
  }
  .privacy-policy-text136 {
    font-weight: 700;
  }
}
@media(max-width: 1200px) {
  .privacy-policy-hero {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .privacy-policy-header {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .privacy-policy-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .privacy-policy-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .privacy-policy-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-container1 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .privacy-policy-hero {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .privacy-policy-header {
    width: 100%;
    max-width: 1200px;
  }
  .privacy-policy-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .privacy-policy-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-container1 {
    height: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .privacy-policy-hero {
    height: auto;
    position: relative;
    padding-bottom: 0px;
    background-color: #0064b7;
  }
  .privacy-policy-header {
    height: auto;
    justify-content: center;
  }
  .privacy-policy-heading {
    font-size: 40px;
    padding-top: var(--dl-space-space-unit);
  }
  .privacy-policy-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .privacy-policy-statistics {
    width: auto;
    height: auto;
  }
  .privacy-policy-container1 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .privacy-policy-hero {
    height: auto;
    padding-top: 4px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .privacy-policy-header {
    height: auto;
    padding-top: var(--dl-space-space-threeunits);
  }
  .privacy-policy-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .privacy-policy-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .privacy-policy-container1 {
    width: auto;
    flex-direction: column;
  }
}
</style>
