<template>
  <div class="about-us-container">
    <section class="about-us-hero">
      <header-menu></header-menu>
      <div class="about-us-header">
        <h1 class="about-us-heading">About Us</h1>
        <span class="about-us-text">
          <span>
            At CleanWP Pro, we specialize in developing high-quality WordPress
            plugins and providing innovative solutions for optimal website
            performance and security.
          </span>
          <br />
        </span>
      </div>
      <dashboard-preview
        Image_src="/website%20speedup-1400w.png"
        rootClassName="dashboard-preview-root-class-name7"
      ></dashboard-preview>
    </section>
    <section class="about-us-features">
      <div class="about-us-title">
        <span id="features" class="about-us-text03">Our Mission</span>
        <span class="about-us-text04">
          <span class="about-us-text05">
            CleanWP Pro&apos;s mission is to empower individuals and businesses by
            providing top-tier WordPress solutions that enhance website
            performance, security, and functionality.
          </span>
          <br class="about-us-text06" />
          <span class="about-us-text07"><span v-html="rawxbtm"></span></span>
          <br />
          <span>
            We work hard to provide cutting-edge goods and services that let our
            customers reach their online objectives with assurance and peace of
            mind. Our devoted team of professionals is committed to providing
            superior solutions that exceed expectations and guarantee the
            long-term success of our customer&apos;s online endeavours.
          </span>
          <br />
        </span>
      </div>
      <div class="about-us-cards">
        <div class="about-us-container1">
          <div class="about-us-analyze card">
            <img alt="pastedImage" src="/https-200h.png" class="about-us-icon" />
            <span class="about-us-text11">Our Vision</span>
            <span class="about-us-text12">
              <span>
                Our vision at CleanWP Pro is to provide WordPress users with the
                tools and support they require to succeed online. Every website
                owner, in our opinion, should be able to conduct business with
                assurance and peace of mind, knowing that their website is safe,
                optimized, and operating at its best.
              </span>
              <br />
              <br />
              <span>
                Our team of professionals is committed to offering top-notch
                solutions that address the unique requirements of WordPress
                websites. We specialize in creating top-notch plugins that improve
                the operation of websites and provide premium services like
                security, optimization, malware cleanup, and maintenance to
                guarantee peak performance.
              </span>
              <br />
              <br />
              <span>
                We are committed to staying at the forefront of the industry,
                continually innovating and adapting to meet the changing needs of
                the WordPress community. Our vision is to be the most trusted and
                reliable provider of WordPress solutions, empowering website
                owners to achieve their full potential online.
              </span>
              <br />
            </span>
          </div>
          <div class="about-us-get-leads card">
            <img
              alt="pastedImage"
              src="/responsive-200h.png"
              class="about-us-icon1"
            />
            <span class="about-us-text21">Our Values</span>
            <span class="about-us-text22">
              <span>
                Our values are at the heart of everything we do. We believe in
                creating exceptional solutions that empower WordPress users to
                succeed online. Our values guide our decisions and actions,
                ensuring that we always act with integrity and professionalism.
              </span>
              <br />
              <br />
              <span>
                Customer focus:
                <span v-html="rawba4x"></span>
              </span>
              <span>
                We prioritize the needs of our clients and are dedicated to
                providing top-notch support.
                <span v-html="rawe3ef"></span>
              </span>
              <br />
              <br />
              <span>Innovation:</span>
              <span>
                Technology is something we are enthusiastic about, and we always
                work to make our products and services better. In order to ensure
                that our clients always have access to the most latest tools and
                solutions, we embrace new technology and adjust to changes in the
                business.
              </span>
              <br />
              <br />
              <span>
                Integrity:
                <span v-html="raw9co5"></span>
              </span>
              <span>
                We hold ourselves to the highest ethical standards, and we operate
                honestly and openly in all of our business transactions. We are
                dedicated to establishing long-lasting relationships with our
                clients based on mutual respect, trust, and benefit.
              </span>
              <br />
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="about-us-statistics">
      <div class="about-us-container2">
        <active-users
          Caption="— Products &amp; Services"
          Statistic="5"
          rootClassName="active-users-root-class-name41"
        ></active-users>
        <active-users
          Caption="— Years of experience"
          Statistic="10+"
          rootClassName="active-users-root-class-name42"
        ></active-users>
        <active-users
          Caption="— Customers"
          Statistic="1000+"
          rootClassName="active-users-root-class-name43"
        ></active-users>
        <active-users
          Caption="— In house Team"
          Statistic="10+"
          rootClassName="active-users-root-class-name44"
        ></active-users>
      </div>
    </section>
    <section class="about-us-banners"></section>
    <app-footer rootClassName="footer-root-class-name4"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import DashboardPreview from '../components/dashboard-preview'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'AboutUs',
  components: {
    HeaderMenu,
    DashboardPreview,
    ActiveUsers,
    AppFooter,
  },
  data() {
    return {
      rawxbtm: ' ',
      rawba4x: ' ',
      rawe3ef: ' ',
      raw9co5: ' ',
    }
  },
  metaInfo: {
    title: 'About  CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'At CleanWP Pro, we specialize in developing high-quality WordPress plugins and providing innovative solutions for optimal website performance and security.',
      },
      {
        property: 'og:title',
        content: 'About  CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/fb99cf74-c3f2-4e40-9e5c-e701940a2221?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.about-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.about-us-hero {
  width: 100%;
  height: 949px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.about-us-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.about-us-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.about-us-text {
  color: #ffffff;
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 0px;
  text-align: center;
  line-height: 30px;
  padding-top: var(--dl-space-space-twounits);
}
.about-us-features {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 250px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #efefef;
}
.about-us-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-us-text03 {
  font-size: 50px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 65px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.about-us-text04 {
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.about-us-text05 {
  width: auto;
  max-width: 900px;
  margin-top: 16px;
  text-align: center;
}
.about-us-text07 {
  width: auto;
  max-width: 900px;
  margin-top: 16px;
  text-align: center;
}
.about-us-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.about-us-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.about-us-analyze {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.about-us-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.about-us-text11 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.about-us-text12 {
  line-height: 24px;
}
.about-us-get-leads {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.about-us-icon1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.about-us-text21 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.about-us-text22 {
  line-height: 24px;
}
.about-us-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.about-us-container2 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-us-banners {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media(max-width: 1200px) {
  .about-us-hero {
    width: 100%;
    height: 963px;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .about-us-header {
    margin-top: var(--dl-space-space-sevenunits);
    margin-bottom: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-us-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .about-us-text {
    font-size: 20px;
    line-height: 30px;
  }
  .about-us-features {
    padding-top: 389px;
  }
  .about-us-title {
    height: auto;
  }
  .about-us-text04 {
    font-size: 20px;
    line-height: 30px;
  }
  .about-us-text05 {
    font-size: 20px;
    line-height: 30px;
  }
  .about-us-text06 {
    font-size: 20px;
    line-height: 30px;
  }
  .about-us-text07 {
    font-size: 20px;
    line-height: 30px;
  }
  .about-us-text11 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .about-us-text12 {
    line-height: 24px;
  }
  .about-us-text21 {
    line-height: 33px;
  }
  .about-us-text22 {
    line-height: 24px;
  }
  .about-us-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .about-us-container2 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .about-us-hero {
    height: 760px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .about-us-header {
    width: 100%;
    max-width: 1200px;
    margin-top: var(--dl-space-space-fourunits);
  }
  .about-us-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .about-us-text {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .about-us-features {
    height: 1280px;
    padding-top: 409px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .about-us-title {
    width: 100%;
    max-width: auto;
  }
  .about-us-text04 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .about-us-text05 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .about-us-text06 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .about-us-text07 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .about-us-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .about-us-text12 {
    line-height: 21px;
  }
  .about-us-text21 {
    font-size: 24px;
  }
  .about-us-text22 {
    line-height: 21px;
  }
  .about-us-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-us-container2 {
    height: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .about-us-hero {
    height: 739px;
    position: relative;
    padding-bottom: 180px;
    background-color: #0064b7;
  }
  .about-us-header {
    height: 372px;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .about-us-heading {
    font-size: 40px;
  }
  .about-us-features {
    height: auto;
    padding-top: 257px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .about-us-text03 {
    font-size: 40px;
  }
  .about-us-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .about-us-text11 {
    font-size: 24px;
  }
  .about-us-text12 {
    font-size: 14px;
  }
  .about-us-text22 {
    font-size: 14px;
  }
  .about-us-statistics {
    width: auto;
    height: auto;
  }
  .about-us-container2 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .about-us-hero {
    height: 630px;
    padding-bottom: 51px;
  }
  .about-us-header {
    height: auto;
    margin-top: 0px;
    padding-top: var(--dl-space-space-threeunits);
    margin-bottom: 0px;
  }
  .about-us-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .about-us-text {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .about-us-features {
    height: 1292px;
    padding-top: 191px;
  }
  .about-us-text03 {
    font-size: 32px;
    line-height: 45px;
  }
  .about-us-text04 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .about-us-text05 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .about-us-text06 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .about-us-text07 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .about-us-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .about-us-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .about-us-get-leads {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-us-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .about-us-container2 {
    width: auto;
    flex-direction: column;
  }
}
</style>
