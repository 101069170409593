<template>
  <div class="malware-cleanup-container">
    <section class="malware-cleanup-hero">
      <header-menu rootClassName="header-menu-root-class-name2"></header-menu>
      <div class="malware-cleanup-header">
        <h1 class="malware-cleanup-heading">
          Eliminate Malware and Secure your Website with our Cleanup Services
        </h1>
        <a href="#pricing" class="malware-cleanup-link button">
          <span class="malware-cleanup-text">
            <span>Clean it now!</span>
            <br />
          </span>
        </a>
      </div>
      <dashboard-preview
        rootClassName="dashboard-preview-root-class-name"
      ></dashboard-preview>
    </section>
    <section class="malware-cleanup-features">
      <div class="malware-cleanup-title">
        <span id="features" class="malware-cleanup-text03">
          Malware Removal Experts to the Rescue!
        </span>
        <span class="malware-cleanup-text04">
          Malware can be detrimental to your website&apos;s performance,
          reputation, and search engine ranking. But don&apos;t worry, we&apos;re
          here to help! With years of experience in malware detection and removal,
          we know exactly what to look for and how to fix it.
        </span>
      </div>
      <span class="malware-cleanup-text05 mobileHeading">
        Our Malware Cleanup Service includes
      </span>
      <div class="malware-cleanup-cards">
        <div class="malware-cleanup-container1">
          <div class="malware-cleanup-schedule card">
            <img
              alt="pastedImage"
              src="/malware%20scan-200h.png"
              class="malware-cleanup-icon"
            />
            <span class="malware-cleanup-text06">Malware Scan and Detection</span>
            <span class="malware-cleanup-text07">
              Our Malware Scan and Detection tool uses latest technologies to
              thoroughly scan every file and folder on your website for malware,
              spyware, and adware, ensuring no threat goes undetected.
              <span v-html="rawruem"></span>
            </span>
          </div>
          <div class="malware-cleanup-schedule1 card">
            <img
              alt="pastedImage"
              src="/dashboard-200h.png"
              class="malware-cleanup-icon01"
            />
            <span class="malware-cleanup-text08">
              Auto + Manual Cleanup by Expert
            </span>
            <span class="malware-cleanup-text09">
              <span>
                Our Malware Removal service manually checks and cleans every
                infected file and folder with original codes, ensuring your
                website functions as before without any malware issues.
              </span>
              <br />
            </span>
          </div>
        </div>
        <div class="malware-cleanup-container2">
          <div class="malware-cleanup-analyze card">
            <img
              alt="pastedImage"
              src="/files%20integrity-200h.png"
              class="malware-cleanup-icon02"
            />
            <span class="malware-cleanup-text12">
              Vulnerability and Backdoor Scan
            </span>
            <span class="malware-cleanup-text13">
              <span>
                Protect your system from cyber threats with our Vulnerability and
                Backdoor Scan service, which identifies and helps fix security
                vulnerabilities like hidden backdoors.
              </span>
              <br />
            </span>
          </div>
          <div class="malware-cleanup-get-leads card">
            <img
              alt="pastedImage"
              src="/firewall-200h.png"
              class="malware-cleanup-icon03"
            />
            <span class="malware-cleanup-text16">
              Security Patches and Updates
            </span>
            <span class="malware-cleanup-text17">
              <span>
                Security patch and update services protect software systems by
                fixing security issues, improving performance and ensuring the
                system is up-to-date.
              </span>
              <br />
            </span>
          </div>
        </div>
        <div class="malware-cleanup-container3">
          <div class="malware-cleanup-analyze1 card">
            <img
              alt="pastedImage"
              src="/backup-200h.png"
              class="malware-cleanup-icon04"
            />
            <span class="malware-cleanup-text20">
              <span>Website Backup and Recovery</span>
              <br />
            </span>
            <span class="malware-cleanup-text23">
              Website backup and recovery services provide regular backups of a
              website&apos;s data, allowing for easy restoration in the event of
              data loss or site issues.
            </span>
          </div>
          <div class="malware-cleanup-get-leads1 card">
            <img
              alt="pastedImage"
              src="/delete-200h.png"
              class="malware-cleanup-icon05"
            />
            <span class="malware-cleanup-text24">Website Blacklist Removal</span>
            <span class="malware-cleanup-text25">
              Website blacklist removal services identify and remove malicious
              content from a website, getting it removed from search engine
              blacklists and restoring its reputation.
              <span v-html="rawx70z"></span>
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="malware-cleanup-how-it-works">
      <div class="malware-cleanup-centered-container">
        <div class="malware-cleanup-heading1">
          <span class="malware-cleanup-text26">How it works</span>
          <span class="malware-cleanup-text27">
            Fast and Effective Malware Cleanup Process
          </span>
        </div>
        <div class="malware-cleanup-category">
          <div class="malware-cleanup-headng">
            <span class="malware-cleanup-text28">
              1 — Place your order and provide us with your website details.
            </span>
            <span class="malware-cleanup-text29">
              First, select a malware cleanup package from the options below and
              place your order. After the order provide us with your website
              details, including login information for WP-Admin and your hosting
              account. We will need this access to scan your website&apos;s files
              and folders to locate any malware present. Please be assured that
              all credentials provided are secured with us.
            </span>
            <a href="#pricing" class="malware-cleanup-link1">
              <div class="malware-cleanup-get-started template-button">
                <span class="malware-cleanup-text30">Get started</span>
              </div>
            </a>
          </div>
          <div class="malware-cleanup-container4">
            <img
              alt="Malware cleanup price"
              src="/malware%20cleanup%20price-1200w.png"
              loading="eager"
              class="malware-cleanup-pasted-image"
            />
          </div>
        </div>
        <div class="malware-cleanup-row">
          <div class="malware-cleanup-category1">
            <div class="malware-cleanup-headng1">
              <span class="malware-cleanup-text31">
                2 — Our team of security experts will scan for malware and
                vulnerabilities.
              </span>
              <span class="malware-cleanup-text32">
                Once you share the website details, one of our experts will begin
                working on your website. The expert will scan and clean all
                malware and keep you updated throughout the task. Once the website
                is cleaned, we will rescan it to ensure no malware is left and
                then send it to you for review.
              </span>
            </div>
            <img
              alt="CleanWP Pro Dashboard page"
              src="/website%20security%20experts-1200w.png"
              loading="lazy"
              class="malware-cleanup-pasted-image1"
            />
          </div>
          <div class="malware-cleanup-category2">
            <div class="malware-cleanup-headng2">
              <span class="malware-cleanup-text33">
                3 — Once the website cleaned, we will send you for your review.
              </span>
              <span class="malware-cleanup-text34">
                <span>
                  To ensure that everything is functioning properly, please
                  perform a thorough check of your website. Once you have
                  confirmed that all necessary tasks have been completed
                  successfully, you may mark the task as &quot;done.&quot;
                  <span v-html="rawuj7u"></span>
                </span>
                <br />
                <span>
                  However, in the event that any issues or incomplete tasks are
                  identified during the website check, please report them back to
                  us promptly so that we may address them. We will work with you
                  to resolve any outstanding issues and restart the process from
                  step 2 if necessary.
                </span>
              </span>
            </div>
            <img
              alt="Easy WordPress website cleanup with CleanWP Pro"
              src="/review%20and%20done-1200w.png"
              class="malware-cleanup-pasted-image2"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="malware-cleanup-statistics">
      <div class="malware-cleanup-container5">
        <active-users
          Caption="Website Cleaned"
          Statistic="1500+"
          rootClassName="active-users-root-class-name9"
        ></active-users>
        <active-users
          Caption="— Malware free"
          Statistic="100%"
          rootClassName="active-users-root-class-name10"
        ></active-users>
        <active-users
          Caption="— days guarantee "
          Statistic="30"
          rootClassName="active-users-root-class-name11"
        ></active-users>
        <active-users
          Caption="— Turnaround time"
          Statistic="48h"
          rootClassName="active-users-root-class-name12"
        ></active-users>
      </div>
    </section>
    <section class="malware-cleanup-banners">
      <div class="malware-cleanup-banner-manage">
        <div class="malware-cleanup-container6">
          <div class="malware-cleanup-left">
            <span class="malware-cleanup-text38 sub-title">
              The Threat of Website Malware
            </span>
            <span class="malware-cleanup-text39 title">
              Protect Your Online Presence
            </span>
            <span class="malware-cleanup-text40">
              In today&apos;s digital age, websites are a crucial component of any
              organization&apos;s online presence. However, they also pose a
              significant security risk due to the prevalence of malware. Website
              malware can take many forms, from viruses and worms to trojans and
              spyware, and can lead to data breaches, financial loss, and
              reputational damage.
            </span>
            <a href="#pricing" class="malware-cleanup-link2">
              <div class="malware-cleanup-get-started1 template-button">
                <span class="malware-cleanup-text41">Get started</span>
              </div>
            </a>
          </div>
          <div class="malware-cleanup-image-container">
            <img
              alt="Save times on maintaining your WordPress website"
              src="/malware%20warning-1200w.jpg"
              class="malware-cleanup-cards-image"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" class="malware-cleanup-pricing">
      <div class="malware-cleanup-centered-container1">
        <div class="malware-cleanup-heading2">
          <span class="malware-cleanup-text42 mobileHeading title">
            Pricing for all kind of businesses
          </span>
          <span class="malware-cleanup-text43">
            We remove malware from all type of WordPress websites. Choose the best
            plan that fits your business.
          </span>
        </div>
        <div class="malware-cleanup-cards1">
          <div class="malware-cleanup-card">
            <span class="malware-cleanup-text44">Malware Cleanup</span>
            <span class="malware-cleanup-text45">
              If your website is hacked or receive malware warning from hosting
            </span>
            <span class="malware-cleanup-text46">$99.00</span>
            <span class="malware-cleanup-text47">What&apos;s included</span>
            <div class="malware-cleanup-bullet-points">
              <div class="malware-cleanup-point">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon06"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text48">Malware Cleanup</span>
              </div>
              <div class="malware-cleanup-point1">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon08"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text49 Content">
                  Website restore
                </span>
              </div>
              <div class="malware-cleanup-point2">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon10"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text50">Blacklist Removal</span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/36/checkout"
              class="malware-cleanup-link3"
            >
              <div class="malware-cleanup-get-started2 template-button">
                <span class="malware-cleanup-text51">Order now</span>
              </div>
            </a>
          </div>
          <div class="malware-cleanup-card1">
            <span class="malware-cleanup-text52">Clean and Secure</span>
            <span class="malware-cleanup-text53">
              Malware cleanup and security setup for your website.
            </span>
            <span class="malware-cleanup-text54">$159.00</span>
            <span class="malware-cleanup-text55">What&apos;s included</span>
            <div class="malware-cleanup-bullet-points1">
              <div class="malware-cleanup-point3">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon12"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text56">Malware Cleanup</span>
              </div>
              <div class="malware-cleanup-point4">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon14"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text57">Security Setup</span>
              </div>
              <div class="malware-cleanup-point5">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon16"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text58 Content">Backup Setup</span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/37/checkout"
              class="malware-cleanup-link4"
            >
              <div class="malware-cleanup-get-started3 template-button">
                <span class="malware-cleanup-text59">
                  <span>Order now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
          <div class="malware-cleanup-card2">
            <span class="malware-cleanup-text62">
              Clean, Secure and optimize.
            </span>
            <span class="malware-cleanup-text63">
              <span>
                We will clean, secure and optimize the website for fast loading.
              </span>
              <br />
            </span>
            <span class="malware-cleanup-text66">$199.00</span>
            <span class="malware-cleanup-text67">What&apos;s included</span>
            <div class="malware-cleanup-bullet-points2">
              <div class="malware-cleanup-point6">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon18"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text68 Content">
                  Malware Cleanup
                </span>
              </div>
              <div class="malware-cleanup-point7">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon20"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text69">Security Setup</span>
              </div>
              <div class="malware-cleanup-point8">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="malware-cleanup-icon22"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="malware-cleanup-text70 Content">
                  Speedup the website
                </span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/38/checkout"
              class="malware-cleanup-link5"
            >
              <div class="malware-cleanup-get-started4 template-button">
                <span class="malware-cleanup-text71">
                  <span>Order now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import DashboardPreview from '../components/dashboard-preview'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'MalwareCleanup',
  components: {
    HeaderMenu,
    DashboardPreview,
    ActiveUsers,
    AppFooter,
  },
  data() {
    return {
      rawruem: ' ',
      rawx70z: ' ',
      rawuj7u: ' ',
    }
  },
  metaInfo: {
    title: 'Malware Clean up services, malware removal services - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'Worried about malware on your website? Let us help.  Keep your website safe with top-notch malware clean-up and removal services.',
      },
      {
        property: 'og:title',
        content:
          'Malware Clean up services, malware removal services - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/9d8a8f83-f5a9-45b9-9a84-466c9522af57?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.malware-cleanup-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.malware-cleanup-hero {
  width: 100%;
  height: 880px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.malware-cleanup-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.malware-cleanup-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  padding-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.malware-cleanup-link {
  cursor: pointer;
  font-size: 45px;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
}
.malware-cleanup-text {
  font-style: normal;
  font-weight: 600;
}
.malware-cleanup-features {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 420px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #efefef;
}
.malware-cleanup-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.malware-cleanup-text03 {
  font-size: 50px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 65px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.malware-cleanup-text04 {
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.malware-cleanup-text05 {
  margin-top: var(--dl-space-space-fiveunits);
}
.malware-cleanup-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.malware-cleanup-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.malware-cleanup-schedule {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text06 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.malware-cleanup-text07 {
  line-height: 24px;
}
.malware-cleanup-schedule1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-icon01 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text08 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.malware-cleanup-text09 {
  line-height: 24px;
}
.malware-cleanup-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.malware-cleanup-analyze {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-icon02 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text12 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.malware-cleanup-text13 {
  line-height: 24px;
}
.malware-cleanup-get-leads {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #ffffff;
}
.malware-cleanup-icon03 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text16 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.malware-cleanup-text17 {
  line-height: 24px;
}
.malware-cleanup-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.malware-cleanup-analyze1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-icon04 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text20 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.malware-cleanup-text23 {
  line-height: 24px;
}
.malware-cleanup-get-leads1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-icon05 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text24 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.malware-cleanup-text25 {
  line-height: 24px;
}
.malware-cleanup-how-it-works {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #211062;
}
.malware-cleanup-centered-container {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.malware-cleanup-heading1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.malware-cleanup-text26 {
  color: rgb(220, 212, 253);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.malware-cleanup-text27 {
  color: var(--dl-color-gray-white);
  font-size: 50px;
  font-family: Inter;
  font-weight: 600;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.malware-cleanup-category {
  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #0064b7;
}
.malware-cleanup-headng {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.malware-cleanup-text28 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.malware-cleanup-text29 {
  color: rgb(238, 233, 254);
  line-height: 24px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 40px;
}
.malware-cleanup-link1 {
  display: contents;
}
.malware-cleanup-get-started {
  margin-bottom: 0px;
  text-decoration: none;
}
.malware-cleanup-get-started:hover {
  color: black;
  background-color: #ffffff;
}
.malware-cleanup-text30 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.malware-cleanup-container4 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}
.malware-cleanup-pasted-image {
  flex: 0 0 auto;
  width: 100%;
  height: 342px;
  align-self: center;
  object-fit: contain;
  border-radius: 0px;
  padding-right: var(--dl-space-space-twounits);
  border-top-left-radius: 0;
  border-top-right-radius: 130px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 130px;
}
.malware-cleanup-row {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.malware-cleanup-category1 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.malware-cleanup-headng1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.malware-cleanup-text31 {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.malware-cleanup-text32 {
  color: rgb(30, 30, 30);
  line-height: 24px;
}
.malware-cleanup-pasted-image1 {
  width: 502px;
  height: 215px;
  align-self: center;
}
.malware-cleanup-category2 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}
.malware-cleanup-headng2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.malware-cleanup-text33 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.malware-cleanup-text34 {
  color: rgb(238, 233, 254);
  line-height: 24px;
}
.malware-cleanup-pasted-image2 {
  width: 480px;
  height: 50%;
  align-self: center;
  object-fit: contain;
  padding-top: var(--dl-space-space-twounits);
}
.malware-cleanup-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.malware-cleanup-container5 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.malware-cleanup-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.malware-cleanup-banner-manage {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.malware-cleanup-container6 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.malware-cleanup-left {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.malware-cleanup-text38 {
  color: rgb(0, 100, 183);
}
.malware-cleanup-text39 {
  text-align: left;
}
.malware-cleanup-text40 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.malware-cleanup-link2 {
  display: contents;
}
.malware-cleanup-get-started1 {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.malware-cleanup-get-started1:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.malware-cleanup-text41 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.malware-cleanup-image-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.malware-cleanup-cards-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}
.malware-cleanup-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #0064b7;
}
.malware-cleanup-centered-container1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.malware-cleanup-heading2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.malware-cleanup-text42 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.malware-cleanup-text43 {
  color: rgb(238, 233, 254);
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-cards1 {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.malware-cleanup-card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.malware-cleanup-text44 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text45 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-text46 {
  font-size: 40px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text47 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-bullet-points {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.malware-cleanup-point {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon06 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text48 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-point1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon08 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text49 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-point2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon10 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text50 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-link3 {
  display: contents;
}
.malware-cleanup-get-started2 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.malware-cleanup-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.malware-cleanup-text51 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.malware-cleanup-card1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.malware-cleanup-text52 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text53 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-text54 {
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text55 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-bullet-points1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.malware-cleanup-point3 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon12 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text56 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-point4 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon14 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text57 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-point5 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon16 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text58 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-link4 {
  display: contents;
}
.malware-cleanup-get-started3 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.malware-cleanup-get-started3:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.malware-cleanup-text59 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.malware-cleanup-card2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #000000;
}
.malware-cleanup-text62 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text63 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.malware-cleanup-text66 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-text67 {
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.malware-cleanup-bullet-points2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.malware-cleanup-point6 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon18 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text68 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-point7 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon20 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text69 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-point8 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.malware-cleanup-icon22 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.malware-cleanup-text70 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.malware-cleanup-link5 {
  display: contents;
}
.malware-cleanup-get-started4 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.malware-cleanup-get-started4:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.malware-cleanup-text71 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}
.malware-cleanup-text71:hover {
  color: var(--dl-color-gray-900);
}
@media(max-width: 1200px) {
  .malware-cleanup-hero {
    width: 100%;
    height: 931px;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .malware-cleanup-header {
    margin-top: var(--dl-space-space-fourunits);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .malware-cleanup-heading {
    color: rgb(255, 255, 255);
    width: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
    text-transform: none;
    text-decoration: none;
  }
  .malware-cleanup-features {
    padding-top: 400px;
  }
  .malware-cleanup-text04 {
    font-size: 20px;
    line-height: 30px;
  }
  .malware-cleanup-text20 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .malware-cleanup-text23 {
    line-height: 24px;
  }
  .malware-cleanup-text24 {
    line-height: 33px;
  }
  .malware-cleanup-text25 {
    line-height: 24px;
  }
  .malware-cleanup-how-it-works {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-category {
    padding-left: var(--dl-space-space-twounits);
  }
  .malware-cleanup-pasted-image {
    padding-right: var(--dl-space-space-twounits);
  }
  .malware-cleanup-row {
    width: 100%;
  }
  .malware-cleanup-headng1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .malware-cleanup-pasted-image1 {
    width: 100%;
    height: auto;
  }
  .malware-cleanup-headng2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .malware-cleanup-pasted-image2 {
    width: 100%;
    height: auto;
    padding-top: 0px;
  }
  .malware-cleanup-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-container5 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
  .malware-cleanup-banner-manage {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-container6 {
    max-width: auto;
  }
  .malware-cleanup-text38 {
    color: rgb(0, 100, 183);
  }
  .malware-cleanup-pricing {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-centered-container1 {
    flex-direction: column;
  }
}
@media(max-width: 991px) {
  .malware-cleanup-hero {
    height: 809px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .malware-cleanup-header {
    width: 100%;
    max-width: 1200px;
    margin-top: var(--dl-space-space-fourunits);
  }
  .malware-cleanup-heading {
    font-size: 50px;
  }
  .malware-cleanup-features {
    height: auto;
    padding-top: 350px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .malware-cleanup-title {
    width: 100%;
    max-width: auto;
  }
  .malware-cleanup-text04 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .malware-cleanup-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .malware-cleanup-text07 {
    line-height: 21px;
  }
  .malware-cleanup-text08 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .malware-cleanup-text09 {
    line-height: 21px;
  }
  .malware-cleanup-text13 {
    line-height: 21px;
  }
  .malware-cleanup-text16 {
    font-size: 24px;
  }
  .malware-cleanup-text17 {
    line-height: 21px;
  }
  .malware-cleanup-text23 {
    line-height: 21px;
  }
  .malware-cleanup-text24 {
    font-size: 24px;
  }
  .malware-cleanup-text25 {
    line-height: 21px;
  }
  .malware-cleanup-how-it-works {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .malware-cleanup-heading1 {
    width: 100%;
  }
  .malware-cleanup-category {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .malware-cleanup-headng {
    margin-left: var(--dl-space-space-fourunits);
  }
  .malware-cleanup-container4 {
    width: 100%;
  }
  .malware-cleanup-pasted-image {
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: var(--dl-space-space-halfunit);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .malware-cleanup-row {
    width: 100%;
  }
  .malware-cleanup-headng1 {
    width: auto;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .malware-cleanup-pasted-image1 {
    width: 100%;
    height: auto;
  }
  .malware-cleanup-headng2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .malware-cleanup-pasted-image2 {
    width: 100%;
    height: auto;
  }
  .malware-cleanup-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .malware-cleanup-container5 {
    height: 100%;
    flex-direction: row;
  }
  .malware-cleanup-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-left {
    margin-right: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-text38 {
    color: #0064b7;
  }
  .malware-cleanup-text39 {
    font-size: 40px;
    line-height: 45px;
  }
  .malware-cleanup-cards-image {
    width: 360px;
  }
  .malware-cleanup-pricing {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .malware-cleanup-centered-container1 {
    width: 100%;
  }
  .malware-cleanup-text42 {
    font-size: 45px;
    line-height: 50px;
  }
  .malware-cleanup-text43 {
    width: 100%;
    max-width: 600px;
  }
  .malware-cleanup-cards1 {
    display: flex;
    flex-direction: row;
  }
  .malware-cleanup-card {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-text45 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .malware-cleanup-text47 {
    font-size: 16px;
  }
  .malware-cleanup-card1 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .malware-cleanup-text53 {
    color: rgb(0, 0, 0);
    line-height: 24px;
  }
  .malware-cleanup-text55 {
    font-size: 16px;
  }
  .malware-cleanup-card2 {
    width: 100%;
    align-items: flex-start;
    padding-right: var(--dl-space-space-twounits);
  }
  .malware-cleanup-text63 {
    color: rgb(255, 255, 255);
    line-height: 24px;
  }
  .malware-cleanup-text66 {
    color: var(--dl-color-gray-white);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
  }
}
@media(max-width: 767px) {
  .malware-cleanup-hero {
    height: 652px;
    position: relative;
    padding-bottom: 180px;
    background-color: #0064b7;
  }
  .malware-cleanup-header {
    height: 372px;
    margin-top: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .malware-cleanup-heading {
    font-size: 40px;
  }
  .malware-cleanup-features {
    height: auto;
    padding-top: 320px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .malware-cleanup-text03 {
    font-size: 40px;
  }
  .malware-cleanup-text05 {
    text-align: center;
  }
  .malware-cleanup-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .malware-cleanup-text06 {
    font-size: 24px;
  }
  .malware-cleanup-text07 {
    font-size: 14px;
  }
  .malware-cleanup-text08 {
    font-size: 24px;
  }
  .malware-cleanup-text09 {
    font-size: 14px;
  }
  .malware-cleanup-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .malware-cleanup-text12 {
    font-size: 24px;
  }
  .malware-cleanup-text13 {
    font-size: 14px;
  }
  .malware-cleanup-text17 {
    font-size: 14px;
  }
  .malware-cleanup-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .malware-cleanup-text20 {
    font-size: 24px;
  }
  .malware-cleanup-text23 {
    font-size: 14px;
  }
  .malware-cleanup-text25 {
    font-size: 14px;
  }
  .malware-cleanup-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .malware-cleanup-heading1 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-text27 {
    text-align: center;
  }
  .malware-cleanup-headng {
    padding-bottom: 0px;
  }
  .malware-cleanup-get-started {
    display: none;
  }
  .malware-cleanup-container4 {
    width: 100%;
  }
  .malware-cleanup-row {
    height: auto;
    flex-direction: column;
  }
  .malware-cleanup-pasted-image1 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .malware-cleanup-pasted-image2 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .malware-cleanup-statistics {
    width: auto;
    height: auto;
  }
  .malware-cleanup-container5 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
  .malware-cleanup-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .malware-cleanup-container6 {
    flex-direction: column;
  }
  .malware-cleanup-left {
    margin-right: 0px;
  }
  .malware-cleanup-text39 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .malware-cleanup-text40 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .malware-cleanup-text41 {
    font-size: 16px;
  }
  .malware-cleanup-image-container {
    justify-content: center;
  }
  .malware-cleanup-cards-image {
    width: auto;
    margin-top: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .malware-cleanup-centered-container1 {
    width: auto;
    flex-direction: column;
  }
  .malware-cleanup-cards1 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .malware-cleanup-card {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .malware-cleanup-text44 {
    font-size: 28px;
  }
  .malware-cleanup-text45 {
    width: auto;
  }
  .malware-cleanup-text46 {
    font-size: 28px;
  }
  .malware-cleanup-bullet-points {
    width: 100%;
  }
  .malware-cleanup-card1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-text52 {
    font-size: 28px;
  }
  .malware-cleanup-text53 {
    width: auto;
  }
  .malware-cleanup-text54 {
    font-size: 28px;
  }
  .malware-cleanup-card2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-text62 {
    font-size: 28px;
  }
  .malware-cleanup-text63 {
    width: auto;
  }
  .malware-cleanup-text66 {
    font-size: 28px;
  }
}
@media(max-width: 479px) {
  .malware-cleanup-hero {
    height: 667px;
    padding-bottom: 51px;
  }
  .malware-cleanup-header {
    height: auto;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  .malware-cleanup-heading {
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-link {
    height: auto;
    font-size: 25px;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .malware-cleanup-text {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
  }
  .malware-cleanup-features {
    height: auto;
    padding-top: 135px;
  }
  .malware-cleanup-text03 {
    font-size: 32px;
    line-height: 45px;
  }
  .malware-cleanup-text04 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .malware-cleanup-text05 {
    font-size: 32;
    line-height: 38px;
  }
  .malware-cleanup-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .malware-cleanup-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .malware-cleanup-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .malware-cleanup-get-leads {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .malware-cleanup-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .malware-cleanup-get-leads1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .malware-cleanup-text27 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .malware-cleanup-category {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .malware-cleanup-headng {
    margin-left: 0px;
    margin-right: 0px;
  }
  .malware-cleanup-text28 {
    font-size: 30px;
  }
  .malware-cleanup-pasted-image {
    width: 100%;
    height: auto;
    align-self: center;
    padding-left: 0px;
    padding-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .malware-cleanup-row {
    width: 100%;
  }
  .malware-cleanup-headng1 {
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .malware-cleanup-text31 {
    font-size: 30px;
  }
  .malware-cleanup-pasted-image1 {
    width: 100%;
    height: auto;
  }
  .malware-cleanup-category2 {
    width: 100%;
  }
  .malware-cleanup-headng2 {
    width: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .malware-cleanup-text33 {
    font-size: 30px;
  }
  .malware-cleanup-pasted-image2 {
    width: 100%;
    height: auto;
  }
  .malware-cleanup-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .malware-cleanup-container5 {
    width: auto;
    flex-direction: column;
  }
  .malware-cleanup-banner-manage {
    width: 100%;
  }
  .malware-cleanup-left {
    width: auto;
  }
  .malware-cleanup-text39 {
    font-size: 30px;
    line-height: 38px;
  }
  .malware-cleanup-text40 {
    line-height: 24px;
  }
  .malware-cleanup-cards-image {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .malware-cleanup-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .malware-cleanup-centered-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .malware-cleanup-text42 {
    font-size: 32px;
    line-height: 38px;
  }
  .malware-cleanup-cards1 {
    flex-direction: column;
  }
}
</style>
