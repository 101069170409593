<template>
  <div class="terms-conditions-container">
    <section class="terms-conditions-hero">
      <header-menu></header-menu>
      <div class="terms-conditions-header">
        <h1 class="terms-conditions-heading">
          Terms &amp; Conditions
          <span v-html="raw77qq"></span>
        </h1>
      </div>
    </section>
    <div class="terms-conditions-banner">
      <span>
        <span>
          These terms and conditions outline the rules and regulations for the use
          of CleanWP Pro&apos;s Website and services, located at
          https://cleanwp.pro.
        </span>
        <br />
        <br />
        <span>
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use CleanWP.Pro if you do not agree to
          take all of the terms and conditions stated on this page.
        </span>
        <br />
        <br />
        <span>
          The following terminology applies to these Terms and Conditions, Privacy
          Statement and Disclaimer Notice and all Agreements: &quot;Client&quot;,
          &quot;You&quot; and &quot;Your&quot; refers to you, the person log on
          this website and compliant to the Company&apos;s terms and conditions.
          &quot;The Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;,
          &quot;Our&quot; and &quot;Us&quot;, refers to our Company.
          &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;, refers to
          both the Client and ourselves. All terms refer to the offer, acceptance
          and consideration of payment necessary to undertake the process of our
          assistance to the Client in the most appropriate manner for the express
          purpose of meeting the Client&apos;s needs in respect of provision of
          the Company&apos;s stated services, in accordance with and subject to,
          prevailing law of in. Any use of the above terminology or other words in
          the singular, plural, capitalization and/or he/she or they, are taken as
          interchangeable and therefore as referring to same.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text010">License</span>
        <br />
        <span>
          Unless otherwise stated, CleanWP Pro and/or its licensors own the
          intellectual property rights for all material on CleanWP.Pro. All
          intellectual property rights are reserved. You may access this from
          CleanWP.Pro for your own personal use subjected to restrictions set in
          these terms and conditions.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text015">You must not:</span>
        <br class="terms-conditions-text016" />
        <br />
        <span>1. Republish material from CleanWP.Pro</span>
        <br />
        <span>2. Sell, rent or sub-license material from CleanWP.Pro</span>
        <br />
        <span>3. Reproduce, duplicate or copy material from CleanWP.Pro</span>
        <br />
        <span>4. Redistribute content from CleanWP.Pro</span>
        <br />
        <br />
        <span>
          This Agreement shall begin on the date hereof.
          <span v-html="raw8v4n"></span>
        </span>
        <br />
        <br />
        <span>
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          CleanWP Pro does not filter, edit, publish or review Comments prior to
          their presence on the website. Comments do not reflect the views and
          opinions of CleanWP Pro,its agents and/or affiliates. Comments reflect
          the views and opinions of the person who post their views and opinions.
          To the extent permitted by applicable laws, CleanWP Pro shall not be
          liable for the Comments or for any liability, damages or expenses caused
          and/or suffered as a result of any use of and/or posting of and/or
          appearance of the Comments on this website.
        </span>
        <br />
        <br />
        <span>
          CleanWP Pro reserves the right to monitor all Comments and to remove any
          Comments which can be considered inappropriate, offensive or causes
          breach of these Terms and Conditions.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text036">
          You warrant and represent that:
        </span>
        <br class="terms-conditions-text037" />
        <br />
        <span>
          1. You are entitled to post the Comments on our website and have all
          necessary licenses and consents to do so;
        </span>
        <br />
        <span>
          2. The Comments do not invade any intellectual property right, including
          without limitation copyright, patent or trademark of any third party;
        </span>
        <br />
        <span>
          3. The Comments do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful material which is an invasion of privacy
        </span>
        <br />
        <span>
          4. The Comments will not be used to solicit or promote business or
          custom or present commercial activities or unlawful activity.
        </span>
        <br />
        <span>
          5. You hereby grant CleanWP Pro a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your Comments in any and all forms, formats or media.
        </span>
        <br />
        <br />
        <br />
        <span class="terms-conditions-text051">Hyperlinking to our Content</span>
        <br class="terms-conditions-text052" />
        <span>
          The following organizations may link to our Website without prior
          written approval:
        </span>
        <br />
        <br />
        <span>Government agencies;</span>
        <br />
        <span>Search engines;</span>
        <br />
        <span>News organizations;</span>
        <br />
        <span>
          Online directory distributors may link to our Website in the same manner
          as they hyperlink to the Websites of other listed businesses; and
        </span>
        <br />
        <span>
          System wide Accredited Businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups
          which may not hyperlink to our Web site.
        </span>
        <br />
        <span>
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and (c)
          fits within the context of the linking party&apos;s site.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text069">
          We may consider and approve other link requests from the following types
          of organizations:
        </span>
        <br class="terms-conditions-text070" />
        <br />
        <span>commonly-known consumer and/or business information sources;</span>
        <br />
        <span>dot.com community sites;</span>
        <br />
        <span>associations or other groups representing charities;</span>
        <br />
        <span>online directory distributors;</span>
        <br />
        <span>internet portals;</span>
        <br />
        <span>accounting, law and consulting firms; and</span>
        <br />
        <span>educational institutions and trade associations.</span>
        <br />
        <span>
          We will approve link requests from these organizations if we decide
          that: (a) the link would not make us look unfavorably to ourselves or to
          our accredited businesses; (b) the organization does not have any
          negative records with us; (c) the benefit to us from the visibility of
          the hyperlink compensates the absence of CleanWP Pro; and (d) the link
          is in the context of general resource information.
        </span>
        <br />
        <br />
        <span>
          These organizations may link to our home page so long as the link: (a)
          is not in any way deceptive; (b) does not falsely imply sponsorship,
          endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party&apos;s
          site.
        </span>
        <br />
        <br />
        <span>
          If you are one of the organizations listed in paragraph 2 above and are
          interested in linking to our website, you must inform us by sending an
          e-mail to CleanWP Pro. Please include your name, your organization name,
          contact information as well as the URL of your site, a list of any URLs
          from which you intend to link to our Website, and a list of the URLs on
          our site to which you would like to link. Wait 2-3 weeks for a response.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text095">
          Approved organizations may hyperlink to our Website as follows:
        </span>
        <br class="terms-conditions-text096" />
        <br />
        <span>By use of our corporate name; or</span>
        <br />
        <span>By use of the uniform resource locator being linked to; or</span>
        <br />
        <span>
          By use of any other description of our Website being linked to that
          makes sense within the context and format of content on the linking
          party&apos;s site.
        </span>
        <br />
        <span>
          No use of CleanWP Pro&apos;s logo or other artwork will be allowed for
          linking absent a trademark license agreement.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text107">iFrames</span>
        <br />
        <span>
          Without prior approval and written permission, you may not create frames
          around our Webpages that alter in any way the visual presentation or
          appearance of our Website.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text112">Content Liability</span>
        <br class="terms-conditions-text113" />
        <span>
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that may
          be interpreted as libelous, obscene or criminal, or which infringes,
          otherwise violates, or advocates the infringement or other violation of,
          any third party rights.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text117">Reservation of Rights</span>
        <br class="terms-conditions-text118" />
        <span>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it&apos;s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and follow
          these linking terms and conditions.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text122">
          Removal of links from our website
        </span>
        <br class="terms-conditions-text123" />
        <span>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to respond
          to you directly.
        </span>
        <br />
        <br />
        <span>
          We do not ensure that the information on this website is correct, we do
          not warrant its completeness or accuracy; nor do we promise to ensure
          that the website remains available or that the material on the website
          is kept up to date.
        </span>
        <br />
        <br />
        <span class="terms-conditions-text130">Disclaimer</span>
        <br />
        <span>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </span>
        <br />
        <br />
        <span>
          limit or exclude our or your liability for death or personal injury;
        </span>
        <br />
        <span>
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </span>
        <br />
        <span>
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </span>
        <br />
        <span>
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </span>
        <br />
        <span>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the disclaimer,
          including liabilities arising in contract, in tort and for breach of
          statutory duty.
        </span>
        <br />
        <br />
        <span>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </span>
        <br />
      </span>
    </div>
    <section class="terms-conditions-statistics">
      <div class="terms-conditions-container1">
        <active-users
          Caption="— Products &amp; Services"
          Statistic="5"
          rootClassName="active-users-root-class-name85"
        ></active-users>
        <active-users
          Caption="— Years of experience"
          Statistic="10+"
          rootClassName="active-users-root-class-name86"
        ></active-users>
        <active-users
          Caption="— Customers"
          Statistic="1000+"
          rootClassName="active-users-root-class-name87"
        ></active-users>
        <active-users
          Caption="— In house Team"
          Statistic="10+"
          rootClassName="active-users-root-class-name88"
        ></active-users>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name15"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'TermsConditions',
  components: {
    HeaderMenu,
    ActiveUsers,
    AppFooter,
  },
  data() {
    return {
      raw77qq: ' ',
      raw8v4n: ' ',
    }
  },
  metaInfo: {
    title: 'Terms & Conditions - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          "Terms and conditions of our services. These terms and conditions outline the rules and regulations for the use of CleanWP Pro's Website and services.",
      },
      {
        property: 'og:title',
        content: 'Terms & Conditions - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/ae563464-3a6f-435c-8b0e-fbc134490f2e?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.terms-conditions-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.terms-conditions-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.terms-conditions-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.terms-conditions-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.terms-conditions-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.terms-conditions-text010 {
  font-weight: 700;
}
.terms-conditions-text015 {
  font-weight: 700;
}
.terms-conditions-text016 {
  font-weight: 700;
}
.terms-conditions-text036 {
  font-weight: 700;
}
.terms-conditions-text037 {
  font-weight: 700;
}
.terms-conditions-text051 {
  font-weight: 700;
}
.terms-conditions-text052 {
  font-weight: 700;
}
.terms-conditions-text069 {
  font-weight: 700;
}
.terms-conditions-text070 {
  font-weight: 700;
}
.terms-conditions-text095 {
  font-weight: 700;
}
.terms-conditions-text096 {
  font-weight: 700;
}
.terms-conditions-text107 {
  font-weight: 700;
}
.terms-conditions-text112 {
  font-weight: 700;
}
.terms-conditions-text113 {
  font-weight: 700;
}
.terms-conditions-text117 {
  font-weight: 700;
}
.terms-conditions-text118 {
  font-weight: 700;
}
.terms-conditions-text122 {
  font-weight: 700;
}
.terms-conditions-text123 {
  font-weight: 700;
}
.terms-conditions-text130 {
  font-weight: 700;
}
.terms-conditions-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.terms-conditions-container1 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 1600px) {
  .terms-conditions-hero {
    padding-bottom: var(--dl-space-space-unit);
  }
  .terms-conditions-heading {
    color: rgb(255, 255, 255);
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    text-transform: none;
    text-decoration: none;
  }
}
@media(max-width: 1200px) {
  .terms-conditions-hero {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .terms-conditions-header {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .terms-conditions-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .terms-conditions-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .terms-conditions-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .terms-conditions-container1 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .terms-conditions-hero {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .terms-conditions-header {
    width: 100%;
    max-width: 1200px;
  }
  .terms-conditions-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .terms-conditions-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .terms-conditions-container1 {
    height: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .terms-conditions-hero {
    height: auto;
    position: relative;
    padding-bottom: 0px;
    background-color: #0064b7;
  }
  .terms-conditions-header {
    height: auto;
    justify-content: center;
  }
  .terms-conditions-heading {
    font-size: 40px;
    padding-top: var(--dl-space-space-unit);
  }
  .terms-conditions-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .terms-conditions-statistics {
    width: auto;
    height: auto;
  }
  .terms-conditions-container1 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .terms-conditions-hero {
    height: auto;
    padding-top: 4px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .terms-conditions-header {
    height: auto;
    padding-top: var(--dl-space-space-threeunits);
  }
  .terms-conditions-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .terms-conditions-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .terms-conditions-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .terms-conditions-container1 {
    width: auto;
    flex-direction: column;
  }
}
</style>
