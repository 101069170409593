<template>
  <div class="header-menu-container" v-bind:class="rootClassName">
    <header data-thq="thq-navbar" class="header-menu-navbar-interactive">
      <router-link to="/" class="header-menu-navlink">
        <img :alt="Logo_alt" :src="Logo_src" class="header-menu-logo" />
      </router-link>
      <div
        data-thq="thq-navbar-nav"
        data-role="Nav"
        class="header-menu-desktop-menu"
      >
        <nav
          data-thq="thq-navbar-nav-links"
          data-role="Nav"
          class="header-menu-nav"
        >
          <router-link to="/" class="header-menu-text Link Menutext">
            {{ Text }}
          </router-link>
          <div data-thq="thq-dropdown" class="header-menu-thq-dropdown list-item">
            <div
              data-thq="thq-dropdown-toggle"
              class="header-menu-dropdown-toggle"
            >
              <span class="header-menu-text01 Link Menutext">{{ text7 }}</span>
              <div
                data-thq="thq-dropdown-arrow"
                class="header-menu-dropdown-arrow"
              >
                <svg viewBox="0 0 1024 1024" class="header-menu-icon">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul data-thq="thq-dropdown-list" class="header-menu-dropdown-list">
              <li data-thq="thq-dropdown" class="header-menu-dropdown list-item">
                <router-link to="/malware-cleanup">
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="header-menu-dropdown-toggle01"
                  >
                    <span class="header-menu-text02">{{ Text2 }}</span>
                  </div>
                </router-link>
              </li>
              <li
                data-thq="thq-dropdown"
                class="header-menu-dropdown01 list-item"
              >
                <router-link to="/website-security">
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="header-menu-dropdown-toggle02"
                  >
                    <span class="header-menu-text03">{{ text9 }}</span>
                  </div>
                </router-link>
              </li>
              <li
                data-thq="thq-dropdown"
                class="header-menu-dropdown02 list-item"
              >
                <router-link to="/website-maintenance">
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="header-menu-dropdown-toggle03"
                  >
                    <span class="header-menu-text04">{{ text10 }}</span>
                  </div>
                </router-link>
              </li>
              <li
                data-thq="thq-dropdown"
                class="header-menu-dropdown03 list-item"
              ></li>
              <li
                data-thq="thq-dropdown"
                class="header-menu-dropdown04 list-item"
              >
                <router-link to="/speed-up-website">
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="header-menu-dropdown-toggle04"
                  >
                    <span class="header-menu-text05">{{ text11 }}</span>
                  </div>
                </router-link>
              </li>
              <li
                data-thq="thq-dropdown"
                class="header-menu-dropdown05 list-item"
              ></li>
            </ul>
          </div>
          <a href="#features" class="header-menu-text06 Link Menutext">
            {{ Text1 }}
          </a>
          <a href="#pricing" class="header-menu-link Link Menutext">{{ text }}</a>
          <a href="#faq" class="header-menu-link1 Link Menutext">{{ text1 }}</a>
          <router-link to="/blog" class="header-menu-navlink05 Link Menutext">
            {{ text2 }}
          </router-link>
        </nav>
      </div>
      <div data-thq="thq-navbar-btn-group" class="header-menu-btn-group">
        <a
          href="https://app.cleanwp.pro/#/login/"
          class="header-menu-login Menutext button"
        >
          {{ Login }}
        </a>
        <a href="#pricing" class="header-menu-register">{{ Register }}</a>
      </div>
      <div data-thq="thq-burger-menu" class="header-menu-burger-menu">
        <svg viewBox="0 0 1024 1024" class="header-menu-icon2">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="header-menu-mobile-menu">
        <div
          data-thq="thq-mobile-menu-nav"
          data-role="Nav"
          class="header-menu-nav1"
        >
          <div class="header-menu-container1">
            <router-link to="/" class="header-menu-navlink06">
              <img :alt="image_alt" :src="image_src" class="header-menu-image" />
            </router-link>
            <div data-thq="thq-close-menu" class="header-menu-menu-close">
              <svg viewBox="0 0 1024 1024" class="header-menu-icon4">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <nav
            data-thq="thq-mobile-menu-nav-links"
            data-role="Nav"
            class="header-menu-nav2"
          >
            <div
              data-thq="thq-dropdown"
              class="header-menu-thq-dropdown1 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                class="header-menu-dropdown-toggle05"
              >
                <span class="header-menu-text07">{{ text8 }}</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  class="header-menu-dropdown-arrow1"
                >
                  <svg viewBox="0 0 1024 1024" class="header-menu-icon6">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul data-thq="thq-dropdown-list" class="header-menu-dropdown-list1">
                <li
                  data-thq="thq-dropdown"
                  class="header-menu-dropdown06 list-item"
                >
                  <router-link to="/malware-cleanup">
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="header-menu-dropdown-toggle06"
                    >
                      <span class="header-menu-text08">{{ text12 }}</span>
                    </div>
                  </router-link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  class="header-menu-dropdown07 list-item"
                >
                  <router-link to="/website-security">
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="header-menu-dropdown-toggle07"
                    >
                      <span class="header-menu-text09">{{ text13 }}</span>
                    </div>
                  </router-link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  class="header-menu-dropdown08 list-item"
                >
                  <router-link to="/website-maintenance">
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="header-menu-dropdown-toggle08"
                    >
                      <span class="header-menu-text10">{{ text14 }}</span>
                    </div>
                  </router-link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  class="header-menu-dropdown09 list-item"
                >
                  <router-link to="/speed-up-website">
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="header-menu-dropdown-toggle09"
                    >
                      <span class="header-menu-text11">{{ text15 }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <span class="header-menu-text12">{{ text3 }}</span>
            <span class="header-menu-text13">{{ text4 }}</span>
            <span class="header-menu-text14">{{ text5 }}</span>
            <span class="header-menu-text15">{{ text6 }}</span>
          </nav>
          <div class="header-menu-container2">
            <button class="header-menu-login1 button">{{ Login1 }}</button>
            <button class="button header-menu-register1">{{ Register1 }}</button>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu',
  props: {
    text4: {
      type: String,
      default: 'Pricing',
    },
    Register: {
      type: String,
      default: 'Download',
    },
    image_src: {
      type: String,
      default: '/logo1-1500h.png',
    },
    text15: {
      type: String,
      default: 'Speed up Website',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    text3: {
      type: String,
      default: 'Features',
    },
    text11: {
      type: String,
      default: 'Speed up Website',
    },
    Text1: {
      type: String,
      default: 'Features',
    },
    text8: {
      type: String,
      default: 'Services',
    },
    Login: {
      type: String,
      default: 'Login',
    },
    text12: {
      type: String,
      default: 'Malware Cleanup',
    },
    text6: {
      type: String,
      default: 'Blog',
    },
    text1: {
      type: String,
      default: 'Faq',
    },
    text: {
      type: String,
      default: 'Pricing',
    },
    text5: {
      type: String,
      default: 'FAQ',
    },
    rootClassName: String,
    text7: {
      type: String,
      default: 'Services',
    },
    Text: {
      type: String,
      default: 'Home',
    },
    text2: {
      type: String,
      default: 'Blog',
    },
    text9: {
      type: String,
      default: 'Website Security',
    },
    text13: {
      type: String,
      default: 'Website Security',
    },
    Text2: {
      type: String,
      default: 'Malware Clean up',
    },
    Login1: {
      type: String,
      default: 'Login',
    },
    Logo_src: {
      type: String,
      default: '/cleanwppro-200h.png',
    },
    Register1: {
      type: String,
      default: 'Register',
    },
    Logo_alt: {
      type: String,
      default: 'CleanWP Pro - The ultimate WordPress cleaner plugin',
    },
    text10: {
      type: String,
      default: 'Website Maintenance',
    },
    text14: {
      type: String,
      default: 'Website Maintenance',
    },
  },
}
</script>

<style scoped>
.header-menu-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.header-menu-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.header-menu-navlink {
  display: contents;
}
.header-menu-logo {
  width: 228px;
  height: auto;
  text-decoration: none;
}
.header-menu-desktop-menu {
  display: flex;
}
.header-menu-nav {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-menu-text {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-text:hover {
  color: #ffe95e;
}
.header-menu-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.header-menu-text01 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  margin-right: 0px;
}
.header-menu-text01:hover {
  color: #ffe95e;
}
.header-menu-dropdown-arrow {
  transition: 0.3s;
}
.header-menu-icon {
  fill: #D9D9D9;
  width: 18px;
  height: 18px;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-bottom: auto;
}
.header-menu-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.header-menu-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-dropdown-toggle01:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-gray-black);
}
.header-menu-text02 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-menu-dropdown01 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-dropdown-toggle02:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-gray-black);
}
.header-menu-text03 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-menu-dropdown02 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-dropdown-toggle03:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-gray-black);
}
.header-menu-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-menu-dropdown03 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown04 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-gray-black);
}
.header-menu-text05 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-menu-dropdown05 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-text06 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  text-decoration: none;
}
.header-menu-text06:hover {
  color: #ffe95e;
}
.header-menu-link {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  text-decoration: none;
}
.header-menu-link:hover {
  color: #ffe95e;
}
.header-menu-link1 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  text-decoration: none;
}
.header-menu-link1:hover {
  color: #ffe95e;
}
.header-menu-navlink05 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  text-decoration: none;
}
.header-menu-navlink05:hover {
  color: #ffe95e;
}
.header-menu-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-menu-login {
  transition: 0.3s;
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-menu-login:hover {
  color: #ffffff;
  background-color: #107bd2;
}
.header-menu-register {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  height: 39px;
  display: flex;
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-weight: 600;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.header-menu-register:hover {
  background-color: #107bd2;
}
.header-menu-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.header-menu-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-menu-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 401px;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.header-menu-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-menu-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-menu-navlink06 {
  display: contents;
}
.header-menu-image {
  height: 2rem;
  text-decoration: none;
}
.header-menu-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-menu-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-menu-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-menu-thq-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.header-menu-text07 {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}
.header-menu-dropdown-arrow1 {
  transition: 0.3s;
}
.header-menu-icon6 {
  width: 18px;
  height: 18px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.header-menu-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.header-menu-dropdown06 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.header-menu-text08 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-menu-dropdown07 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-dropdown-toggle07:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.header-menu-text09 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-menu-dropdown08 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.header-menu-text10 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-menu-dropdown09 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-menu-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-menu-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.header-menu-text11 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-menu-text12 {
  margin-bottom: var(--dl-space-space-unit);
}
.header-menu-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.header-menu-text14 {
  margin-bottom: var(--dl-space-space-unit);
}
.header-menu-text15 {
  margin-bottom: var(--dl-space-space-unit);
}
.header-menu-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.header-menu-login1 {
  margin-right: var(--dl-space-space-twounits);
}





@media(max-width: 1200px) {
  .header-menu-navbar-interactive {
    width: 120%;
    height: auto;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .header-menu-dropdown-toggle {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .header-menu-text01 {
    margin-right: 0px;
  }
  .header-menu-icon {
    fill: #D9D9D9;
    width: 18px;
  }
  .header-menu-text02 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .header-menu-text03 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .header-menu-text04 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .header-menu-text05 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .header-menu-register {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Inter;
    font-weight: 600;
    border-radius: 8px;
    text-transform: none;
    text-decoration: none;
    background-color: rgb(0, 0, 0);
  }
  .header-menu-mobile-menu {
    align-self: center;
  }
}
@media(max-width: 991px) {
  .header-menu-navbar-interactive {
    width: 100%;
  }
  .header-menu-desktop-menu {
    display: none;
  }
  .header-menu-btn-group {
    display: none;
  }
  .header-menu-register {
    display: none;
  }
  .header-menu-burger-menu {
    display: flex;
  }
  .header-menu-icon2 {
    fill: #D9D9D9;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .header-menu-mobile-menu {
    top: 3px;
    left: 0px;
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-left: 67%;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .header-menu-nav1 {
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
  }
  .header-menu-container1 {
    width: 100%;
    align-self: flex-start;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
    padding-right: 0px;
  }
  .header-menu-image {
    width: 120px;
    height: auto;
    align-self: center;
  }
  .header-menu-icon4 {
    width: auto;
    height: 30px;
  }
  .header-menu-nav2 {
    width: auto;
    align-self: flex-start;
    flex-direction: column;
  }
  .header-menu-dropdown-toggle05 {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .header-menu-text07 {
    font-size: 16px;
    font-style: inherit;
    font-weight: 700;
  }
  .header-menu-text08 {
    font-size: 14px;
  }
  .header-menu-text09 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .header-menu-text10 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .header-menu-text11 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .header-menu-text12 {
    font-style: normal;
    font-weight: 600;
  }
  .header-menu-text13 {
    font-style: normal;
    font-weight: 600;
  }
  .header-menu-text14 {
    font-style: normal;
    font-weight: 600;
  }
  .header-menu-text15 {
    font-style: normal;
    font-weight: 600;
  }
  .header-menu-container2 {
    align-self: flex-end;
  }
  .header-menu-register1 {
    font-style: normal;
    font-weight: 500;
  }
}
@media(max-width: 767px) {
  .header-menu-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-menu-register {
    display: none;
  }
  .header-menu-mobile-menu {
    margin-left: 50%;
  }
  .header-menu-container1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .header-menu-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .header-menu-mobile-menu {
    padding: 16px;
    margin-left: 42%;
  }
}
</style>
