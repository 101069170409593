<template>
  <div class="partners-container">
    <section class="partners-hero"><header-menu></header-menu></section>
    <div class="partners-hero1">
      <h1 class="partners-heading">Partners - Earn money with us</h1>
      <span class="partners-text">
        Exclusive benefits are available to web agencies and independent
        contractors through CleanWP Pro&apos;s Partner Programme. Our effective
        WordPress management solutions can help you increase your income.
      </span>
      <div class="partners-btn-group">
        <a href="#whywork" class="partners-link button">Learn more</a>
      </div>
    </div>
    <div class="partners-banner">
      <h2 id="whywork" class="partners-heading1">Partnership Program</h2>
      <span>
        <span class="partners-text02">
          Providing complete solutions for website owners and agencies, CleanWP
          Pro is a top WordPress maintenance and support service. We are pleased
          to present a partner program that enables companies to profit from our
          professional expertise and offerings while increasing their own revenue
          streams.
        </span>
        <br class="partners-text03" />
        <br class="partners-text04" />
        <span class="partners-text05">Partner options:</span>
        <br class="partners-text06" />
        <span class="partners-text07">
          We offer two partner options: white-label partnership and affiliate
          commission. With the help of our white-label partnership, you can
          customize our services and market them under your own brand, thus
          boosting your earnings while giving your customers high-quality
          services.
        </span>
        <br class="partners-text08" />
        <br class="partners-text09" />
        <span class="partners-text10">White-label partnership:</span>
        <br class="partners-text11" />
        <span>
          By partnering with us on a white-label basis, you can provide CleanWP
          Pro&apos;s fantastic services to your customers under your own brand.
          While you take care of client-facing communication and branding, we take
          care of all the technical parts of maintenance and support. By using
          this strategy, you can increase the range of services you offer without
          spending more money or working more hours.
        </span>
        <br class="partners-text13" />
        <br class="partners-text14" />
        <span class="partners-text15">Affiliate commission:</span>
        <br class="partners-text16" />
        <span>
          Promoting CleanWP Pro&apos;s services and receiving up to 30% commission
          on each sale made through your exclusive referral link. Simply recommend
          customers to us, and we&apos;ll take care of the technical aspects and
          customer service for you.
        </span>
        <br class="partners-text18" />
        <br class="partners-text19" />
        <span class="partners-text20">Partnership application:</span>
        <br class="partners-text21" />
        <span>
          Fill out the form on our website with the details of your company and
          your desired partner option to apply for our partner programme. After
          reviewing your application, a member of our staff will contact you as
          soon as they can.
        </span>
        <br class="partners-text23" />
        <br class="partners-text24" />
        <span>
          Working together with CleanWP Pro benefits both parties. You can gain
          from our top-notch support and expertise while expanding your own source
          of income. To benefit from this wonderful opportunity, submit an
          application immediately! Do not hesitate to contact us if you have any
          queries or worries.
        </span>
        <br class="partners-text26" />
        <br />
      </span>
      <div class="partners-div">
        <DangerousHTML
          html="<iframe
        onload='javascript:parent.scrollTo(0,0);'
        height='1550' allowTransparency='true'
        frameborder='0' scrolling='yes'
        style='width:100%;border:none'
        src='https://cleanwppro.formaloo.net/partnership'
        id='formalooIframe'></iframe>"
        ></DangerousHTML>
      </div>
    </div>
    <section class="partners-statistics">
      <div class="partners-container1">
        <active-users
          Caption="— Products &amp; Services"
          Statistic="5"
          rootClassName="active-users-root-class-name69"
        ></active-users>
        <active-users
          Caption="— Years of experience"
          Statistic="10+"
          rootClassName="active-users-root-class-name70"
        ></active-users>
        <active-users
          Caption="— Customers"
          Statistic="1000+"
          rootClassName="active-users-root-class-name71"
        ></active-users>
        <active-users
          Caption="— In house Team"
          Statistic="10+"
          rootClassName="active-users-root-class-name72"
        ></active-users>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name11"></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderMenu from '../components/header-menu'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'Partners',
  components: {
    HeaderMenu,
    DangerousHTML,
    ActiveUsers,
    AppFooter,
  },
  metaInfo: {
    title: 'Partners - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          "CleanWP Pro's Partner Program offers exclusive benefits to web agencies and freelancers. Boost your profits with our powerful WordPress management tools.",
      },
      {
        property: 'og:title',
        content: 'Partners - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/6c87bba3-0753-4b3a-821b-e87ae893834e?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.partners-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.partners-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.partners-hero1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(113.8deg, rgba(255, 76, 92, 0.8) 7.5%, rgba(255, 177, 70, 0.8) 94.47%),url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHx3b3JrJTIwbGFwdG9wfGVufDB8fHx8MTYyNjE3NjkzMA&ixlib=rb-1.2.1&w=1500');
}
.partners-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.partners-text {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 0px;
  text-align: center;
  line-height: 30px;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.partners-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.partners-link {
  font-size: 0.75rem;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  text-decoration: none;
}
.partners-link:hover {
  transform: scale(1.02);
}
.partners-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.partners-heading1 {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 65px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 70px;
  padding-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.partners-text02 {
  line-height: 20px;
}
.partners-text03 {
  line-height: 20px;
}
.partners-text04 {
  line-height: 20px;
}
.partners-text05 {
  font-weight: 700;
  line-height: 20px;
}
.partners-text06 {
  line-height: 20px;
}
.partners-text07 {
  line-height: 20px;
}
.partners-text08 {
  line-height: 20px;
}
.partners-text09 {
  line-height: 20px;
}
.partners-text10 {
  font-weight: 700;
}
.partners-text11 {
  line-height: 20px;
}
.partners-text13 {
  line-height: 20px;
}
.partners-text14 {
  line-height: 20px;
}
.partners-text15 {
  font-weight: 700;
}
.partners-text16 {
  line-height: 20px;
}
.partners-text18 {
  line-height: 20px;
}
.partners-text19 {
  line-height: 20px;
}
.partners-text20 {
  font-weight: 700;
}
.partners-text21 {
  line-height: 20px;
}
.partners-text23 {
  line-height: 20px;
}
.partners-text24 {
  line-height: 20px;
}
.partners-text26 {
  line-height: 20px;
}
.partners-div {
  width: var(--dl-size-size-maxwidth);
  height: 1215px;
}
.partners-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.partners-container1 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 1600px) {
  .partners-hero1 {
    min-height: 50vh;
    background-image: linear-gradient(90deg, rgba(255, 76, 92, 0.8) 7.00%,rgba(255, 177, 70, 0.8) 94.00%),url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHx3b3JrJTIwbGFwdG9wfGVufDB8fHx8MTYyNjE3NjkzMA&ixlib=rb-1.2.1&w=1500');
  }
  .partners-heading {
    color: rgb(255, 255, 255);
  }
  .partners-text {
    color: rgb(255, 255, 255);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .partners-link {
    font-size: 16px;
    text-transform: uppercase;
  }
  .partners-banner {
    align-self: flex-start;
  }
  .partners-heading1 {
    color: var(--dl-color-gray-black);
    font-size: 60px;
    align-self: center;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .partners-div {
    height: 1174px;
  }
}
@media(max-width: 1200px) {
  .partners-hero {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .partners-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .partners-text {
    font-size: 20px;
    line-height: 30px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .partners-heading1 {
    color: var(--dl-color-gray-black);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .partners-div {
    width: 100%;
  }
  .partners-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .partners-container1 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .partners-hero {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .partners-hero1 {
    flex-direction: column;
  }
  .partners-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .partners-text {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .partners-heading1 {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .partners-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .partners-container1 {
    height: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .partners-hero {
    height: auto;
    position: relative;
    background-color: #0064b7;
  }
  .partners-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .partners-heading {
    font-size: 40px;
  }
  .partners-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .partners-heading1 {
    font-size: 40px;
  }
  .partners-div {
    height: 1271px;
  }
  .partners-statistics {
    width: auto;
    height: auto;
  }
  .partners-container1 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .partners-hero {
    height: auto;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .partners-hero1 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .partners-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .partners-text {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .partners-btn-group {
    flex-direction: column;
  }
  .partners-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .partners-heading1 {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .partners-div {
    height: 1549px;
  }
  .partners-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .partners-container1 {
    width: auto;
    flex-direction: column;
  }
}
</style>
