<template>
  <div class="blog-container">
    <section class="blog-hero">
      <header-menu></header-menu>
      <div class="blog-header">
        <h1 class="blog-heading">Blog</h1>
        <span class="blog-text">
          <span>
            Stay up-to-date with the latest news and updates from CleanWP Pro and
            WordPress on our blog. Get expert tips, tutorials, and enhance your
            website today!
          </span>
          <br />
        </span>
      </div>
    </section>
    <section class="blog-blog-section">
      <div class="blog-title">
        <div class="blog-blog">
          <div class="blog-container1">
            <blog-post-card2
              when="3 days ago"
              label="WordPress"
              title="Why WordPress website need regular maintenance?"
              author="Jullie Robert"
              image_alt="Why WordPress website need regular maintenance?"
              image_src="/maintenance%20wordpress-1500w.jpeg"
              description="WordPress websites need regular maintenance to ensure they remain secure and functioning properly. Maintenance is important to keep a WordPress website running smoothly. In this blog post, we will discuss why WordPress websites need regular maintenance.."
              profile_src="https://images.unsplash.com/photo-1611232658409-0d98127f237f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
              blog_url_link="/why-wordpress-website-need-regular-maintenance"
              rootClassName="rootClassName3"
            ></blog-post-card2>
          </div>
          <div class="blog-container2">
            <blog-post-card2
              when="5 days ago"
              label="WordPress"
              title="How to reduce WordPress website running cost?"
              author="Shreya Roy"
              image_alt="How to reduce WordPress website running cost?"
              image_src="/reduce%20wordpress%20website%20running%20cost-1500w.jpeg"
              description="Here are some tips to help you reduce the running cost of your WordPress website. By following these tips, you can keep your website running smoothly and efficiently."
              profile_alt="Shreya Roy"
              profile_src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDExfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
              blog_url_link="/how-to-reduce-wordpress-website-running-cost"
              rootClassName="rootClassName"
            ></blog-post-card2>
          </div>
          <blog-post-card2
            when="8 days ago"
            label="Security"
            title="WordPress website security - Is a security plugin enough?"
            author="T.C Barman"
            image_src="/wordpress%20website%20security%20-%20is%20a%20security%20plugin%20enough-1500w.jpeg"
            description="WordPress website security is a hot topic these days. But is a security plugin enough to keep your site safe? Let's take a look at what else you can do to secure your WordPress site."
            profile_src="/image572912-0d3-200h.png"
            blog_url_link="/wordpress-website-security-is-a-security-plugin-enough"
            rootClassName="rootClassName2"
          ></blog-post-card2>
        </div>
      </div>
    </section>
    <section class="blog-statistics">
      <div class="blog-container3">
        <active-users
          Caption="— Products &amp; Services"
          Statistic="5"
          rootClassName="active-users-root-class-name45"
        ></active-users>
        <active-users
          Caption="— Years of experience"
          Statistic="10+"
          rootClassName="active-users-root-class-name46"
        ></active-users>
        <active-users
          Caption="— Customers"
          Statistic="1000+"
          rootClassName="active-users-root-class-name47"
        ></active-users>
        <active-users
          Caption="— In house Team"
          Statistic="10+"
          rootClassName="active-users-root-class-name48"
        ></active-users>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name5"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import BlogPostCard2 from '../components/blog-post-card2'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'Blog',
  components: {
    HeaderMenu,
    BlogPostCard2,
    ActiveUsers,
    AppFooter,
  },
  metaInfo: {
    title: 'Blog -  CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          "Stay ahead of the game with CleanWP Pro's latest news and updates, along with WordPress expert tips and tutorials to enhance your website.",
      },
      {
        property: 'og:title',
        content: 'Blog -  CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/cffaa5e5-833d-479c-8fa6-c7e1465a1e65?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.blog-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.blog-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.blog-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.blog-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.blog-text {
  color: #ffffff;
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 0px;
  text-align: center;
  line-height: 30px;
  padding-top: var(--dl-space-space-twounits);
}
.blog-blog-section {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #efefef;
}
.blog-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.blog-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.blog-container1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.blog-container2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.blog-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.blog-container3 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 1200px) {
  .blog-hero {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .blog-header {
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .blog-text {
    font-size: 20px;
    line-height: 30px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-title {
    height: auto;
  }
  .blog-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .blog-container3 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .blog-hero {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .blog-header {
    width: 100%;
    max-width: 1200px;
    padding-top: var(--dl-space-space-twounits);
  }
  .blog-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .blog-text {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .blog-blog-section {
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog-title {
    width: 100%;
    max-width: auto;
  }
  .blog-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-container3 {
    height: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .blog-hero {
    height: auto;
    position: relative;
    background-color: #0064b7;
  }
  .blog-header {
    height: 372px;
    justify-content: center;
  }
  .blog-heading {
    font-size: 40px;
  }
  .blog-blog-section {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .blog-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog-statistics {
    width: auto;
    height: auto;
  }
  .blog-container3 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .blog-hero {
    height: auto;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-header {
    height: auto;
    margin-top: 0px;
    padding-top: var(--dl-space-space-threeunits);
    margin-bottom: 0px;
  }
  .blog-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .blog-text {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .blog-blog-section {
    height: auto;
  }
  .blog-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .blog-container3 {
    width: auto;
    flex-direction: column;
  }
}
</style>
