<template>
  <div class="website-maintenance-container">
    <section class="website-maintenance-hero">
      <header-menu rootClassName="header-menu-root-class-name"></header-menu>
      <div class="website-maintenance-header">
        <h1 class="website-maintenance-heading">
          Keep Your WordPress Website Running Smoothly with Our Maintenance
          Services
        </h1>
      </div>
      <a href="#featuresm" class="website-maintenance-link">
        <dashboard-preview
          Image_src="/website%20maintenance%20service-1400w.jpeg"
          rootClassName="dashboard-preview-root-class-name5"
          class="website-maintenance-component1"
        ></dashboard-preview>
      </a>
    </section>
    <section class="website-maintenance-features">
      <div class="website-maintenance-title">
        <span id="features" class="website-maintenance-text">
          Sign up for our WordPress maintenance services today and enjoy a
          worry-free website!
        </span>
        <span class="website-maintenance-text001">
          Your WordPress website is the face of your business online. To keep it
          running smoothly and minimize downtime, you need regular maintenance.
          That&apos;s where we come in.
        </span>
      </div>
      <span id="featuresm" class="website-maintenance-text002 mobileHeading">
        Our maintenance services offers
      </span>
      <div class="website-maintenance-cards">
        <div class="website-maintenance-container1">
          <div class="website-maintenance-schedule card">
            <img
              alt="pastedImage"
              src="/updates-200h.png"
              class="website-maintenance-icon"
            />
            <span class="website-maintenance-text003">Upto date Website</span>
            <span class="website-maintenance-text004">
              We regularly update your website with the most recent versions of
              WordPress, themes, plugins, and PHP to guarantee optimum speed and
              security.
              <span v-html="raw10y8"></span>
            </span>
          </div>
          <div class="website-maintenance-schedule1 card">
            <img
              alt="pastedImage"
              src="/firewall-200h.png"
              class="website-maintenance-icon01"
            />
            <span class="website-maintenance-text005">Complete Security</span>
            <span class="website-maintenance-text006">
              <span>
                Our maintenance services provide total security, stopping hackers
                and getting rid of any kind of malware. Let us handle the security
                of your website so you can focus on what you do best.
              </span>
              <br />
            </span>
          </div>
        </div>
        <div class="website-maintenance-container2">
          <div class="website-maintenance-analyze card">
            <img
              alt="pastedImage"
              src="/fast%20loading-200h.png"
              class="website-maintenance-icon02"
            />
            <span class="website-maintenance-text009">Always Load Fast</span>
            <span class="website-maintenance-text010">
              <span>
                Our skilled team of WordPress professionals uses the most latest
                optimization technologies to guarantee that your website always
                loads fast, giving your users a smooth browsing experience.
              </span>
              <br />
            </span>
          </div>
          <div class="website-maintenance-get-leads card">
            <img
              alt="pastedImage"
              src="/backup-200h.png"
              class="website-maintenance-icon03"
            />
            <span class="website-maintenance-text013">Complete Backup</span>
            <span class="website-maintenance-text014">
              With our maintenance services, you can have peace of mind knowing
              that your website is constantly backed up, offering a safety net in
              case of unexpected issues, and full recovery.
            </span>
          </div>
        </div>
        <div class="website-maintenance-container3">
          <div class="website-maintenance-analyze1 card">
            <img
              alt="pastedImage"
              src="/bug%20fixing-200h.png"
              class="website-maintenance-icon04"
            />
            <span class="website-maintenance-text015">
              <span>Bug Fixes</span>
              <br />
            </span>
            <span class="website-maintenance-text018">
              If you experiencing compatibility issues or bugs on your website,
              don&apos;t worry! Our team is here to help. Contact us for expert
              assistance with installation and configuration problems.
            </span>
          </div>
          <div class="website-maintenance-get-leads1 card">
            <img
              alt="pastedImage"
              src="/save-money-200h.png"
              class="website-maintenance-icon05"
            />
            <span class="website-maintenance-text019">
              Premium Updates &amp; Support
            </span>
            <span class="website-maintenance-text020">
              We have your back! With our all-inclusive service, you can save
              money and have a hassle-free experience because Premium Plugin
              updates and support are provided without any additional fees.
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="website-maintenance-statistics">
      <div class="website-maintenance-container4">
        <active-users
          Caption="— Website maintaining"
          Statistic="100+"
          rootClassName="active-users-root-class-name29"
        ></active-users>
        <active-users
          Caption="— WordPress Experts"
          Statistic="10+"
          rootClassName="active-users-root-class-name30"
        ></active-users>
        <active-users
          Caption="— Support"
          Statistic="24x6"
          rootClassName="active-users-root-class-name31"
        ></active-users>
        <active-users
          Caption="— Cost Cutting"
          Statistic="50%"
          rootClassName="active-users-root-class-name32"
        ></active-users>
      </div>
    </section>
    <section class="website-maintenance-banners">
      <div class="website-maintenance-banner-manage">
        <div class="website-maintenance-container5">
          <div class="website-maintenance-left">
            <span class="website-maintenance-text021 sub-title">
              Cut your website running cost by 50%
            </span>
            <span class="website-maintenance-text022 mobileHeading title">
              Save times and money both with our maintenance Service
            </span>
            <span class="website-maintenance-text023">
              By outsourcing your website maintenance to experts, you&apos;ll not
              only save time but also money in the long run. A maintenance service
              will ensure that your website is always up-to-date with the latest
              software versions and security patches, reducing the risk of hacking
              and data breaches. They can also provide regular backups,
              performance optimization, and troubleshooting support, so you can
              focus on running your business without worrying about your website.
            </span>
            <router-link
              to="/how-to-reduce-wordpress-website-running-cost"
              class="website-maintenance-navlink"
            >
              <div class="website-maintenance-get-started template-button">
                <span class="website-maintenance-text024">Learn More</span>
              </div>
            </router-link>
          </div>
          <div class="website-maintenance-image-container">
            <img
              alt="Save times on maintaining your WordPress website"
              src="/wordpress%20maintennace-1200w.png"
              class="website-maintenance-cards-image"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" class="website-maintenance-pricing">
      <div class="website-maintenance-centered-container">
        <div class="website-maintenance-heading1">
          <span class="website-maintenance-text025 title mobileHeading">
            Pricing for all kind of businesses
          </span>
          <span class="website-maintenance-text026">
            <span>
              Sign up for our WordPress maintenance services now and enjoy peace
              of mind!
            </span>
            <br />
          </span>
        </div>
        <div class="website-maintenance-cards1">
          <div class="website-maintenance-card">
            <span class="website-maintenance-text029">
              <span class="website-maintenance-text030">Basic</span>
              <br />
            </span>
            <span class="website-maintenance-text032">
              If you have only one website you can choose this plan.
            </span>
            <span class="website-maintenance-text033">$249.00/year</span>
            <span class="website-maintenance-text034">What&apos;s included</span>
            <div class="website-maintenance-bullet-points">
              <div class="website-maintenance-point">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon06"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text035 Content">
                  Website Security
                </span>
              </div>
              <div class="website-maintenance-point01">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon08"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text036">
                  Unlimited Malware Cleanup
                </span>
              </div>
              <div class="website-maintenance-point02">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon10"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text037">
                  <span>Website Backup</span>
                  <br />
                </span>
              </div>
              <div class="website-maintenance-point03">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon12"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text040">
                  <span>Website Optimization</span>
                  <br />
                </span>
              </div>
            </div>
            <div class="website-maintenance-bullet-points1">
              <div class="website-maintenance-point04">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon14"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text043">
                  Include Premium Security Plugin
                </span>
              </div>
              <div class="website-maintenance-point05">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon16"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text044 Content">
                  Include CleanWP Pro Plugin
                </span>
              </div>
              <div class="website-maintenance-point06">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon18"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text045">
                  Include Premium Optimization Plugin
                </span>
              </div>
              <div class="website-maintenance-point07">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon20"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text046">
                  <span>Monthly Updates and Maintenance</span>
                  <br />
                </span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/26/checkout"
              class="website-maintenance-link1"
            >
              <div class="website-maintenance-get-started1 template-button">
                <span class="website-maintenance-text049">Start now</span>
              </div>
            </a>
          </div>
          <div class="website-maintenance-card1">
            <span class="website-maintenance-text050">Standard</span>
            <span class="website-maintenance-text051">
              If you have multiple websites choose this plan.
            </span>
            <span class="website-maintenance-text052">$349.00/year</span>
            <span class="website-maintenance-text053">What&apos;s included</span>
            <div class="website-maintenance-bullet-points2">
              <div class="website-maintenance-point08">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon22"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text054">
                  Everything from Basic +
                </span>
              </div>
            </div>
            <div class="website-maintenance-bullet-points3">
              <div class="website-maintenance-point09">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon24"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text055">
                  1 Hour of WordPress works/month
                </span>
              </div>
              <div class="website-maintenance-point10">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon26"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text056">
                  Submit up to 2 tasks/month
                </span>
              </div>
              <div class="website-maintenance-point11">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon28"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text057">
                  Premium Plugin Updates and Support - So you don&apos;t need to
                  pay for renewal.
                </span>
              </div>
              <div class="website-maintenance-point12">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon30"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text058">
                  You can use the WordPress development time credits for bug
                  fixing or small customization
                </span>
              </div>
            </div>
            <div class="website-maintenance-point13">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                class="website-maintenance-icon32"
              >
                <path
                  d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                ></path>
              </svg>
              <span class="website-maintenance-text059">
                On Demand Updates and Maintenance
              </span>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/27/checkout"
              class="website-maintenance-link2"
            >
              <div class="website-maintenance-get-started2 template-button">
                <span class="website-maintenance-text060">
                  <span>Start now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
          <div class="website-maintenance-card2">
            <span class="website-maintenance-text063">Premium</span>
            <span class="website-maintenance-text064">
              <span>
                If you are an Agency choose this plan to install CleanWP Pro on
                100 of Websites.
              </span>
              <br />
            </span>
            <span class="website-maintenance-text067">$499.00/year</span>
            <span class="website-maintenance-text068">What&apos;s included</span>
            <div class="website-maintenance-bullet-points4">
              <div class="website-maintenance-point14">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon34"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text069">
                  Everything from Basic +
                </span>
              </div>
              <div class="website-maintenance-point15">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon36"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text070">
                  Rank Math Premium SEO Plugin
                </span>
              </div>
              <div class="website-maintenance-point16">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon38"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text071">
                  2 Hours of WordPress works/month
                </span>
              </div>
            </div>
            <div class="website-maintenance-bullet-points5">
              <div class="website-maintenance-point17">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon40"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text072">
                  Submit up to 4 tasks/month
                </span>
              </div>
              <div class="website-maintenance-point18">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon42"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text073">
                  Premium Plugin Updates and Support - So you don&apos;t need to
                  pay for renewal.
                </span>
              </div>
              <div class="website-maintenance-point19">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="website-maintenance-icon44"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="website-maintenance-text074">
                  You can use the WordPress development time credits for bug
                  fixing or small customization
                </span>
              </div>
            </div>
            <div class="website-maintenance-point20">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                class="website-maintenance-icon46"
              >
                <path
                  d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                ></path>
              </svg>
              <span class="website-maintenance-text075">
                On Demand Updates and Maintenance
              </span>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/28/checkout"
              class="website-maintenance-link3"
            >
              <div class="website-maintenance-get-started3 template-button">
                <span class="website-maintenance-text076">
                  <span>Start now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="faq" class="website-maintenance-faq">
      <div class="website-maintenance-heading2">
        <span class="website-maintenance-text079 title">
          Frequently asked questions
        </span>
        <span class="website-maintenance-text080">
          Answers to frequently asked questions (FAQs) concerning Website
          maintenance services
        </span>
      </div>
      <div class="website-maintenance-accordion">
        <div
          data-faq="closed"
          class="website-maintenance-accordion-container accordionContainer"
        >
          <div class="website-maintenance-header1">
            <span class="website-maintenance-text081">
              — How you going to reduce the running cost of a WordPress website by
              50%?
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="website-maintenance-text082">
              <span>
                As a WordPress website owner, one of your top priorities is
                maintaining the smooth running of your website. However, this can
                come with a hefty price tag when considering the necessary premium
                plugins, expert maintenance, and potential malware removal fees.
              </span>
              <br />
              <br />
              <span>
                To break it down further, you would need to invest in a page
                builder ($59/year), security plugin ($70/year), caching or
                optimization plugin ($59/year), and SEO plugin ($59/year). The
                cost of these premium plugins alone total up to $247 per year.
              </span>
              <br />
              <br />
              <span>
                Furthermore, hiring a WordPress Security Expert or Performance
                Optimization Expert to handle updates, security maintenance, and
                website performance optimization every 3 months for just 2 hours
                can cost you an additional $240 per year. This is assuming the
                minimum hourly rate of $30.
              </span>
              <br />
              <br />
              <span>
                In addition, if your website gets affected by malware due to not
                updating WordPress or plugins on time, or not having proper
                security measures in place, hiring a malware removal expert will
                cost you at least $100 per incident. Therefore, even if your
                website gets infected once a year, it will result in another $100
                charge.
              </span>
              <br />
              <br />
              <span>
                All of these costs add up quickly, resulting in a total expense of
                $587 per year. And that doesn&apos;t even include the charges for
                any other bugs or compatibility issues that may arise on your
                website, which could take more than 2 hours of work every 3
                months.
              </span>
              <br />
              <br />
              <span>
                Fortunately, with CleanWP Pro, you can get all of these essential
                services and complete maintenance for only $249 per year. By
                choosing CleanWP Pro, you&apos;ll be able to cut your WordPress
                website&apos;s running costs by half while ensuring the best
                possible user experience for your visitors.
              </span>
              <br />
              <br />
              <span>
                So, choose wisely and opt for CleanWP Pro website maintenance
                service to reduce your website&apos;s running costs, streamline
                maintenance processes, and ensure optimal security and
                performance.
              </span>
            </span>
          </div>
        </div>
        <div
          data-faq="closed"
          class="website-maintenance-accordion-container1 accordionContainer"
        >
          <div class="website-maintenance-header2">
            <span class="website-maintenance-text102">
              <span>— Do you have monthly plans?</span>
              <br />
              <span><span v-html="rawwmk8"></span></span>
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="website-maintenance-text106">
              <span>
                At this time, we do not offer a monthly plan for our services.
                This is because we believe in providing our clients with the best
                possible service and value for their investment.
              </span>
              <br />
              <br />
              <span>
                When you sign up for our services, our team of experts will work
                diligently to ensure that your website is secure, optimized, and
                backed up. We also install premium plugins to enhance the
                functionality and performance of your website.
              </span>
              <br />
              <br />
              <span>
                While we understand that some clients may prefer a monthly pricing
                model, we have found that it can be misused by certain individuals
                who subscribe only to cancel their subscription after a month. We
                believe that our current pricing model ensures that we can provide
                the highest level of service to all of our clients, while also
                protecting our business from potential misuse.
              </span>
            </span>
          </div>
        </div>
        <div class="accordionContainer">
          <div class="website-maintenance-header3">
            <span class="website-maintenance-text114">
              — Which premium plugins includes on the maintenance plans?
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="website-maintenance-text115">
              <span>
                Based on the maintenance plan, we are pleased to include the
                following premium plugins:
              </span>
              <br />
              <br />
              <span>
                1. CleanWP Pro - the ultimate WordPress cleaner and Optimization
                Plugin.
              </span>
              <br />
              <br />
              <span>
                2. A Premium Security plugin, such as iTheme Security Pro, Hide My
                WP Ghost, or All In One WP Security Pro, which will help ensure
                that your website remains secure and protected from potential
                threats.
              </span>
              <br />
              <br />
              <span>
                3. A Caching plugin, such as WP Rocket or FlyingPress cache
                plugin, which will improve the loading time and overall
                performance of your website.
              </span>
              <br />
              <br />
              <span>
                4. A Premium SEO plugin, Rank math Pro, which will help boost your
                website&apos;s visibility and ranking on search engines.
              </span>
              <br />
              <br />
              <span>
                We have acquired agency or business licenses for all these
                plugins, so you can be confident that you have access to the
                latest features and functionalities available.
              </span>
            </span>
          </div>
        </div>
        <div data-faq="closed" class="accordionContainer">
          <div class="website-maintenance-header4">
            <span class="website-maintenance-text132">
              <span>— How you do premium plugin updates?</span>
              <br />
              <span><span v-html="rawjd2f"></span></span>
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="website-maintenance-text136">
              <span>
                As a WordPress website maintenance agency, we hold an agency
                license for numerous popular plugins such as page builder plugins
                like Elementor Pro and Divi Page Builder, WooCommerce plugins,
                security plugins, caching plugins, and SEO plugins.
              </span>
              <br />
              <span>
                To obtain the latest updates and features of these premium
                plugins, we use our agency or business license to activate and
                update them.
                <span v-html="raw4sc2"></span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div>
        <DangerousHTML
          html="<script>
  // Accordion - Code Embed
  
  const accordionContainers = document.querySelectorAll('.accordionContainer'); // All accordion containers
  const accordionContents = document.querySelectorAll('.accordionContent'); // All accordion content
  const accordionIcons = document.querySelectorAll('.accordionIcon'); // All accordion icons
  
  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = 'none'; //Hides all accordion contents
  });
  
  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener('click', () => {
          accordionContents.forEach((accordionContent) => {
              accordionContent.style.display = 'none'; //Hides all accordion contents
          });
  
          accordionIcons.forEach((accordionIcon) => {
              accordionIcon.style.transform = 'rotate(0deg)'; // Resets all icon transforms to 0deg (default)
          });
  
          if (accordionContainer.classList.contains('accordion-open')) { // Checks if this container has class 'accordion-open'
              accordionContainer.classList.remove('accordion-open'); // Removes class 'accordion-open'
          } else {
              accordionContainers.forEach((accordionContainer) => {
                  accordionContainer.classList.remove('accordion-open'); // Removes class 'accordion-open' from all containers
              });
  
              accordionContainer.classList.add('accordion-open'); // Adds class 'accordion-open' to this container
              accordionContents[index].style.display = 'flex'; // Shows accordion content
              accordionIcons[index].style.transform = 'rotate(180deg)'; // Rotates accordion icon 180deg
          }
      });
  });
  
  /*
  Here's what the above is doing:
      1. Selects all accordion containers, contents, and icons
      2. Hides all accordion contents
      3. Adds an event listener to each accordion container
      4. When an accordion container is clicked, it:
          - Hides all accordion contents
          - Resets all icon transforms to 0deg (default)
          - Checks if this container has class 'accordion-open'
              - If it does, it removes class 'accordion-open'
              - If it doesn't, it:
                  - Removes class 'accordion-open' from all containers
                  - Adds class 'accordion-open' to this container
                  - Shows accordion content
                  - Rotates accordion icon 180deg
  */
  </script>"
        ></DangerousHTML>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name1"></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderMenu from '../components/header-menu'
import DashboardPreview from '../components/dashboard-preview'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'WebsiteMaintenance',
  components: {
    HeaderMenu,
    DashboardPreview,
    ActiveUsers,
    DangerousHTML,
    AppFooter,
  },
  data() {
    return {
      raw10y8: ' ',
      rawwmk8: ' ',
      rawjd2f: ' ',
      raw4sc2: ' ',
    }
  },
  metaInfo: {
    title: 'WordPress Website Maintenance Services - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'Ensure optimal performance of your WordPress site with our professional maintenance services. Get regular updates, backups, security checks & more.',
      },
      {
        property: 'og:title',
        content: 'WordPress Website Maintenance Services - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/0f7ff0c2-0de1-4af4-a99b-690936278db2?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.website-maintenance-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.website-maintenance-hero {
  width: 100%;
  height: 749px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.website-maintenance-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.website-maintenance-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  padding-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.website-maintenance-link {
  display: contents;
}
.website-maintenance-component1 {
  text-decoration: none;
}
.website-maintenance-features {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #efefef;
}
.website-maintenance-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.website-maintenance-text {
  font-size: 50px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 65px;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.website-maintenance-text001 {
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.website-maintenance-text002 {
  margin-top: var(--dl-space-space-fiveunits);
}
.website-maintenance-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.website-maintenance-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.website-maintenance-schedule {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text003 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.website-maintenance-text004 {
  line-height: 24px;
}
.website-maintenance-schedule1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-icon01 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text005 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.website-maintenance-text006 {
  line-height: 24px;
}
.website-maintenance-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.website-maintenance-analyze {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-icon02 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text009 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.website-maintenance-text010 {
  line-height: 24px;
}
.website-maintenance-get-leads {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #ffffff;
}
.website-maintenance-icon03 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text013 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.website-maintenance-text014 {
  line-height: 24px;
}
.website-maintenance-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.website-maintenance-analyze1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-icon04 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text015 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.website-maintenance-text018 {
  line-height: 24px;
}
.website-maintenance-get-leads1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-icon05 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text019 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.website-maintenance-text020 {
  line-height: 24px;
}
.website-maintenance-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.website-maintenance-container4 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.website-maintenance-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.website-maintenance-banner-manage {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.website-maintenance-container5 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.website-maintenance-left {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.website-maintenance-text021 {
  color: rgb(0, 100, 183);
}
.website-maintenance-text022 {
  text-align: left;
}
.website-maintenance-text023 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.website-maintenance-navlink {
  display: contents;
}
.website-maintenance-get-started {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.website-maintenance-get-started:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.website-maintenance-text024 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.website-maintenance-image-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.website-maintenance-cards-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}
.website-maintenance-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #0064b7;
}
.website-maintenance-centered-container {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.website-maintenance-heading1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.website-maintenance-text025 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.website-maintenance-text026 {
  color: rgb(238, 233, 254);
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-cards1 {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.website-maintenance-card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.website-maintenance-text029 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text030 {
  align-self: center;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text032 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-text033 {
  font-size: 40px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text034 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-bullet-points {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-maintenance-point {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon06 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text035 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point01 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon08 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text036 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point02 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon10 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text037 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point03 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon12 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text040 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-bullet-points1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-maintenance-point04 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon14 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text043 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point05 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon16 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text044 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point06 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon18 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text045 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point07 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon20 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text046 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-maintenance-link1 {
  display: contents;
}
.website-maintenance-get-started1 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.website-maintenance-get-started1:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.website-maintenance-text049 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.website-maintenance-card1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.website-maintenance-text050 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text051 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-text052 {
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text053 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-bullet-points2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-maintenance-point08 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon22 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text054 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-bullet-points3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-maintenance-point09 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon24 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text055 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point10 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon26 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text056 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point11 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon28 {
  fill: #0064b7;
  width: 16px;
  height: 16px;
}
.website-maintenance-text057 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point12 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon30 {
  fill: #0064b7;
  width: 16px;
  height: 16px;
}
.website-maintenance-text058 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point13 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon32 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text059 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-maintenance-link2 {
  display: contents;
}
.website-maintenance-get-started2 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.website-maintenance-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.website-maintenance-text060 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.website-maintenance-card2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #000000;
}
.website-maintenance-text063 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text064 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.website-maintenance-text067 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-text068 {
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.website-maintenance-bullet-points4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-maintenance-point14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon34 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text069 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point15 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon36 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text070 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon38 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text071 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-bullet-points5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.website-maintenance-point17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon40 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text072 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point18 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon42 {
  fill: #0064b7;
  width: 16px;
  height: 16px;
}
.website-maintenance-text073 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point19 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon44 {
  fill: #0064b7;
  width: 16px;
  height: 16px;
}
.website-maintenance-text074 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.website-maintenance-point20 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.website-maintenance-icon46 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.website-maintenance-text075 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.website-maintenance-link3 {
  display: contents;
}
.website-maintenance-get-started3 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.website-maintenance-get-started3:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.website-maintenance-text076 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}
.website-maintenance-text076:hover {
  color: var(--dl-color-gray-900);
}
.website-maintenance-faq {
  width: 100%;
  display: flex;
  padding: 120px;
  align-items: center;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #EFF0F2;
}
.website-maintenance-heading2 {
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  flex-direction: column;
}
.website-maintenance-text079 {
  font-size: 56px;
  text-align: center;
}
.website-maintenance-text080 {
  color: rgb(0, 0, 0);
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.website-maintenance-accordion {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.website-maintenance-accordion-container {
  transition: 0.3s;
}

.website-maintenance-header1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.website-maintenance-text081 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  font-weight: 500;
}
.website-maintenance-text082 {
  width: 100%;
  font-size: 16px;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}
.website-maintenance-text082:focus {
  color: #000000;
}
.website-maintenance-accordion-container1 {
  transition: 0.3s;
}

.website-maintenance-header2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.website-maintenance-text102 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  font-weight: 500;
}
.website-maintenance-text106 {
  width: 100%;
  font-size: 16px;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}
.website-maintenance-text106:focus {
  color: #000000;
}
.website-maintenance-header3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.website-maintenance-text114 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}

.website-maintenance-text115 {
  width: 100%;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}
.website-maintenance-text115:focus {
  color: #000000;
}
.website-maintenance-header4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.website-maintenance-text132 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}
.website-maintenance-text132:hover {
  color: var(--dl-color-gray-black);
}
.website-maintenance-text136 {
  width: 100%;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}
.website-maintenance-text136:focus {
  color: #000000;
}
@media(max-width: 1600px) {
  .website-maintenance-text024 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
}
@media(max-width: 1200px) {
  .website-maintenance-hero {
    width: 100%;
    height: 941px;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .website-maintenance-header {
    margin-top: var(--dl-space-space-sevenunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .website-maintenance-heading {
    color: rgb(255, 255, 255);
    width: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
    text-transform: none;
    text-decoration: none;
  }
  .website-maintenance-features {
    padding-top: 380px;
  }
  .website-maintenance-text {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .website-maintenance-text001 {
    font-size: 20px;
    line-height: 30px;
  }
  .website-maintenance-text015 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .website-maintenance-text018 {
    line-height: 24px;
  }
  .website-maintenance-text019 {
    line-height: 33px;
  }
  .website-maintenance-text020 {
    line-height: 24px;
  }
  .website-maintenance-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .website-maintenance-container4 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
  .website-maintenance-banner-manage {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .website-maintenance-container5 {
    max-width: auto;
  }
  .website-maintenance-text021 {
    color: rgb(0, 100, 183);
  }
  .website-maintenance-pricing {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .website-maintenance-centered-container {
    flex-direction: column;
  }
  .website-maintenance-text081 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .website-maintenance-text082 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 24px;
  }
  .website-maintenance-text102 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .website-maintenance-text106 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 24px;
  }
  .website-maintenance-text114 {
    color: var(--dl-color-gray-black);
  }
  .website-maintenance-text115 {
    color: var(--dl-color-gray-black);
  }
  .website-maintenance-text136 {
    color: var(--dl-color-gray-black);
  }
}
@media(max-width: 991px) {
  .website-maintenance-hero {
    height: 809px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .website-maintenance-header {
    width: 100%;
    max-width: 1200px;
    margin-top: var(--dl-space-space-fourunits);
  }
  .website-maintenance-heading {
    font-size: 50px;
  }
  .website-maintenance-features {
    height: auto;
    padding-top: 330px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .website-maintenance-title {
    width: 100%;
    max-width: auto;
  }
  .website-maintenance-text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .website-maintenance-text001 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .website-maintenance-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .website-maintenance-text004 {
    line-height: 21px;
  }
  .website-maintenance-text005 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .website-maintenance-text006 {
    line-height: 21px;
  }
  .website-maintenance-text010 {
    line-height: 21px;
  }
  .website-maintenance-text013 {
    font-size: 24px;
  }
  .website-maintenance-text014 {
    line-height: 21px;
  }
  .website-maintenance-text018 {
    line-height: 21px;
  }
  .website-maintenance-text019 {
    font-size: 24px;
  }
  .website-maintenance-text020 {
    line-height: 21px;
  }
  .website-maintenance-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .website-maintenance-container4 {
    height: 100%;
    flex-direction: row;
  }
  .website-maintenance-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .website-maintenance-left {
    margin-right: var(--dl-space-space-threeunits);
  }
  .website-maintenance-text021 {
    color: #0064b7;
  }
  .website-maintenance-text022 {
    font-size: 40px;
    line-height: 45px;
  }
  .website-maintenance-cards-image {
    width: 360px;
  }
  .website-maintenance-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .website-maintenance-centered-container {
    width: 100%;
    position: relative;
    max-width: auto;
  }
  .website-maintenance-text025 {
    font-size: 45px;
    line-height: 50px;
  }
  .website-maintenance-text026 {
    width: 100%;
    max-width: 600px;
  }
  .website-maintenance-cards1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .website-maintenance-card {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .website-maintenance-text032 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .website-maintenance-text034 {
    font-size: 16px;
  }
  .website-maintenance-card1 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .website-maintenance-text051 {
    color: rgb(0, 0, 0);
    line-height: 24px;
  }
  .website-maintenance-text053 {
    font-size: 16px;
  }
  .website-maintenance-card2 {
    width: 100%;
    align-items: flex-start;
    padding-right: var(--dl-space-space-twounits);
  }
  .website-maintenance-text064 {
    color: rgb(255, 255, 255);
    line-height: 24px;
  }
  .website-maintenance-text067 {
    color: var(--dl-color-gray-white);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
  }
  .website-maintenance-text079 {
    font-size: 45px;
    line-height: 50px;
  }
  .website-maintenance-accordion {
    width: 100%;
    max-width: 1200px;
  }
}
@media(max-width: 767px) {
  .website-maintenance-hero {
    height: 710px;
    position: relative;
    padding-bottom: 180px;
    background-color: #0064b7;
  }
  .website-maintenance-header {
    height: 372px;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .website-maintenance-heading {
    font-size: 40px;
  }
  .website-maintenance-features {
    height: auto;
    padding-top: 225px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .website-maintenance-text {
    font-size: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .website-maintenance-text002 {
    text-align: center;
  }
  .website-maintenance-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .website-maintenance-text003 {
    font-size: 24px;
  }
  .website-maintenance-text004 {
    font-size: 14px;
  }
  .website-maintenance-text005 {
    font-size: 24px;
  }
  .website-maintenance-text006 {
    font-size: 14px;
  }
  .website-maintenance-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .website-maintenance-text009 {
    font-size: 24px;
  }
  .website-maintenance-text010 {
    font-size: 14px;
  }
  .website-maintenance-text014 {
    font-size: 14px;
  }
  .website-maintenance-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .website-maintenance-text015 {
    font-size: 24px;
  }
  .website-maintenance-text018 {
    font-size: 14px;
  }
  .website-maintenance-text020 {
    font-size: 14px;
  }
  .website-maintenance-statistics {
    width: auto;
    height: auto;
  }
  .website-maintenance-container4 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
  .website-maintenance-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .website-maintenance-container5 {
    flex-direction: column;
  }
  .website-maintenance-left {
    margin-right: 0px;
  }
  .website-maintenance-text022 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .website-maintenance-text023 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .website-maintenance-text024 {
    font-size: 16px;
  }
  .website-maintenance-image-container {
    justify-content: center;
  }
  .website-maintenance-cards-image {
    margin-top: var(--dl-space-space-threeunits);
  }
  .website-maintenance-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .website-maintenance-centered-container {
    width: auto;
    flex-direction: column;
  }
  .website-maintenance-cards1 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .website-maintenance-card {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .website-maintenance-text029 {
    font-size: 28px;
  }
  .website-maintenance-text030 {
    font-size: 28px;
  }
  .website-maintenance-text032 {
    width: auto;
  }
  .website-maintenance-text033 {
    font-size: 28px;
  }
  .website-maintenance-bullet-points {
    width: 100%;
  }
  .website-maintenance-bullet-points1 {
    width: 100%;
  }
  .website-maintenance-card1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .website-maintenance-text050 {
    font-size: 28px;
  }
  .website-maintenance-text051 {
    width: auto;
  }
  .website-maintenance-text052 {
    font-size: 28px;
  }
  .website-maintenance-card2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .website-maintenance-text063 {
    font-size: 28px;
  }
  .website-maintenance-text064 {
    width: auto;
  }
  .website-maintenance-text067 {
    font-size: 28px;
  }
  .website-maintenance-faq {
    padding: var(--dl-space-space-fourunits);
  }
  .website-maintenance-heading2 {
    width: 100%;
  }
  .website-maintenance-text079 {
    font-size: 36px;
    line-height: 36px;
  }
  .website-maintenance-text080 {
    width: 100%;
    max-width: 600p;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .website-maintenance-hero {
    height: 546px;
    padding-bottom: 51px;
  }
  .website-maintenance-header {
    height: auto;
    margin-top: 0px;
    padding-top: var(--dl-space-space-threeunits);
  }
  .website-maintenance-heading {
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .website-maintenance-features {
    height: auto;
    padding-top: 120px;
  }
  .website-maintenance-text {
    font-size: 32px;
    line-height: 45px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .website-maintenance-text001 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .website-maintenance-text002 {
    font-size: 32;
    line-height: 38px;
  }
  .website-maintenance-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .website-maintenance-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .website-maintenance-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .website-maintenance-get-leads {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .website-maintenance-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .website-maintenance-get-leads1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .website-maintenance-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .website-maintenance-container4 {
    width: auto;
    flex-direction: column;
  }
  .website-maintenance-banner-manage {
    width: 100%;
  }
  .website-maintenance-left {
    width: auto;
  }
  .website-maintenance-text022 {
    font-size: 30px;
    line-height: 38px;
  }
  .website-maintenance-text023 {
    line-height: 24px;
  }
  .website-maintenance-cards-image {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .website-maintenance-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .website-maintenance-centered-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .website-maintenance-text025 {
    font-size: 32px;
    line-height: 38px;
  }
  .website-maintenance-cards1 {
    flex-direction: column;
  }
  .website-maintenance-text079 {
    font-size: 32px;
    line-height: 38px;
  }
}
</style>
