<template>
  <div class="work-with-us-clean-w-pro-container">
    <section class="work-with-us-clean-w-pro-hero">
      <header-menu></header-menu>
    </section>
    <div class="work-with-us-clean-w-pro-hero1">
      <h1 class="work-with-us-clean-w-pro-heading">Careers - Work with Us</h1>
      <span class="work-with-us-clean-w-pro-text">
        If you are looking for a challenging and rewarding career in the field of
        WordPress development, then CleanWP Pro is the place to be.
        <span v-html="rawnwo7"></span>
      </span>
      <div class="work-with-us-clean-w-pro-btn-group">
        <a href="#whywork" class="work-with-us-clean-w-pro-link button">
          Learn more
        </a>
      </div>
    </div>
    <div class="work-with-us-clean-w-pro-banner">
      <h2 id="whywork" class="work-with-us-clean-w-pro-heading1">
        Why Work at CleanWP Pro?
      </h2>
      <span class="work-with-us-clean-w-pro-text01">
        <span>
          CleanWP Pro is a dynamic and fast-growing branch of TechBengal - IT
          Solutions, that specializes in building and providing WordPress-related
          products and services. We take pride in offering our clients top-notch
          solutions that meet their specific needs and exceed their expectations.
        </span>
        <br />
        <br />
        <span>
          If you are looking for a challenging and rewarding career in the field
          of WordPress development, then CleanWP Pro is the place to be. By
          joining our team, you will have the opportunity to work with
          international clients and gain valuable experience as a freelancer. We
          also offer remote working options, which allow you to work from anywhere
          in the world while still being a part of a collaborative team.
        </span>
        <br />
        <br />
        <span>
          At CleanWP Pro, we believe in investing in our employees&apos; growth
          and development. Our yearly team get-togethers provide a great
          opportunity for networking and learning from your peers. We also offer
          other perks that are updated regularly to ensure that our team members
          feel appreciated and motivated.
        </span>
        <br />
        <br />
        <span>
          Joining CleanWP Pro means that you will be a part of an innovative and
          forward-thinking team that values creativity, collaboration, and
          excellence. If you are passionate about WordPress development and want
          to work in an environment that encourages personal and professional
          growth, then CleanWP Pro is the perfect place for you.
        </span>
        <br />
        <br />
        <span>
          At CleanWP Pro, we recognize that formal education is not the only path
          to acquiring valuable skills and experience.
          <span v-html="rawlr9q"></span>
        </span>
        <span class="work-with-us-clean-w-pro-text15">
          We prioritize talent and expertise over academic qualifications or
          grades.
        </span>
        <span>
          Our team values individuals who can demonstrate their abilities through
          their work and achievements, rather than relying solely on educational
          credentials.
        </span>
        <br />
        <br />
        <span>
          We understand that traditional measures of success do not always capture
          the full range of talents and capabilities that individuals possess.
          That is why we are committed to creating an inclusive environment where
          all types of skills are valued and appreciated.
        </span>
        <span class="work-with-us-clean-w-pro-text20">
          As long as you can showcase your abilities and contribute to our
          mission, we welcome your participation in our projects.
        </span>
        <br />
        <br />
        <span>
          So if you believe you have the necessary skills to help us build
          innovative solutions, we invite you to show us what you&apos;ve got. We
          are always looking for talented individuals who can bring fresh
          perspectives and ideas to the table.
          <span v-html="rawg1pk"></span>
        </span>
        <span class="work-with-us-clean-w-pro-text24">
          Just provide us with proof of your skills and experience, and we&apos;ll
          be happy to welcome you aboard!
        </span>
        <span><span v-html="rawj1n5"></span></span>
        <span><span v-html="rawnezf"></span></span>
      </span>
      <div class="work-with-us-clean-w-pro-div">
        <DangerousHTML
          html="<iframe
        onload='javascript:parent.scrollTo(0,0);'
        height='2000' allowTransparency='true'
        frameborder='0' scrolling='yes'
        style='width:100%;border:none'
        src='https://cleanwppro.formaloo.net/jobs'
        id='formalooIframe'></iframe>"
        ></DangerousHTML>
      </div>
    </div>
    <section class="work-with-us-clean-w-pro-statistics">
      <div class="work-with-us-clean-w-pro-container1">
        <active-users
          Caption="— Products &amp; Services"
          Statistic="5"
          rootClassName="active-users-root-class-name65"
        ></active-users>
        <active-users
          Caption="— Years of experience"
          Statistic="10+"
          rootClassName="active-users-root-class-name66"
        ></active-users>
        <active-users
          Caption="— Customers"
          Statistic="1000+"
          rootClassName="active-users-root-class-name67"
        ></active-users>
        <active-users
          Caption="— In house Team"
          Statistic="10+"
          rootClassName="active-users-root-class-name68"
        ></active-users>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name10"></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderMenu from '../components/header-menu'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'WorkWithUsCleanWPPro',
  components: {
    HeaderMenu,
    DangerousHTML,
    ActiveUsers,
    AppFooter,
  },
  data() {
    return {
      rawnwo7: ' ',
      rawlr9q: ' ',
      rawg1pk: ' ',
      rawj1n5: ' ',
      rawnezf: ' ',
    }
  },
  metaInfo: {
    title: 'Work with us - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'If you are looking for a challenging and rewarding career in the field of WordPress development, then CleanWP Pro is the place to be.',
      },
      {
        property: 'og:title',
        content: 'Work with us - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/3f77f370-9dee-4a89-a53d-4b433d26d461?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.work-with-us-clean-w-pro-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.work-with-us-clean-w-pro-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.work-with-us-clean-w-pro-hero1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(113.8deg, rgba(255, 76, 92, 0.8) 7.5%, rgba(255, 177, 70, 0.8) 94.47%),url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHx3b3JrJTIwbGFwdG9wfGVufDB8fHx8MTYyNjE3NjkzMA&ixlib=rb-1.2.1&w=1500');
}
.work-with-us-clean-w-pro-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.work-with-us-clean-w-pro-text {
  color: #ffffff;
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 0px;
  text-align: center;
  line-height: 30px;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.work-with-us-clean-w-pro-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.work-with-us-clean-w-pro-link {
  font-size: 0.75rem;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  text-decoration: none;
}
.work-with-us-clean-w-pro-link:hover {
  transform: scale(1.02);
}
.work-with-us-clean-w-pro-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.work-with-us-clean-w-pro-heading1 {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 65px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: none;
  text-decoration: none;
}
.work-with-us-clean-w-pro-text01 {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-bottom: var(--dl-space-space-twounits);
}
.work-with-us-clean-w-pro-div {
  width: 100%;
  height: 1780px;
}
.work-with-us-clean-w-pro-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.work-with-us-clean-w-pro-container1 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 1600px) {
  .work-with-us-clean-w-pro-hero1 {
    min-height: 50vh;
    background-image: linear-gradient(90deg, rgba(255, 76, 92, 0.8) 7.00%,rgba(255, 177, 70, 0.8) 94.00%),url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHx3b3JrJTIwbGFwdG9wfGVufDB8fHx8MTYyNjE3NjkzMA&ixlib=rb-1.2.1&w=1500');
  }
  .work-with-us-clean-w-pro-heading {
    color: rgb(255, 255, 255);
  }
  .work-with-us-clean-w-pro-text {
    color: rgb(255, 255, 255);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .work-with-us-clean-w-pro-link {
    font-size: 16px;
    text-transform: uppercase;
  }
  .work-with-us-clean-w-pro-banner {
    align-self: flex-start;
  }
  .work-with-us-clean-w-pro-heading1 {
    color: var(--dl-color-gray-black);
    font-size: 60px;
    align-self: center;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 60px;
    text-transform: none;
    text-decoration: none;
  }
  .work-with-us-clean-w-pro-text01 {
    align-self: center;
    text-align: left;
  }
  .work-with-us-clean-w-pro-text15 {
    font-weight: 700;
  }
  .work-with-us-clean-w-pro-text20 {
    font-weight: 700;
  }
  .work-with-us-clean-w-pro-text24 {
    font-weight: 700;
  }
  .work-with-us-clean-w-pro-div {
    width: 100%;
    height: 1860px;
  }
}
@media(max-width: 1200px) {
  .work-with-us-clean-w-pro-hero {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .work-with-us-clean-w-pro-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .work-with-us-clean-w-pro-text {
    font-size: 20px;
    line-height: 30px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .work-with-us-clean-w-pro-heading1 {
    color: var(--dl-color-gray-black);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .work-with-us-clean-w-pro-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .work-with-us-clean-w-pro-container1 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .work-with-us-clean-w-pro-hero {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .work-with-us-clean-w-pro-hero1 {
    flex-direction: column;
  }
  .work-with-us-clean-w-pro-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .work-with-us-clean-w-pro-text {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .work-with-us-clean-w-pro-heading1 {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .work-with-us-clean-w-pro-text01 {
    max-width: 100%;
  }
  .work-with-us-clean-w-pro-div {
    height: 1850px;
  }
  .work-with-us-clean-w-pro-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .work-with-us-clean-w-pro-container1 {
    height: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .work-with-us-clean-w-pro-hero {
    height: auto;
    position: relative;
    background-color: #0064b7;
  }
  .work-with-us-clean-w-pro-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .work-with-us-clean-w-pro-heading {
    font-size: 40px;
  }
  .work-with-us-clean-w-pro-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .work-with-us-clean-w-pro-heading1 {
    font-size: 40px;
    padding-top: var(--dl-space-space-halfunit);
  }
  .work-with-us-clean-w-pro-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .work-with-us-clean-w-pro-div {
    height: 1950px;
  }
  .work-with-us-clean-w-pro-statistics {
    width: auto;
    height: auto;
  }
  .work-with-us-clean-w-pro-container1 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .work-with-us-clean-w-pro-hero {
    height: auto;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .work-with-us-clean-w-pro-hero1 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .work-with-us-clean-w-pro-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .work-with-us-clean-w-pro-text {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .work-with-us-clean-w-pro-btn-group {
    flex-direction: column;
  }
  .work-with-us-clean-w-pro-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .work-with-us-clean-w-pro-heading1 {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .work-with-us-clean-w-pro-div {
    height: 2000px;
  }
  .work-with-us-clean-w-pro-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .work-with-us-clean-w-pro-container1 {
    width: auto;
    flex-direction: column;
  }
}
</style>
