<template>
  <div class="not-found-container">
    <section class="not-found-hero">
      <header-menu rootClassName="header-menu-root-class-name4"></header-menu>
    </section>
    <div class="not-found-container1">
      <div class="not-found-hero1">
        <img alt="image" src="/404-1500h.png" class="not-found-image" />
        <h1 class="not-found-heading">Oops! Page Not Found</h1>
        <h3 class="not-found-text">
          <span>
            Stay up-to-date with the latest news and updates from CleanWP Pro and
            WordPress on our blog. Get expert tips, tutorials, and enhance your
            website today!
          </span>
          <br />
        </h3>
        <router-link to="/" class="not-found-navlink">
          <div class="not-found-get-started template-button">
            <span class="not-found-text3">Go Back to Home</span>
          </div>
        </router-link>
        <div class="not-found-btn-group"></div>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name3"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import AppFooter from '../components/footer'

export default {
  name: 'NotFound',
  components: {
    HeaderMenu,
    AppFooter,
  },
  metaInfo: {
    title: 'Not found - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          "CleanWP Pro is the perfect solution for cleaning and optimizing your WordPress website. It's never been easier to improve your site's performance.",
      },
      {
        property: 'og:title',
        content: 'Not found - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/0f7ff0c2-0de1-4af4-a99b-690936278db2?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.not-found-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.not-found-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.not-found-container1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-hero1 {
  width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  min-height: 80vh;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.not-found-image {
  width: 100%;
  object-fit: cover;
}
.not-found-heading {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.not-found-text {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 40px;
  max-width: 1300px;
  margin-top: 0px;
  text-align: center;
  line-height: 50px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.not-found-navlink {
  display: contents;
}
.not-found-get-started {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.not-found-get-started:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.not-found-text3 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.not-found-btn-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
@media(max-width: 1200px) {
  .not-found-hero {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .not-found-heading {
    color: var(--dl-color-gray-black);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .not-found-text {
    font-size: 20px;
    line-height: 30px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 991px) {
  .not-found-hero {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .not-found-hero1 {
    flex-direction: column;
  }
  .not-found-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .not-found-text {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .not-found-hero {
    height: auto;
    position: relative;
    background-color: #0064b7;
  }
  .not-found-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .not-found-heading {
    font-size: 40px;
  }
  .not-found-text3 {
    font-size: 16px;
  }
}
@media(max-width: 479px) {
  .not-found-hero {
    height: auto;
  }
  .not-found-hero1 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .not-found-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .not-found-text {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .not-found-btn-group {
    flex-direction: column;
  }
}
</style>
