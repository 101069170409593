<template>
  <div class="clients-pills-container">
    <div class="clients-pills">
      <div class="clients-container">
        <you-tube
          rootClassName="you-tube-root-class-name"
          pastedImage_src="/logos/logo2-200h.png"
        ></you-tube>
        <you-tube
          rootClassName="you-tube-root-class-name1"
          pastedImage_src="/logos/logo4-200h.png"
        ></you-tube>
        <you-tube
          rootClassName="you-tube-root-class-name2"
          pastedImage_src="/logos/logo-200h.jpg"
        ></you-tube>
        <you-tube
          rootClassName="you-tube-root-class-name3"
          pastedImage_src="/logos/justlocal%20logo%20v2%20sized-200h.png"
        ></you-tube>
      </div>
    </div>
  </div>
</template>

<script>
import YouTube from './you-tube'

export default {
  name: 'Clients',
  props: {},
  components: {
    YouTube,
  },
}
</script>

<style scoped>
.clients-pills-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.clients-pills {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.clients-container {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
@media(max-width: 1200px) {
  .clients-container {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .clients-pills-container {
    height: auto;
  }
  .clients-pills {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .clients-container {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .clients-container {
    width: 297px;
    padding-right: 0px;
    justify-content: center;
  }
}
</style>
