<template>
  <div class="speed-up-website-container">
    <section class="speed-up-website-hero">
      <header-menu></header-menu>
      <div class="speed-up-website-header">
        <h1 class="speed-up-website-heading">
          Speed Up Your Website and Increase Conversions
        </h1>
        <span class="speed-up-website-text">
          <span>
            Is your website slow and sluggish? Do you want to improve your
            site&apos;s loading time and user experience?
          </span>
          <br />
          <span>
            Our team of experts specializes in optimizing WordPress websites to
            deliver lightning-fast performance. With our proven strategies and
            techniques, we&apos;ll speed up your site, reduce bounce rates, and
            boost conversions. Contact us today to get started.
          </span>
        </span>
      </div>
      <dashboard-preview
        Image_src="/website%20speedup-1400w.png"
        rootClassName="dashboard-preview-root-class-name6"
      ></dashboard-preview>
    </section>
    <section class="speed-up-website-features">
      <div class="speed-up-website-title">
        <span id="features" class="speed-up-website-text04">
          Optimize Your Website for Success
        </span>
        <span class="speed-up-website-text05">
          Our WordPress Speedup and Optimization Services Will Boost Your
          Website&apos;s Speed and User ExperienceOur WordPress Speedup and
          Optimization Services Will Boost Your Website&apos;s Speed and User
          Experience
        </span>
      </div>
      <span class="speed-up-website-text06 mobileHeading">
        Our Website Speedup Service includes
      </span>
      <div class="speed-up-website-cards">
        <div class="speed-up-website-container1">
          <div class="speed-up-website-schedule card">
            <img
              alt="pastedImage"
              src="/js-css-200h.png"
              class="speed-up-website-icon"
            />
            <span class="speed-up-website-text07">Code Optimization</span>
            <span class="speed-up-website-text08">
              Our optimization experts carefully optimize and compress HTML, CSS,
              and JavaScript files to maximize efficiency and minimize website
              size, saving precious bandwidth and reducing load times.
            </span>
          </div>
          <div class="speed-up-website-schedule1 card">
            <img
              alt="pastedImage"
              src="/image%20optimization-200h.png"
              class="speed-up-website-icon01"
            />
            <span class="speed-up-website-text09">Image Optimization</span>
            <span class="speed-up-website-text10">
              <span>
                Our optimization process ensures that each image is served in the
                recommended next-generation format by Google, allowing for faster
                loading times and reduced space usage without sacrificing quality.
              </span>
              <br />
            </span>
          </div>
        </div>
        <div class="speed-up-website-container2">
          <div class="speed-up-website-analyze card">
            <img
              alt="pastedImage"
              src="/browser%20cache-200h.png"
              class="speed-up-website-icon02"
            />
            <span class="speed-up-website-text13">Browser Caching</span>
            <span class="speed-up-website-text14">
              <span>
                Browser caching stores a website&apos;s static files, like images
                and scripts, on the user&apos;s device. This leads to faster load
                times, reduces server load, and improves overall user experience.
              </span>
              <br />
            </span>
          </div>
          <div class="speed-up-website-get-leads card">
            <img
              alt="pastedImage"
              src="/file-manager1-200h.png"
              class="speed-up-website-icon03"
            />
            <span class="speed-up-website-text17">Database Caching</span>
            <span class="speed-up-website-text18">
              Database caching reduces the number of requests to a database by
              storing frequently accessed data in memory. This leads to faster
              response times, improved performance, and reduced server load.
            </span>
          </div>
        </div>
        <div class="speed-up-website-container3">
          <div class="speed-up-website-analyze1 card">
            <img
              alt="pastedImage"
              src="/https-200h.png"
              class="speed-up-website-icon04"
            />
            <span class="speed-up-website-text19">
              <span>Reduce HTTP Requests</span>
              <br />
            </span>
            <span class="speed-up-website-text22">
              Reducing the number of HTTP requests by minimizing CSS, JavaScript,
              and image files can lead to faster load times, better server
              performance, and improved user experience.
            </span>
          </div>
          <div class="speed-up-website-get-leads1 card">
            <img
              alt="pastedImage"
              src="/responsive-200h.png"
              class="speed-up-website-icon05"
            />
            <span class="speed-up-website-text23">
              Desktop and Mobile Optimization
            </span>
            <span class="speed-up-website-text24">
              Our website optimization for both desktop and mobile devices ensures
              that your site is easily accessible, engaging, and better search
              engine rankings.
              <span v-html="raw0fa7"></span>
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="speed-up-website-statistics">
      <div class="speed-up-website-container4">
        <active-users
          Caption="— 2* Seconds Loading"
          Statistic="Faster"
          rootClassName="active-users-root-class-name33"
        ></active-users>
        <active-users
          Caption="— Optimization"
          Statistic="Image"
          rootClassName="active-users-root-class-name34"
        ></active-users>
        <active-users
          Caption="— Mobile and Desktop"
          Statistic="Both"
          rootClassName="active-users-root-class-name35"
        ></active-users>
        <active-users
          Caption="— Plugin Setup"
          Statistic="Caching"
          rootClassName="active-users-root-class-name36"
        ></active-users>
      </div>
    </section>
    <section class="speed-up-website-banners">
      <div class="speed-up-website-banner">
        <div class="speed-up-website-container5">
          <div class="speed-up-website-left">
            <span class="speed-up-website-text25">
              Slow Websites Are Killing Your Business
            </span>
            <h2 class="speed-up-website-text26 title">
              Time is Money- The Real Cost of Slow Website Load Times
            </h2>
            <span class="speed-up-website-text27">
              <span>
                In today&apos;s highly competitive digital world, every second
                counts. Slow website load times can cause users to abandon your
                site, resulting in missed sales opportunities and decreased
                engagement. By investing in a fast website, you can improve user
                experience, drive higher conversions, and gain a competitive edge
                in your industry. Remember, time is money - make every second
                count!
              </span>
              <br />
            </span>
            <a href="#pricing" class="speed-up-website-link">
              <div class="speed-up-website-get-started template-button">
                <span class="speed-up-website-text30">Get started</span>
              </div>
            </a>
          </div>
          <div class="speed-up-website-image-container">
            <img
              alt="Full WordPress Website Backup"
              src="/time%20is%20money-1200w.png"
              class="speed-up-website-cards-image"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" class="speed-up-website-pricing">
      <div class="speed-up-website-centered-container">
        <div class="speed-up-website-heading1">
          <span class="speed-up-website-text31 mobileHeading title">
            Pricing for all kind of businesses
          </span>
          <span class="speed-up-website-text32">
            Experience Improved Site Performance and Higher Rankings with Our
            Expert Optimization Services
          </span>
        </div>
        <div class="speed-up-website-cards1">
          <div class="speed-up-website-card">
            <span class="speed-up-website-text33">Accelerate</span>
            <span class="speed-up-website-text34">
              If you want to optimize your website with a onetime fee only.
            </span>
            <span class="speed-up-website-text35">$99.00</span>
            <span class="speed-up-website-text36">What&apos;s included</span>
            <div class="speed-up-website-bullet-points">
              <div class="speed-up-website-point">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon06"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text37">Code Optimization</span>
              </div>
              <div class="speed-up-website-point01">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon08"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text38 Content">
                  Images Optimization
                </span>
              </div>
              <div class="speed-up-website-point02">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon10"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text39 Content">
                  Browser Caching
                </span>
              </div>
            </div>
            <div class="speed-up-website-bullet-points1">
              <div class="speed-up-website-point03">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon12"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text40">Database caching</span>
              </div>
              <div class="speed-up-website-point04">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon14"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text41 Content">
                  Reduce HTTP request
                </span>
              </div>
              <div class="speed-up-website-point05">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon16"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text42 Content">
                  Mobile and Desktop Optimization
                </span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/33/checkout"
              target="_blank"
              rel="noreferrer noopener"
              class="speed-up-website-link1"
            >
              <div class="speed-up-website-get-started1 template-button">
                <span class="speed-up-website-text43">Order now</span>
              </div>
            </a>
          </div>
          <div class="speed-up-website-card1">
            <span class="speed-up-website-text44">Swift</span>
            <span class="speed-up-website-text45">
              If you want to keep your website always fast.
            </span>
            <span class="speed-up-website-text46">$129.00/year</span>
            <span class="speed-up-website-text47">What&apos;s included</span>
            <div class="speed-up-website-bullet-points2">
              <div class="speed-up-website-point06">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon18"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text48">
                  <span class="Content">All from </span>
                  <span>Accelerate plan +</span>
                  <span class="Content"> </span>
                </span>
              </div>
              <div class="speed-up-website-point07">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon20"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text52 Content">
                  Premium Caching plugin
                </span>
              </div>
              <div class="speed-up-website-point08">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon22"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text53">
                  <span>Free CDN/CloudFlare Setup</span>
                  <br />
                </span>
              </div>
            </div>
            <div class="speed-up-website-bullet-points3">
              <div class="speed-up-website-point09">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon24"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text56">
                  Performance Monitoring
                </span>
              </div>
              <div class="speed-up-website-point10">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon26"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text57 Content">
                  Monthly Optimisation
                </span>
              </div>
              <div class="speed-up-website-point11">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon28"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text58">
                  <span>Monthly Performance Optimization</span>
                  <br />
                </span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/34/checkout"
              class="speed-up-website-link2"
            >
              <div class="speed-up-website-get-started2 template-button">
                <span class="speed-up-website-text61">
                  <span>Order now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
          <div class="speed-up-website-card2">
            <span class="speed-up-website-text64">Turbo</span>
            <span class="speed-up-website-text65">
              <span>
                If you want your website always optimized and load faster.
              </span>
              <br />
            </span>
            <span class="speed-up-website-text68">$159.00/year</span>
            <span class="speed-up-website-text69">What&apos;s included</span>
            <div class="speed-up-website-bullet-points4">
              <div class="speed-up-website-point12">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon30"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text70">
                  All from Accelerate plan + 
                </span>
              </div>
              <div class="speed-up-website-point13">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon32"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text71">
                  Premium Caching plugin
                </span>
              </div>
              <div class="speed-up-website-point14">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon34"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text72">
                  CleanWP Pro - Optimization Plugin
                </span>
              </div>
            </div>
            <div class="speed-up-website-bullet-points5">
              <div class="speed-up-website-point15">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon36"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text73">
                  Performance Monitoring
                </span>
              </div>
              <div class="speed-up-website-point16">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon38"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text74">Monthly Optimisation</span>
              </div>
              <div class="speed-up-website-point17">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="speed-up-website-icon40"
                >
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="speed-up-website-text75">
                  Monthly Performance Optimization
                </span>
              </div>
            </div>
            <a
              href="https://app.cleanwp.pro/#/plans/35/checkout"
              class="speed-up-website-link3"
            >
              <div class="speed-up-website-get-started3 template-button">
                <span class="speed-up-website-text76">
                  <span>Order now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name2"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import DashboardPreview from '../components/dashboard-preview'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'SpeedUpWebsite',
  components: {
    HeaderMenu,
    DashboardPreview,
    ActiveUsers,
    AppFooter,
  },
  data() {
    return {
      raw0fa7: ' ',
    }
  },
  metaInfo: {
    title: 'Malware Clean up services, malware removal services - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'Worried about malware on your website? Let us help.  Keep your website safe with top-notch malware clean-up and removal services.',
      },
      {
        property: 'og:title',
        content:
          'Malware Clean up services, malware removal services - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/5b1f4792-a0bb-4711-8c88-6f582e4cce61?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.speed-up-website-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.speed-up-website-hero {
  width: 100%;
  height: 949px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.speed-up-website-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.speed-up-website-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.speed-up-website-text {
  color: #ffffff;
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 0px;
  text-align: center;
  line-height: 30px;
  padding-top: var(--dl-space-space-twounits);
}
.speed-up-website-features {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #efefef;
}
.speed-up-website-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.speed-up-website-text04 {
  font-size: 50px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 65px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.speed-up-website-text05 {
  width: auto;
  font-size: 20px;
  max-width: 900px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.speed-up-website-text06 {
  margin-top: var(--dl-space-space-fiveunits);
}
.speed-up-website-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.speed-up-website-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.speed-up-website-schedule {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text07 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.speed-up-website-text08 {
  line-height: 24px;
}
.speed-up-website-schedule1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-icon01 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text09 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.speed-up-website-text10 {
  line-height: 24px;
}
.speed-up-website-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.speed-up-website-analyze {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-icon02 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text13 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.speed-up-website-text14 {
  line-height: 24px;
}
.speed-up-website-get-leads {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #ffffff;
}
.speed-up-website-icon03 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text17 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.speed-up-website-text18 {
  line-height: 24px;
}
.speed-up-website-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.speed-up-website-analyze1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-icon04 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text19 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.speed-up-website-text22 {
  line-height: 24px;
}
.speed-up-website-get-leads1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-icon05 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text23 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.speed-up-website-text24 {
  line-height: 24px;
}
.speed-up-website-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.speed-up-website-container4 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.speed-up-website-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.speed-up-website-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.speed-up-website-container5 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.speed-up-website-left {
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.speed-up-website-text25 {
  color: rgb(0, 100, 183);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.speed-up-website-text26 {
  width: 596px;
  text-align: left;
}
.speed-up-website-text27 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.speed-up-website-link {
  display: contents;
}
.speed-up-website-get-started {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.speed-up-website-get-started:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.speed-up-website-text30 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.speed-up-website-image-container {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.speed-up-website-cards-image {
  width: auto;
  height: auto;
  object-fit: contain;
}
.speed-up-website-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #0064b7;
}
.speed-up-website-centered-container {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.speed-up-website-heading1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.speed-up-website-text31 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.speed-up-website-text32 {
  color: rgb(238, 233, 254);
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-cards1 {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.speed-up-website-card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.speed-up-website-text33 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text34 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-text35 {
  font-size: 40px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text36 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-bullet-points {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.speed-up-website-point {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon06 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text37 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point01 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon08 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text38 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point02 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon10 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text39 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-bullet-points1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.speed-up-website-point03 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon12 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text40 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point04 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon14 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text41 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point05 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon16 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text42 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-link1 {
  display: contents;
}
.speed-up-website-get-started1 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.speed-up-website-get-started1:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.speed-up-website-text43 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.speed-up-website-card1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.speed-up-website-text44 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text45 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-text46 {
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text47 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-bullet-points2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.speed-up-website-point06 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon18 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text48 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point07 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon20 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text52 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point08 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon22 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text53 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-bullet-points3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.speed-up-website-point09 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon24 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text56 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point10 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon26 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text57 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point11 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon28 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text58 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-link2 {
  display: contents;
}
.speed-up-website-get-started2 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.speed-up-website-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.speed-up-website-text61 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.speed-up-website-card2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #000000;
}
.speed-up-website-text64 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text65 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.speed-up-website-text68 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-text69 {
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.speed-up-website-bullet-points4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.speed-up-website-point12 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon30 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text70 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point13 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon32 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text71 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon34 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text72 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-bullet-points5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.speed-up-website-point15 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon36 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text73 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon38 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text74 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-point17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.speed-up-website-icon40 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.speed-up-website-text75 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.speed-up-website-link3 {
  display: contents;
}
.speed-up-website-get-started3 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.speed-up-website-get-started3:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.speed-up-website-text76 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}
.speed-up-website-text76:hover {
  color: var(--dl-color-gray-900);
}
@media(max-width: 1200px) {
  .speed-up-website-hero {
    width: 100%;
    height: 1116px;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .speed-up-website-header {
    margin-top: var(--dl-space-space-sevenunits);
    margin-bottom: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .speed-up-website-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .speed-up-website-text {
    font-size: 20px;
    line-height: 30px;
  }
  .speed-up-website-features {
    padding-top: 380px;
  }
  .speed-up-website-text05 {
    font-size: 20px;
    line-height: 30px;
  }
  .speed-up-website-text19 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .speed-up-website-text22 {
    line-height: 24px;
  }
  .speed-up-website-text23 {
    line-height: 33px;
  }
  .speed-up-website-text24 {
    line-height: 24px;
  }
  .speed-up-website-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .speed-up-website-container4 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
  .speed-up-website-banner {
    width: 100%;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .speed-up-website-left {
    width: 50%;
  }
  .speed-up-website-cards-image {
    width: 100%;
  }
  .speed-up-website-pricing {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .speed-up-website-centered-container {
    flex-direction: column;
  }
}
@media(max-width: 991px) {
  .speed-up-website-hero {
    height: 966px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .speed-up-website-header {
    width: 100%;
    max-width: 1200px;
    margin-top: var(--dl-space-space-fourunits);
  }
  .speed-up-website-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .speed-up-website-text {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .speed-up-website-features {
    height: auto;
    padding-top: 330px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .speed-up-website-title {
    width: 100%;
    max-width: auto;
  }
  .speed-up-website-text05 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .speed-up-website-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .speed-up-website-text08 {
    line-height: 21px;
  }
  .speed-up-website-text09 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .speed-up-website-text10 {
    line-height: 21px;
  }
  .speed-up-website-text14 {
    line-height: 21px;
  }
  .speed-up-website-text17 {
    font-size: 24px;
  }
  .speed-up-website-text18 {
    line-height: 21px;
  }
  .speed-up-website-text22 {
    line-height: 21px;
  }
  .speed-up-website-text23 {
    font-size: 24px;
  }
  .speed-up-website-text24 {
    line-height: 21px;
  }
  .speed-up-website-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .speed-up-website-container4 {
    height: 100%;
    flex-direction: row;
  }
  .speed-up-website-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .speed-up-website-text26 {
    width: 584px;
    font-size: 40px;
    line-height: 45px;
  }
  .speed-up-website-cards-image {
    width: 343px;
    height: auto;
  }
  .speed-up-website-pricing {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .speed-up-website-centered-container {
    width: 100%;
  }
  .speed-up-website-text31 {
    font-size: 45px;
    line-height: 50px;
  }
  .speed-up-website-text32 {
    width: 100%;
    max-width: 600px;
  }
  .speed-up-website-cards1 {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .speed-up-website-card {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .speed-up-website-text34 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .speed-up-website-text36 {
    font-size: 16px;
  }
  .speed-up-website-card1 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .speed-up-website-text45 {
    color: rgb(0, 0, 0);
    line-height: 24px;
  }
  .speed-up-website-text47 {
    font-size: 16px;
  }
  .speed-up-website-card2 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .speed-up-website-text65 {
    color: rgb(255, 255, 255);
    line-height: 24px;
  }
  .speed-up-website-text68 {
    color: var(--dl-color-gray-white);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
  }
}
@media(max-width: 767px) {
  .speed-up-website-hero {
    height: 829px;
    position: relative;
    padding-bottom: 180px;
    background-color: #0064b7;
  }
  .speed-up-website-header {
    height: 372px;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .speed-up-website-heading {
    font-size: 40px;
  }
  .speed-up-website-features {
    height: auto;
    padding-top: 225px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .speed-up-website-text04 {
    font-size: 40px;
  }
  .speed-up-website-text06 {
    text-align: center;
  }
  .speed-up-website-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .speed-up-website-text07 {
    font-size: 24px;
  }
  .speed-up-website-text08 {
    font-size: 14px;
  }
  .speed-up-website-text09 {
    font-size: 24px;
  }
  .speed-up-website-text10 {
    font-size: 14px;
  }
  .speed-up-website-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .speed-up-website-text13 {
    font-size: 24px;
  }
  .speed-up-website-text14 {
    font-size: 14px;
  }
  .speed-up-website-text18 {
    font-size: 14px;
  }
  .speed-up-website-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .speed-up-website-text19 {
    font-size: 24px;
  }
  .speed-up-website-text22 {
    font-size: 14px;
  }
  .speed-up-website-text24 {
    font-size: 14px;
  }
  .speed-up-website-statistics {
    width: auto;
    height: auto;
  }
  .speed-up-website-container4 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
  .speed-up-website-banner {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .speed-up-website-container5 {
    flex-direction: column;
  }
  .speed-up-website-left {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .speed-up-website-text26 {
    width: auto;
  }
  .speed-up-website-cards-image {
    width: auto;
  }
  .speed-up-website-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .speed-up-website-centered-container {
    width: auto;
    flex-direction: column;
  }
  .speed-up-website-cards1 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .speed-up-website-card {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .speed-up-website-text33 {
    font-size: 28px;
  }
  .speed-up-website-text34 {
    width: auto;
  }
  .speed-up-website-text35 {
    font-size: 28px;
  }
  .speed-up-website-bullet-points {
    width: 100%;
  }
  .speed-up-website-bullet-points1 {
    width: 100%;
  }
  .speed-up-website-card1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .speed-up-website-text44 {
    font-size: 28px;
  }
  .speed-up-website-text45 {
    width: auto;
  }
  .speed-up-website-text46 {
    font-size: 28px;
  }
  .speed-up-website-card2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .speed-up-website-text64 {
    font-size: 28px;
  }
  .speed-up-website-text65 {
    width: auto;
  }
  .speed-up-website-text68 {
    font-size: 28px;
  }
}
@media(max-width: 479px) {
  .speed-up-website-hero {
    height: 751px;
    padding-bottom: 51px;
  }
  .speed-up-website-header {
    height: auto;
    margin-top: 0px;
    padding-top: var(--dl-space-space-threeunits);
    margin-bottom: 0px;
  }
  .speed-up-website-heading {
    height: 109px;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .speed-up-website-text {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .speed-up-website-features {
    height: auto;
    padding-top: 200px;
  }
  .speed-up-website-text04 {
    font-size: 32px;
    line-height: 45px;
  }
  .speed-up-website-text05 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }
  .speed-up-website-text06 {
    font-size: 32;
    line-height: 38px;
  }
  .speed-up-website-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .speed-up-website-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .speed-up-website-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .speed-up-website-get-leads {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .speed-up-website-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .speed-up-website-get-leads1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .speed-up-website-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .speed-up-website-container4 {
    width: auto;
    flex-direction: column;
  }
  .speed-up-website-left {
    width: 100%;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .speed-up-website-text26 {
    width: auto;
    font-size: 32px;
    line-height: 38px;
  }
  .speed-up-website-cards-image {
    width: 100%;
    height: auto;
  }
  .speed-up-website-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .speed-up-website-centered-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .speed-up-website-text31 {
    font-size: 32px;
    line-height: 38px;
  }
  .speed-up-website-cards1 {
    flex-direction: column;
  }
}
</style>
