<template>
  <div class="home-container">
    <section class="home-hero">
      <header-menu rootClassName="header-menu-root-class-name3"></header-menu>
      <div class="home-header">
        <h1 class="home-heading">Say Goodbye to Cluttered WordPress Websites!</h1>
        <p class="home-text">
          Tired of a slow and cluttered WordPress website? With just one click,
          our plugin will optimize your site for maximum efficiency, faster
          loading times, reduce storage uses, and better website security.
          <span v-html="rawfrgt"></span>
        </p>
        <a href="#features" class="home-link button">
          <span class="home-text001">
            <span>Introducing CleanWP Pro</span>
            <br />
          </span>
        </a>
      </div>
      <div class="home-dashboard-preview">
        <div class="home-outline">
          <img
            alt="CleanWP Pro - The ultimate WordPress cleaner plugin"
            src="/cleanwp%20pro%20dashboard-1400w.jpeg"
            loading="eager"
            class="home-image"
          />
        </div>
      </div>
    </section>
    <section class="home-features">
      <div class="home-title">
        <span id="features" class="home-text004">
          The Ultimate Solution for a Faster, More Efficient WordPress Site
        </span>
        <span class="home-text005">
          We know that website speed and performance is essential for your online
          success. That&apos;s why we&apos;ve created the ultimate solution to
          help you achieve junk free, secure and optimal website performance.
        </span>
      </div>
      <span class="home-text006">Features</span>
      <div class="home-cards">
        <div class="home-container1">
          <div class="home-schedule card">
            <img alt="pastedImage" src="/dashboard-200h.png" class="home-icon" />
            <span class="home-text007">Dashboard Cleaner</span>
            <span class="home-text008">
              Simplify your Wordpress experience with Dashboard Cleaner. This
              feature cleans up your dashboard, removing unnecessary menus and
              widgets for a cleaner interface.
              <span v-html="raw5k8l"></span>
            </span>
          </div>
          <div class="home-schedule1 card">
            <img alt="pastedImage" src="/backup-200h.png" class="home-icon01" />
            <span class="home-text009">Full Website Backup and Restore</span>
            <span class="home-text010">
              <span>
                Our one-click full Backup and Restore feature enables you to take
                backups whenever you need them. This ensures that your data is
                always safe and secure.
              </span>
              <br />
            </span>
          </div>
        </div>
        <div class="home-container2">
          <div class="home-analyze card">
            <img alt="pastedImage" src="/backup-200h.png" class="home-icon02" />
            <span class="home-text013">WordPress Junks Cleaner</span>
            <span class="home-text014">
              <span>
                Simplify your WordPress site maintenance. Get rid of unnecessary
                data, including post revisions, spam comments, orphan data, terms,
                plugins, and themes, with one easy click.
              </span>
              <br />
            </span>
          </div>
          <div class="home-get-leads card">
            <img
              alt="pastedImage"
              src="/media-delete-200h.png"
              class="home-icon03"
            />
            <span class="home-text017">Remove Unused Medias</span>
            <span class="home-text018">
              Your website taking too much storage? Safely remove unused media
              files and free up valuable storage space on your WordPress site with
              our powerful plugin feature.
            </span>
          </div>
        </div>
        <div class="home-container3">
          <div class="home-analyze1 card">
            <img
              alt="pastedImage"
              src="/files%20integrity-200h.png"
              class="home-icon04"
            />
            <span class="home-text019">
              <span>Monitor WordPress integrity</span>
              <br />
            </span>
            <span class="home-text022">
              Protect your WordPress site from malicious attacks and keep it
              running smoothly with our powerful WordPress Integrity
              Monitor. Verify whether any official WordPress core files have been
              modified.
            </span>
          </div>
          <div class="home-get-leads1 card">
            <img
              alt="pastedImage"
              src="/autoclean-200h.png"
              class="home-icon05"
            />
            <span class="home-text023">Auto Update and CleanUp</span>
            <span class="home-text024">
              The auto cleanup feature will remove all junk data from your
              WordPress website, and the auto-update feature will always keep
              everything up to date, saving time on website maintenance.
              <span v-html="raw244c"></span>
            </span>
          </div>
        </div>
        <div class="home-container4">
          <div class="home-analyze2 card">
            <img
              alt="pastedImage"
              src="/file-manager1-200h.png"
              class="home-icon06"
            />
            <span class="home-text025">
              <span>Files Cleaner</span>
              <br />
            </span>
            <span class="home-text028">
              Effortlessly manage your WordPress files with our powerful file
              manager. Easily locate and remove outdated, unnecessary files from
              your dashboard to optimize performance and storage usage.
            </span>
          </div>
          <div class="home-get-leads2 card">
            <img alt="pastedImage" src="/js-css-200h.png" class="home-icon07" />
            <span class="home-text029">CSS/JS Cleaner</span>
            <span class="home-text030">
              Optimize your WordPress website&apos;s performance in seconds with
              the CSS/JS Cleaner feature that removes unnecessary CSS and
              JavaScript files. Reduce website size and improve load times.
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="home-statistics">
      <div class="home-container5">
        <active-users
          Caption="— Active Installation"
          Statistic="2000+"
          rootClassName="active-users-root-class-name1"
        ></active-users>
        <active-users
          Caption="— Maintenance Free"
          Statistic="99%"
          rootClassName="active-users-root-class-name4"
        ></active-users>
        <active-users
          Caption="— Non techie Friendly"
          Statistic="100%"
          rootClassName="active-users-root-class-name3"
        ></active-users>
        <active-users
          Caption="— Websites Compatible"
          Statistic="99%"
          rootClassName="active-users-root-class-name2"
        ></active-users>
      </div>
    </section>
    <section class="home-banners">
      <div class="home-banner-manage">
        <div class="home-container6">
          <div class="home-left">
            <span class="home-text031 sub-title">Automatic Maintenance</span>
            <span class="home-text032 title">
              Save times on maintaining your WordPress website
            </span>
            <span class="home-text033">
              <span>
                CleanWP Pro is a powerful tool that can help ensure that your
                website functions smoothly and safely.
              </span>
              <br />
              <span>
                One of CleanWP Pro&apos;s important features is its regular
                auto-cleanup, that helps in clearing up unused files and data from
                your website&apos;s database and file system. This improves your
                website&apos;s functionality while lowering the risk of
                vulnerabilities in security.
              </span>
            </span>
            <a href="#pricing" class="home-link1">
              <div class="home-get-started template-button">
                <span class="home-text037">Get started</span>
              </div>
            </a>
          </div>
          <div class="home-image-container">
            <img
              alt="Save times on maintaining your WordPress website"
              src="/wordpress%20maintennace-1200w.png"
              class="home-cards-image"
            />
          </div>
        </div>
      </div>
      <div class="home-banner-advanced-analytics">
        <div class="home-centered-container">
          <div class="home-image-container1">
            <img
              alt="The All-in-One Database Cleaning and Optimization Plugin"
              src="/cleanup%20everything-1200w.png"
              class="home-cards-image1"
            />
          </div>
          <div class="home-right">
            <span class="home-text038 sub-title">Clean Everything</span>
            <h2 class="home-text039 title">
              The All-in-One Database Cleaning and Optimization Plugin
            </h2>
            <div class="home-category">
              <span class="home-text040">
                <span class="home-text041">
                  CleanWP Pro is a feature-rich plugin that provides a number of
                  tools to assist website owners in cleaning and optimising their
                  databases.
                </span>
                <br />
                <br />
                <span>
                  A variety of tools are available through CleanWP Pro to assist
                  website owners in cleaning and optimizing their databases,
                  including the removal of spam and unapproved comments, trashed
                  comments, orphaned comments metadata, unused terms, duplicate
                  users metadata, expired transients, post revisions, and
                  auto-drafts, as well as improving database performance.
                </span>
                <br />
              </span>
            </div>
            <a href="#pricing" class="home-link2">
              <div class="home-get-started1 template-button">
                <span class="home-text046">Get started</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="home-banner">
        <div class="home-container7">
          <div class="home-left1">
            <span class="home-text047">Backup your website data</span>
            <h2 class="home-text048 title">
              Full Website Backup just a Click away
            </h2>
            <span class="home-text049">
              <span>
                With only one click with CleanWP Pro, you can backup your whole
                database or Files and Folders. You can also schedule automatic
                database backups whenever it&apos;s convenient for you. These
                backups will be kept on your hosting account and sent to you
                through email so that you can keep them secure.
              </span>
              <br />
            </span>
            <a href="#pricing" class="home-link3">
              <div class="home-get-started2 template-button">
                <span class="home-text052">Get started</span>
              </div>
            </a>
          </div>
          <div class="home-image-container2">
            <img
              alt="Full WordPress Website Backup"
              src="/full%20wordpress%20website%20backup-1200w.png"
              class="home-cards-image2"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="home-integrations">
      <div class="home-centered-container1">
        <div class="home-heading1">
          <span class="home-text053 sub-title">
            CleanWP Pro Plugin Trusted by Popular Websites
          </span>
          <span class="title home-text054">
            Boost Your Website&apos;s Performance with CleanWP Pro
          </span>
          <span class="home-text055">
            Join the Ranks of Successful Websites with CleanWP Pro: Trusted by
            Popular Wordpress Websites Everywhere!
          </span>
        </div>
        <app-clients></app-clients>
      </div>
    </section>
    <section class="home-how-it-works">
      <div class="home-centered-container2">
        <div class="home-heading2">
          <span class="home-text056">How it works</span>
          <span class="home-text057 title">
            Cleaning up a WordPress website has never been easier!
          </span>
        </div>
        <div class="home-category1">
          <div class="home-headng">
            <span class="home-text058">1 — Install and Activate</span>
            <span class="home-text059">
              First, you&apos;ll need to Download the CleanWP Pro plugin and
              install it on your website. You can do this by going to your
              WordPress dashboard, navigating to the plugins section, and then
              selecting &quot;Add New&quot;. From here, upload the CleanWP Pro
              plugin and activate it.
            </span>
            <a href="#pricing" class="home-link4">
              <div class="home-get-started3 template-button">
                <span class="home-text060">Get started</span>
              </div>
            </a>
          </div>
          <div class="home-container8">
            <img
              alt="Install and Activate CleanWP Pro"
              src="/upload%20cleanwp%20pro-1200w.jpg"
              loading="eager"
              class="home-pasted-image"
            />
          </div>
        </div>
        <div class="home-row">
          <div class="home-category2">
            <div class="home-headng1">
              <span class="home-text061">2 — Go to the plugin dashboard</span>
              <span class="home-text062">
                Once you&apos;ve installed and activated the CleanWP Pro plugin,
                you can navigate to the plugin dashboard by going to your
                WordPress dashboard and selecting &quot;CleanWP Pro&quot; from the
                left-hand menu. This will bring up the main dashboard for the
                plugin, which provides you with various Tools for cleaning and
                optimizing your website.
              </span>
            </div>
            <img
              alt="CleanWP Pro Dashboard page"
              src="/cleanwp%20dashboard-1200w.jpeg"
              loading="lazy"
              class="home-pasted-image1"
            />
          </div>
          <div class="home-category3">
            <div class="home-headng2">
              <span class="home-text063">
                3 — Clean manually or set automatic clean and backup
              </span>
              <span class="home-text064">
                <span>
                  From the CleanWP Pro dashboard, you can choose to clean your
                  website manually or set up automatic cleaning and backups. To
                  clean your site manually, select a tab, view and the click on
                  the red &quot;Delete&quot; button, which will clean the data
                  instantly in background.
                </span>
                <br />
                <span>
                  If you&apos;d like to automate the cleaning process, you can set
                  up a schedule for automatic cleaning and backups within the
                  plugin settings.
                </span>
              </span>
            </div>
            <img
              alt="Easy WordPress website cleanup with CleanWP Pro"
              src="/clean%20manually.jpg"
              class="home-pasted-image2"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" class="home-pricing">
      <div class="home-centered-container3">
        <div class="home-heading3">
          <span class="home-text068 title">
            Pricing for all kind of businesses
          </span>
          <span class="home-text069">
            Clean up any WordPress website easily and boost its performance by
            choosing the plan that fits your business.
          </span>
        </div>
        <div class="home-cards1">
          <div class="home-card">
            <span class="home-text070">Single site</span>
            <span class="home-text071">
              If you have only one website you can choose this plan.
            </span>
            <span class="home-text072">$59.99</span>
            <span class="home-text073">What&apos;s included</span>
            <div class="home-bullet-points">
              <div class="home-point">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon08">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text074 Content">
                  Install on single website
                </span>
              </div>
              <div class="home-point1">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon10">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text075 Content">All features included</span>
              </div>
              <div class="home-point2">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon12">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text076 Content">
                  1 year of updates and support
                </span>
              </div>
            </div>
            <a
              href="https://checkout.freemius.com/mode/dialog/plugin/12709/plan/21436/licenses/1/"
              class="home-link5"
            >
              <div class="home-get-started4 template-button">
                <span class="home-text077">Download now</span>
              </div>
            </a>
          </div>
          <div class="home-card1">
            <span class="home-text078">Freelancer</span>
            <span class="home-text079">
              If you have multiple websites choose this plan.
            </span>
            <span class="home-text080">$99.99</span>
            <span class="home-text081">What&apos;s included</span>
            <div class="home-bullet-points1">
              <div class="home-point3">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon14">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text082 Content">Install on 5 websites</span>
              </div>
              <div class="home-point4">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon16">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text083 Content">All features included</span>
              </div>
              <div class="home-point5">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon18">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text084 Content">
                  1 year of updates and support
                </span>
              </div>
            </div>
            <a
              href="https://checkout.freemius.com/mode/dialog/plugin/12709/plan/21436/licenses/5/"
              class="home-link6"
            >
              <div class="home-get-started5 template-button">
                <span class="home-text085">
                  <span>Download now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
          <div class="home-card2">
            <span class="home-text088">Agency</span>
            <span class="home-text089">
              <span>
                If you are an Agency choose this plan to install CleanWP Pro on 50
                of Websites.
              </span>
              <br />
            </span>
            <span class="home-text092">$199.99</span>
            <span class="home-text093">What&apos;s included</span>
            <div class="home-bullet-points2">
              <div class="home-point6">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon20">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text094 Content">Install on 50 websites</span>
              </div>
              <div class="home-point7">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon22">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text095 Content">All features included</span>
              </div>
              <div class="home-point8">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon24">
                  <path
                    d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
                <span class="home-text096 Content">
                  1 year of updates and support
                </span>
              </div>
            </div>
            <a
              href="https://checkout.freemius.com/mode/dialog/plugin/12709/plan/21436/licenses/50/"
              class="home-link7"
            >
              <div class="home-get-started6 template-button">
                <span class="home-text097">
                  <span>Download now</span>
                  <br />
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="home-testimonals">
      <div class="home-left2">
        <span class="home-text100">Testimonals</span>
        <span class="home-text101 title">What people say about CleanWP Pro</span>
      </div>
      <div class="home-right1">
        <div class="home-column">
          <app-testimonal
            From="Blogger"
            name="Rachel"
            Quote="&quot;A must have WordPress plugin for your blog, it's really saves time on cleaning WordPress junk.”"
            Avatar_src="/image552912-e3yq-200h.png"
            rootClassName="testimonal-root-class-name"
          ></app-testimonal>
          <app-testimonal
            From="Digital Marketer"
            name="Sarah Johnson"
            Quote='"I was struggling to keep my website clean and optimized until I came across CleanWP Pro Plugin. It&apos;s simply amazing! It removed all the unwanted clutter and boosted my website speed. Now, my visitors have a seamless browsing experience."'
            Avatar_src="/image312912-mvsg-200h.png"
          ></app-testimonal>
          <app-testimonal
            From="Entrepreneur"
            name="Mark Davis"
            Quote='"CleanWP Pro Plugin has saved me so much time and hassle with its automated backup and clean up features. Highly recommend!"'
            Avatar_src="/image502912-6wy-200h.png"
          ></app-testimonal>
        </div>
        <div class="home-column1">
          <app-testimonal
            From="Small Business Owner"
            name="James Parker"
            Quote='"When my hosting account warned me about exceeding the storage limit, I was in a panic. But then I found CleanWP Pro Plugin, which helped me optimize my website and reduce its storage usage. I&apos;m grateful for this plugin, as it saved me from losing potential customers."'
            Avatar_src="/image572912-0d3-200h.png"
          ></app-testimonal>
          <app-testimonal
            From="Travel Blogger"
            name="Maria Rodriguez"
            Quote='"As a blogger, I upload a lot of images and media to my website regularly. However, I never realized how much of it was just sitting there unused and taking up valuable storage space. CleanWP Pro Plugin helped me clean up all the unused media and files in just few clicks. It&apos;s a must-have plugin for bloggers!"'
            Avatar_src="/image632913-swij-200h.png"
          ></app-testimonal>
        </div>
      </div>
      <span class="home-text102">
        <span>Show more</span>
        <br />
      </span>
    </section>
    <section class="home-action-bar">
      <div class="home-action">
        <div class="home-heading4">
          <span class="home-text105 title">Download CleanWP Pro</span>
          <span class="home-text106">
            CleanWP Pro Plugin is the only WordPress plugin you need for cleaning
            up and backing up your website. Its powerful automation features make
            it easy to keep your site running smoothly without any manual effort
            on your part.
          </span>
          <a href="#pricing" class="home-link8">
            <div class="home-get-started7 template-button">
              <span class="home-text107">
                <span>Get started</span>
                <br />
              </span>
            </div>
          </a>
        </div>
        <div class="home-images">
          <img
            alt="Download CleanWP Pro"
            src="/database%20backup-1200w.png"
            class="home-image1"
          />
        </div>
      </div>
    </section>
    <section id="faq" class="home-faq">
      <div class="home-heading5">
        <span class="home-text110 title">Frequently asked questions</span>
        <span class="home-text111">
          Answers to frequently asked questions (FAQs) concerning CleanWP
          Pro&apos;s features and functioning
        </span>
      </div>
      <div class="home-accordion">
        <div
          data-faq="closed"
          class="home-accordion-container accordionContainer"
        >
          <div class="home-header1">
            <span class="home-text112">
              <span>
                — Why should I use a plugin to clean up junk data in WordPress?
              </span>
              <br />
              <span><span v-html="rawfs41"></span></span>
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="home-text116">
              <span>
                CleanWP Pro is a reliable and powerful WordPress plugin that can
                assist you in improving the performance of your website.
              </span>
              <br />
              <span>
                CleanWP Pro will remove unnecessary content like spam comments,
                post/pages revisions, unused medias and other unused content might
                cause your website to operate slowly. The speed and overall
                effectiveness of your site can be increased by using CleanWP Pro
                to get rid of this clutter.
                <span v-html="raw63ql"></span>
              </span>
            </span>
          </div>
        </div>
        <div class="accordionContainer">
          <div class="home-header2">
            <span class="home-text120">
              — Can CleanWP Pro be configured to run on a schedule or
              automatically?
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="home-text121">
              <span>
                Yes, you can set up CleanWP Pro to run automatically or on a
                schedule. You can configure the plugin to clear out unnecessary
                data and perform database backups on a daily, weekly, or monthly
                basis. This saves you time and effort because you won&apos;t have
                to remember to manually clean up your website.
              </span>
              <br />
              <br />
              <span>
                If you have a busy site with lots of information being uploaded
                often, the scheduling option is quite helpful. By automating the
                procedure, you can maintain the optimization and clutter-free
                website without worrying about manually doing the cleanup process
                yourself.
                <span v-html="raw3btu"></span>
              </span>
            </span>
          </div>
        </div>
        <div data-faq="closed" class="accordionContainer">
          <div class="home-header3">
            <span class="home-text126">
              <span>— Will the plugin work on all WordPress Websites?</span>
              <br />
              <span><span v-html="rawfnnq"></span></span>
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="home-text130">
              Yes. It should be compatible with the majority of current WordPress
              versions because it will run on any website running WordPress
              version 5 or later and PHP version 7.4 or later.
              <span v-html="rawv2ax"></span>
            </span>
          </div>
        </div>
        <div data-faq="closed" class="accordionContainer">
          <div class="home-header4">
            <span class="home-text131">
              <span>
                — How does the plugin clean up unused media files from my
                WordPress site?
              </span>
              <br />
              <span><span v-html="rawe23q"></span></span>
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="home-text135">
              <span>
                CleanWP Pro first analyzes your site&apos;s media library and
                compares it to the images, videos, and other media files that are
                actually being used on your site. It identifies any files that are
                not used on any post, page, or custom post type.
              </span>
              <br />
              <br />
              <span>
                When the plugin detects any unwanted media files, it gives you the
                option to review them all and select which ones you want to
                delete. You can choose to delete all of the unnecessary files at
                once or one by one. Additionally, you can add media to an Ignore
                list if you think it should not be deleted or used elsewhere.
                <span v-html="raw90eq"></span>
              </span>
            </span>
          </div>
        </div>
        <div data-faq="closed" class="accordionContainer">
          <div class="home-header5">
            <span class="home-text140">
              — Is the plugin compatible with other plugins?
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="home-text141">
              Absolutely. Following best practises for WordPress core functioning,
              CleanWP Pro is created to function well with other plugins. This
              includes compatibility with popular cache and backup plugins,
              offering a hassle-free experience when using CleanWP Pro together
              with other plugins to improve your website.
            </span>
          </div>
        </div>
        <div data-faq="closed" class="accordionContainer">
          <div class="home-header6">
            <span class="home-text142">
              — Do you you offer a money-back guarantee?
            </span>
            <svg viewBox="0 0 1024 1024" class="accordionIcon">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div class="accordionContent">
            <span class="home-text143">
              We are pleased to provide a 30-day money-back guarantee for CleanWP
              Pro to all of our clients. Simply ask for a full refund within the
              first 30 days of purchase if you&apos;re unhappy with the way our
              product performs. Although our team is dedicated to making sure that
              our customers get the best outcomes when using CleanWP Pro, we
              acknowledge that not everyone will find it to be a perfect fit. This
              guarantee gives our devoted customers peace of mind because we stand
              behind our product. 
            </span>
          </div>
        </div>
      </div>
      <div>
        <DangerousHTML
          html="<script>
  // Accordion - Code Embed
  
  const accordionContainers = document.querySelectorAll('.accordionContainer'); // All accordion containers
  const accordionContents = document.querySelectorAll('.accordionContent'); // All accordion content
  const accordionIcons = document.querySelectorAll('.accordionIcon'); // All accordion icons
  
  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = 'none'; //Hides all accordion contents
  });
  
  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener('click', () => {
          accordionContents.forEach((accordionContent) => {
              accordionContent.style.display = 'none'; //Hides all accordion contents
          });
  
          accordionIcons.forEach((accordionIcon) => {
              accordionIcon.style.transform = 'rotate(0deg)'; // Resets all icon transforms to 0deg (default)
          });
  
          if (accordionContainer.classList.contains('accordion-open')) { // Checks if this container has class 'accordion-open'
              accordionContainer.classList.remove('accordion-open'); // Removes class 'accordion-open'
          } else {
              accordionContainers.forEach((accordionContainer) => {
                  accordionContainer.classList.remove('accordion-open'); // Removes class 'accordion-open' from all containers
              });
  
              accordionContainer.classList.add('accordion-open'); // Adds class 'accordion-open' to this container
              accordionContents[index].style.display = 'flex'; // Shows accordion content
              accordionIcons[index].style.transform = 'rotate(180deg)'; // Rotates accordion icon 180deg
          }
      });
  });
  
  /*
  Here's what the above is doing:
      1. Selects all accordion containers, contents, and icons
      2. Hides all accordion contents
      3. Adds an event listener to each accordion container
      4. When an accordion container is clicked, it:
          - Hides all accordion contents
          - Resets all icon transforms to 0deg (default)
          - Checks if this container has class 'accordion-open'
              - If it does, it removes class 'accordion-open'
              - If it doesn't, it:
                  - Removes class 'accordion-open' from all containers
                  - Adds class 'accordion-open' to this container
                  - Shows accordion content
                  - Rotates accordion icon 180deg
  */
  </script>"
        ></DangerousHTML>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderMenu from '../components/header-menu'
import ActiveUsers from '../components/active-users'
import AppClients from '../components/clients'
import AppTestimonal from '../components/testimonal'
import AppFooter from '../components/footer'

export default {
  name: 'Home',
  components: {
    HeaderMenu,
    ActiveUsers,
    AppClients,
    AppTestimonal,
    DangerousHTML,
    AppFooter,
  },
  data() {
    return {
      rawfrgt: ' ',
      raw5k8l: ' ',
      raw244c: ' ',
      rawfs41: ' ',
      raw63ql: ' ',
      raw3btu: ' ',
      rawfnnq: ' ',
      rawv2ax: ' ',
      rawe23q: ' ',
      raw90eq: ' ',
    }
  },
  metaInfo: {
    title: 'CleanWP Pro - The Ultimate WordPress Cleaning Plugin and Services',
    meta: [
      {
        name: 'description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:title',
        content:
          'CleanWP Pro - The Ultimate WordPress Cleaning Plugin and Services',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/1e540aed-93e7-4b67-bd19-de86f9865489?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.home-header {
  display: flex;
  max-width: 1300px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 65px;
}
.home-text {
  color: rgb(238, 233, 254);
  font-size: 20px;
  max-width: 700px;
  text-align: center;
  line-height: 30px;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-link {
  cursor: pointer;
  font-size: 45px;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
}
.home-text001 {
  font-style: normal;
  font-weight: 600;
}
.home-dashboard-preview {
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #107bd2;
  border-width: 20px;
  border-radius: 80px;
  flex-direction: column;
  background-color: #107bd2;
}
.home-outline {
  display: flex;
  align-items: center;
  border-color: #505ef7;
  border-width: 10px;
  border-radius: 70px;
  flex-direction: column;
  background-color: #505ef7;
}
.home-image {
  width: 1069px;
  height: 712px;
  z-index: 10;
  user-select: none;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 70px;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 396px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #efefef;
}
.home-title {
  display: flex;
  max-width: 1300px;
  align-items: center;
  flex-direction: column;
}
.home-text004 {
  font-size: 56px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 60px;
  padding-top: var(--dl-space-space-fiveunits);
}
.home-text005 {
  width: auto;
  font-size: 20px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-text006 {
  font-size: 40px;
  margin-top: var(--dl-space-space-fiveunits);
  font-weight: 600;
}
.home-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-schedule {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text007 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text008 {
  line-height: 24px;
}
.home-schedule1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-icon01 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text009 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text010 {
  line-height: 24px;
}
.home-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-analyze {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-icon02 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text013 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text014 {
  line-height: 24px;
}
.home-get-leads {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #ffffff;
}
.home-icon03 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text017 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text018 {
  line-height: 24px;
}
.home-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-analyze1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-icon04 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text019 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text022 {
  line-height: 24px;
}
.home-get-leads1 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-icon05 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text023 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text024 {
  line-height: 24px;
}
.home-container4 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-analyze2 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text025 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text028 {
  line-height: 24px;
}
.home-get-leads2 {
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-icon07 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text029 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text030 {
  line-height: 24px;
}
.home-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.home-container5 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-banner-manage {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container6 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text031 {
  color: rgb(0, 100, 183);
}
.home-text032 {
  text-align: left;
}
.home-text033 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-link1 {
  display: contents;
}
.home-get-started {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.home-get-started:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text037 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-image-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cards-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}
.home-banner-advanced-analytics {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container1 {
  flex: 1;
  display: flex;
}
.home-cards-image1 {
  width: 600px;
  height: auto;
  object-fit: contain;
  padding-right: var(--dl-space-space-fiveunits);
}
.home-right {
  flex: 1;
  width: 100%;
  display: flex;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.home-text038 {
  color: rgb(0, 100, 183);
}
.home-text039 {
  width: 645px;
  text-align: left;
}
.home-category {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text040 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-link2 {
  display: contents;
}
.home-get-started1 {
  align-self: flex-start;
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.home-get-started1:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text046 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container7 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text047 {
  color: rgb(0, 100, 183);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text048 {
  width: 596px;
  text-align: left;
}
.home-text049 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-link3 {
  display: contents;
}
.home-get-started2 {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.home-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text052 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-image-container2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image2 {
  width: 582px;
  height: 425px;
  object-fit: contain;
}
.home-integrations {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sevenunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text053 {
  color: #0064b7;
}
.home-text055 {
  color: rgb(0, 0, 0);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-how-it-works {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #211062;
}
.home-centered-container2 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.home-heading2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text056 {
  color: rgb(220, 212, 253);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text057 {
  color: var(--dl-color-gray-white);
}
.home-category1 {
  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #0064b7;
}
.home-headng {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text058 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text059 {
  color: rgb(238, 233, 254);
  line-height: 24px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 40px;
}
.home-link4 {
  display: contents;
}
.home-get-started3 {
  margin-bottom: 0px;
  text-decoration: none;
}
.home-get-started3:hover {
  color: black;
  background-color: #ffffff;
}
.home-text060 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-container8 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}
.home-pasted-image {
  flex: 0 0 auto;
  width: 100%;
  height: 342px;
  align-self: center;
  object-fit: contain;
  border-radius: 0px;
  padding-right: var(--dl-space-space-twounits);
  border-top-left-radius: 0;
  border-top-right-radius: 130px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 130px;
}
.home-row {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.home-category2 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-headng1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text061 {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text062 {
  color: rgb(30, 30, 30);
  line-height: 24px;
}
.home-pasted-image1 {
  width: 502px;
  height: 215px;
  align-self: center;
}
.home-category3 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}
.home-headng2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text063 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text064 {
  color: rgb(238, 233, 254);
  line-height: 24px;
}
.home-pasted-image2 {
  width: 480px;
  height: 50%;
  align-self: center;
  object-fit: contain;
  padding-top: var(--dl-space-space-twounits);
}
.home-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #0064b7;
}
.home-centered-container3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text068 {
  color: var(--dl-color-gray-white);
  font-size: 56px;
  text-align: center;
}
.home-text069 {
  color: rgb(238, 233, 254);
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-cards1 {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.home-card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-text070 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text071 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text072 {
  font-size: 40px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text073 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon08 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text074 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon10 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text075 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon12 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text076 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-link5 {
  display: contents;
}
.home-get-started4 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-get-started4:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.home-text077 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-card1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-text078 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text079 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text080 {
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text081 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point3 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon14 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text082 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point4 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon16 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text083 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point5 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon18 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text084 {
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-link6 {
  display: contents;
}
.home-get-started5 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-get-started5:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.home-text085 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-card2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #000000;
}
.home-text088 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text089 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text092 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text093 {
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point6 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon20 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text094 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point7 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon22 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text095 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point8 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon24 {
  fill: #0064b7;
  width: 8px;
  height: 8px;
}
.home-text096 {
  color: #ffffff;
  font-style: normal;
  margin-left: var(--dl-space-space-halfunit);
}
.home-link7 {
  display: contents;
}
.home-get-started6 {
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-get-started6:hover {
  color: var(--dl-color-gray-white);
  background-color: #0064b7;
}
.home-text097 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}
.home-text097:hover {
  color: var(--dl-color-gray-900);
}
.home-testimonals {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-left2 {
  width: 375px;
  display: flex;
  margin-top: 305px;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text100 {
  color: rgb(0, 100, 183);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text101 {
  text-align: left;
}
.home-right1 {
  gap: 40px;
  display: flex;
  flex-direction: row;
}
.home-column {
  gap: 40px;
  display: flex;
  flex-direction: column;
}
.home-column1 {
  gap: 40px;
  display: flex;
  flex-direction: column;
}
.home-text102 {
  cursor: pointer;
  display: none;
}
.home-action-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-action {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0064b7;
}
.home-heading4 {
  width: 871px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: 44px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
}
.home-text105 {
  color: var(--dl-color-gray-white);
  text-align: left;
}
.home-text106 {
  color: rgb(238, 233, 254);
  width: 600px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-link8 {
  display: contents;
}
.home-get-started7 {
  margin-bottom: 0px;
  text-decoration: none;
}
.home-text107 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-images {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
}
.home-faq {
  width: 100%;
  display: flex;
  padding: 120px;
  align-items: center;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #EFF0F2;
}
.home-heading5 {
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  flex-direction: column;
}
.home-text110 {
  font-size: 56px;
  text-align: center;
}
.home-text111 {
  color: rgb(0, 0, 0);
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-accordion {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-accordion-container {
  transition: 0.3s;
}

.home-header1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text112 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  font-weight: 500;
}
.home-text116 {
  width: 100%;
  font-size: 16px;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}

.home-header2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text120 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}

.home-text121 {
  width: 100%;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}

.home-header3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text126 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}
.home-text126:hover {
  color: var(--dl-color-gray-black);
}
.home-text130 {
  width: 100%;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}

.home-header4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text131 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}
.home-text131:hover {
  color: var(--dl-color-gray-black);
}
.home-text135 {
  width: 100%;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}
.home-text135:hover {
  color: var(--dl-color-gray-black);
}
.home-header5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text140 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}
.home-text140:hover {
  color: var(--dl-color-gray-black);
}
.home-text141 {
  width: 100%;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}
.home-text141:hover {
  color: var(--dl-color-gray-black);
}
.home-header6 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text142 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}
.home-text142:hover {
  color: var(--dl-color-gray-black);
}
.home-text143 {
  width: 100%;
  max-width: 760px;
  transition: 0.3s;
  line-height: 24px;
}
.home-text143:hover {
  color: var(--dl-color-gray-black);
}
@media(max-width: 1200px) {
  .home-heading {
    color: rgb(255, 255, 255);
  }
  .home-text {
    color: rgb(238, 233, 254);
    font-size: 20px;
    line-height: 30px;
  }
  .home-image {
    animation-duration: 70px;
  }
  .home-text004 {
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
  }
  .home-text005 {
    font-size: 20px;
    line-height: 30px;
  }
  .home-text019 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .home-text022 {
    line-height: 24px;
  }
  .home-text023 {
    line-height: 33px;
  }
  .home-text024 {
    line-height: 24px;
  }
  .home-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-container5 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
  .home-banner-manage {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-container6 {
    max-width: auto;
  }
  .home-text031 {
    color: rgb(0, 100, 183);
  }
  .home-banner-advanced-analytics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-centered-container {
    width: 100%;
    max-width: auto;
  }
  .home-cards-image1 {
    width: 100%;
    height: auto;
  }
  .home-right {
    width: auto;
  }
  .home-text038 {
    color: rgb(0, 100, 183);
  }
  .home-text039 {
    width: 100%;
  }
  .home-banner {
    width: 100%;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-left1 {
    width: 50%;
  }
  .home-cards-image2 {
    width: 100%;
  }
  .home-how-it-works {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-category1 {
    padding-left: var(--dl-space-space-twounits);
  }
  .home-pasted-image {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-row {
    width: 100%;
  }
  .home-headng1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-pasted-image1 {
    width: 100%;
    height: auto;
  }
  .home-headng2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-pasted-image2 {
    width: 100%;
    height: auto;
    padding-top: 0px;
  }
  .home-pricing {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-centered-container3 {
    flex-direction: column;
  }
  .home-testimonals {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text100 {
    color: rgb(0, 100, 183);
  }
  .home-action-bar {
    padding: var(--dl-space-space-threeunits);
  }
  .home-action {
    width: 100%;
    padding-right: 0px;
  }
  .home-text106 {
    width: 100%;
  }
  .home-image1 {
    width: 100%;
    height: auto;
  }
  .home-text112 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text116 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 24px;
  }
  .home-text120 {
    color: var(--dl-color-gray-black);
  }
  .home-text121 {
    color: var(--dl-color-gray-black);
  }
  .home-text130 {
    color: var(--dl-color-gray-black);
  }
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-header {
    width: 100%;
    max-width: 1200px;
  }
  .home-heading {
    color: rgb(255, 255, 255);
  }
  .home-text {
    color: rgb(238, 233, 254);
  }
  .home-image {
    width: 100%;
    height: auto;
    border-radius: 70px;
  }
  .home-features {
    padding-top: 230px;
  }
  .home-title {
    width: 100%;
    max-width: 1200px;
  }
  .home-text004 {
    font-size: 36px;
    line-height: 40px;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text005 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-text008 {
    line-height: 21px;
  }
  .home-text009 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .home-text010 {
    line-height: 21px;
  }
  .home-text014 {
    line-height: 21px;
  }
  .home-text017 {
    font-size: 24px;
  }
  .home-text018 {
    line-height: 21px;
  }
  .home-text022 {
    line-height: 21px;
  }
  .home-text023 {
    font-size: 24px;
  }
  .home-text024 {
    line-height: 21px;
  }
  .home-text028 {
    line-height: 21px;
  }
  .home-text029 {
    font-size: 24px;
  }
  .home-text030 {
    line-height: 21px;
  }
  .home-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container5 {
    height: 100%;
    flex-direction: row;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-left {
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-text031 {
    color: #0064b7;
  }
  .home-text032 {
    font-size: 40px;
    line-height: 45px;
  }
  .home-cards-image {
    width: 360px;
  }
  .home-banner-advanced-analytics {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-cards-image1 {
    width: 423px;
    height: auto;
  }
  .home-text038 {
    color: #0064b7;
  }
  .home-text039 {
    font-size: 40px;
    line-height: 45px;
  }
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text048 {
    width: 584px;
    font-size: 40px;
    line-height: 45px;
  }
  .home-cards-image2 {
    width: 343px;
    height: auto;
  }
  .home-text054 {
    font-size: 45px;
  }
  .home-how-it-works {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text057 {
    font-size: 45px;
    line-height: 50px;
  }
  .home-category1 {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-headng {
    margin-left: var(--dl-space-space-fourunits);
  }
  .home-container8 {
    width: 100%;
  }
  .home-pasted-image {
    height: auto;
    padding-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-row {
    width: 100%;
  }
  .home-headng1 {
    width: auto;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-pasted-image1 {
    width: 100%;
    height: auto;
  }
  .home-headng2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-pasted-image2 {
    width: 100%;
    height: auto;
  }
  .home-pricing {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-centered-container3 {
    width: 100%;
  }
  .home-text068 {
    font-size: 45px;
    line-height: 50px;
  }
  .home-text069 {
    width: 100%;
    max-width: 600px;
  }
  .home-cards1 {
    display: flex;
    flex-direction: row;
  }
  .home-card {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text071 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text073 {
    font-size: 16px;
  }
  .home-card1 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text079 {
    color: rgb(0, 0, 0);
    line-height: 24px;
  }
  .home-text081 {
    font-size: 16px;
  }
  .home-card2 {
    width: 100%;
    align-items: flex-start;
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text089 {
    color: rgb(255, 255, 255);
    line-height: 24px;
  }
  .home-text092 {
    color: var(--dl-color-gray-white);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
  }
  .home-testimonals {
    align-items: center;
    flex-direction: column;
  }
  .home-left2 {
    margin-top: 0px;
    align-items: center;
    margin-bottom: ThreeUnits;
  }
  .home-text101 {
    font-size: 36px;
    text-align: center;
    line-height: 40px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-right1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .home-column {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-action {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-heading4 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text105 {
    font-size: 36px;
    align-self: center;
    line-height: 36px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text106 {
    width: 100%;
    max-width: 600px;
    align-self: center;
    text-align: center;
  }
  .home-get-started7 {
    align-self: center;
  }
  .home-text107 {
    text-align: center;
  }
  .home-images {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image1 {
    width: auto;
  }
  .home-text110 {
    font-size: 45px;
    line-height: 50px;
  }
  .home-accordion {
    width: 100%;
    max-width: 1200px;
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: 830px;
    position: relative;
    padding-bottom: 180px;
    background-color: #0064b7;
  }
  .home-header {
    height: auto;
    margin-top: var(--dl-space-space-fourunits);
    padding-top: 0px;
    justify-content: center;
  }
  .home-heading {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-text {
    color: rgb(238, 233, 254);
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 24px;
  }
  .home-dashboard-preview {
    top: auto;
    width: 90%;
    border-width: 10px;
    border-radius: 45px;
  }
  .home-outline {
    border-width: 10px;
    border-radius: 35px;
  }
  .home-image {
    width: 100%;
    border-radius: 35px;
  }
  .home-features {
    height: auto;
    padding-top: 200px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text004 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-text006 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text007 {
    font-size: 24px;
  }
  .home-text008 {
    font-size: 14px;
  }
  .home-text009 {
    font-size: 24px;
  }
  .home-text010 {
    font-size: 14px;
  }
  .home-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text013 {
    font-size: 24px;
  }
  .home-text014 {
    font-size: 14px;
  }
  .home-text018 {
    font-size: 14px;
  }
  .home-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text019 {
    font-size: 24px;
  }
  .home-text022 {
    font-size: 14px;
  }
  .home-text024 {
    font-size: 14px;
  }
  .home-container4 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text025 {
    font-size: 24px;
  }
  .home-text028 {
    font-size: 14px;
  }
  .home-text030 {
    font-size: 14px;
  }
  .home-statistics {
    width: auto;
    height: auto;
  }
  .home-container5 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container6 {
    flex-direction: column;
  }
  .home-left {
    margin-right: 0px;
  }
  .home-text032 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text033 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text037 {
    font-size: 16px;
  }
  .home-image-container {
    justify-content: center;
  }
  .home-cards-image {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-banner-advanced-analytics {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container {
    flex-direction: column-reverse;
  }
  .home-cards-image1 {
    width: 400px;
    height: auto;
    align-self: center;
    padding-right: 0px;
  }
  .home-right {
    width: auto;
    margin-left: 0px;
  }
  .home-text039 {
    font-size: 28px;
    text-align: left;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text040 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text041 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text046 {
    font-size: 16px;
  }
  .home-banner {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container7 {
    flex-direction: column;
  }
  .home-left1 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text048 {
    width: auto;
  }
  .home-cards-image2 {
    width: auto;
  }
  .home-integrations {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text055 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading2 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text057 {
    margin-bottom: 0px;
  }
  .home-headng {
    padding-bottom: 0px;
  }
  .home-get-started3 {
    display: none;
  }
  .home-container8 {
    width: 100%;
  }
  .home-row {
    height: auto;
    flex-direction: column;
  }
  .home-pasted-image1 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-pasted-image2 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-centered-container3 {
    width: auto;
    flex-direction: column;
  }
  .home-cards1 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .home-card {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text070 {
    font-size: 28px;
  }
  .home-text071 {
    width: auto;
  }
  .home-text072 {
    font-size: 28px;
  }
  .home-bullet-points {
    width: 100%;
  }
  .home-card1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text078 {
    font-size: 28px;
  }
  .home-text079 {
    width: auto;
  }
  .home-text080 {
    font-size: 28px;
  }
  .home-card2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text088 {
    font-size: 28px;
  }
  .home-text089 {
    width: auto;
  }
  .home-text092 {
    font-size: 28px;
  }
  .home-testimonals {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: 0px;
  }
  .home-right1 {
    width: 100%;
    flex-direction: column;
  }
  .home-column1 {
    display: none;
  }
  .home-text102 {
    display: flex;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-weight: 600;
  }
  .home-action-bar {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-action {
    align-items: center;
  }
  .home-heading4 {
    width: auto;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text105 {
    font-size: 28px;
    align-self: center;
    text-align: left;
  }
  .home-text107 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-images {
    justify-content: center;
  }
  .home-image1 {
    width: 100%;
  }
  .home-faq {
    padding: var(--dl-space-space-fourunits);
  }
  .home-heading5 {
    width: 100%;
  }
  .home-text110 {
    font-size: 36px;
    line-height: 36px;
  }
  .home-text111 {
    width: 100%;
    max-width: 600p;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .home-hero {
    height: 699px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .home-header {
    height: auto;
    margin-top: 0px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }
  .home-heading {
    font-size: 32px;
  }
  .home-text {
    color: rgb(238, 233, 254);
    width: auto;
    font-size: 20px;
    line-height: 30px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-link {
    height: auto;
    font-size: 25px;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .home-text001 {
    font-size: 25px;
  }
  .home-dashboard-preview {
    top: 40px;
    width: auto;
    height: auto;
  }
  .home-outline {
    border-width: 1px;
  }
  .home-image {
    width: 100%;
    height: auto;
    border-radius: 35px;
    margin-bottom: 3px;
  }
  .home-features {
    height: auto;
    margin-top: 0px;
    padding-top: 150px;
  }
  .home-text004 {
    width: auto;
    font-size: 32px;
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-text005 {
    text-align: center;
  }
  .home-text006 {
    font-size: 33px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-get-leads {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-get-leads1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container4 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .home-container5 {
    width: auto;
    flex-direction: column;
  }
  .home-banner-manage {
    width: 100%;
  }
  .home-left {
    width: auto;
  }
  .home-text032 {
    font-size: 32px;
    line-height: 40px;
  }
  .home-text033 {
    color: rgb(0, 0, 0);
    line-height: 24px;
  }
  .home-cards-image {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-banner-advanced-analytics {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-cards-image1 {
    width: 100%;
    height: auto;
    padding: 0px;
  }
  .home-right {
    width: auto;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text038 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-text039 {
    width: auto;
    font-size: 32px;
    line-height: 38px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-text040 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-left1 {
    width: 100%;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text048 {
    width: auto;
  }
  .home-cards-image2 {
    width: 100%;
    height: auto;
  }
  .home-text054 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-text057 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-category1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-headng {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-text058 {
    font-size: 30px;
  }
  .home-pasted-image {
    width: 100%;
    height: auto;
    align-self: center;
    padding-left: 0px;
    padding-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-row {
    width: 100%;
  }
  .home-headng1 {
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text061 {
    font-size: 30px;
  }
  .home-pasted-image1 {
    width: 100%;
    height: auto;
  }
  .home-category3 {
    width: 100%;
  }
  .home-headng2 {
    width: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text063 {
    font-size: 30px;
  }
  .home-pasted-image2 {
    width: 100%;
    height: auto;
  }
  .home-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-centered-container3 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-text068 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-cards1 {
    flex-direction: column;
  }
  .home-testimonals {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-left2 {
    margin-right: 0px;
  }
  .home-text101 {
    font-size: 32px;
  }
  .home-action-bar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading4 {
    width: auto;
  }
  .home-text105 {
    color: var(--dl-color-gray-white);
    text-align: center;
  }
}
</style>
