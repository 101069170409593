<template>
  <div class="our-ethics-container">
    <section class="our-ethics-hero">
      <header-menu></header-menu>
      <div class="our-ethics-header">
        <h1 class="our-ethics-heading">Our Ethics</h1>
      </div>
    </section>
    <div class="our-ethics-banner">
      <span>
        <span>
          At CleanWP Pro, our core values are centered around putting the customer
          first and upholding our commitment to providing exceptional services. We
          firmly believe that no matter what pricing package or discount a
          customer may have received, they deserve the same level of attention and
          quality of work as any other client.
        </span>
        <br />
        <br />
        <span>
          Therefore, we make it our priority to honor every contract and guarantee
          that the services we promised are delivered in a timely, efficient, and
          effective manner. Our team of professionals is dedicated to ensuring
          that each and every customer receives the highest quality service
          possible, regardless of their discounted rate or lifetime deal.
        </span>
        <br />
        <br />
        <span>
          Our commitment to ethical business practices has earned us a reputation
          as a trustworthy and reliable provider of WordPress related products and
          maintenance services. We take pride in being transparent with our
          clients, communicating clearly about any changes or updates to their
          projects, and never compromising on the quality of our work.
        </span>
        <br />
        <br />
        <span>
          In short, at CleanWP Pro, we hold ourselves to the highest standards of
          ethics and professionalism. Our customers can rest assured that they
          will always receive top-notch service and support, no matter what
          pricing package or discount they may have received.
        </span>
      </span>
    </div>
    <section class="our-ethics-statistics">
      <div class="our-ethics-container1">
        <active-users
          Caption="— Products &amp; Services"
          Statistic="5"
          rootClassName="active-users-root-class-name77"
        ></active-users>
        <active-users
          Caption="— Years of experience"
          Statistic="10+"
          rootClassName="active-users-root-class-name78"
        ></active-users>
        <active-users
          Caption="— Customers"
          Statistic="1000+"
          rootClassName="active-users-root-class-name79"
        ></active-users>
        <active-users
          Caption="— In house Team"
          Statistic="10+"
          rootClassName="active-users-root-class-name80"
        ></active-users>
      </div>
    </section>
    <app-footer rootClassName="footer-root-class-name13"></app-footer>
  </div>
</template>

<script>
import HeaderMenu from '../components/header-menu'
import ActiveUsers from '../components/active-users'
import AppFooter from '../components/footer'

export default {
  name: 'OurEthics',
  components: {
    HeaderMenu,
    ActiveUsers,
    AppFooter,
  },
  metaInfo: {
    title: 'Our Ethics - CleanWP Pro',
    meta: [
      {
        name: 'description',
        content:
          'CleanWP Pro puts customers first, committed to delivering on our promises and providing high-quality services to all, regardless of discounts or deals.',
      },
      {
        property: 'og:title',
        content: 'Our Ethics - CleanWP Pro',
      },
      {
        property: 'og:description',
        content:
          'The All-In-One Solution for Security, Speed, and Peace of Mind. 🧹 Clean junk, unused media, and files. It backs up the full website and checks core security.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/713f131e-c8dc-45e0-be1d-be8441d9efec/732d5c62-5992-4755-bca5-d1e57a4697b2?org_if_sml=1&q=80',
      },
    ],
  },
}
</script>

<style scoped>
.our-ethics-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.our-ethics-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0064b7;
}
.our-ethics-header {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.our-ethics-heading {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 56px;
  max-width: 1200px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 65px;
  text-transform: none;
  text-decoration: none;
}
.our-ethics-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.our-ethics-statistics {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #0064b7;
}
.our-ethics-container1 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 1600px) {
  .our-ethics-hero {
    padding-bottom: var(--dl-space-space-unit);
  }
  .our-ethics-heading {
    color: rgb(255, 255, 255);
  }
}
@media(max-width: 1200px) {
  .our-ethics-hero {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
  }
  .our-ethics-header {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .our-ethics-heading {
    color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    font-size: 56px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 65px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    text-transform: none;
    text-decoration: none;
  }
  .our-ethics-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .our-ethics-statistics {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .our-ethics-container1 {
    width: 100%;
    margin: 0px;
    max-width: auto;
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .our-ethics-hero {
    height: auto;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
  }
  .our-ethics-header {
    width: 100%;
    max-width: 1200px;
  }
  .our-ethics-heading {
    height: auto;
    font-size: 50px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .our-ethics-statistics {
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .our-ethics-container1 {
    height: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .our-ethics-hero {
    height: auto;
    position: relative;
    padding-bottom: 0px;
    background-color: #0064b7;
  }
  .our-ethics-header {
    height: auto;
    justify-content: center;
  }
  .our-ethics-heading {
    font-size: 40px;
    padding-top: var(--dl-space-space-unit);
  }
  .our-ethics-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .our-ethics-statistics {
    width: auto;
    height: auto;
  }
  .our-ethics-container1 {
    width: auto;
    height: auto;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .our-ethics-hero {
    height: auto;
    padding-top: 4px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .our-ethics-header {
    height: auto;
    padding-top: var(--dl-space-space-threeunits);
  }
  .our-ethics-heading {
    height: auto;
    font-size: 32px;
    line-height: 38px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .our-ethics-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .our-ethics-statistics {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .our-ethics-container1 {
    width: auto;
    flex-direction: column;
  }
}
</style>
